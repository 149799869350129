import { A } from "@mobily/ts-belt";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { css } from "styled-system/css";
import { Container, Flex, HStack, Stack } from "styled-system/jsx";
import { BreadCrumb, Button, ControlledCombobox, ControlledFile, ControlledInput, Icon } from "~/components";
import CurrencyInput from "~/components/form/controlled/CurrencyInput";
import { Skeleton } from "~/components/ui/Skeleton";
import { useCategory } from "~/hooks";
import { useMutationMyProduct } from "../../hooks";
import { Link } from "@tanstack/react-router";
import { ControlledFroala } from "~/components/form/controlled/Froala";
import { useToast } from "~/context/Toast";

export const Detail = () => {
  const {
    productId,
    control,
    errors,
    handleChange,
    onSubmit,
    progress,
    resetImage,
    mutation,
    inputFileRef,
    getPreviewImage,
    product,
    mutationProduct,
  } = useMutationMyProduct();
  const { toast } = useToast();

  const { category, keyword, debouncedSearch } = useCategory(
    "/product-category",
    product?.data?.data?.category.name,
    product.isSuccess,
  );

  const breadcrumbs = [
    {
      path: "Produk saya",
      to: `/my-product`,
      disabled: false,
    },
    {
      path: productId ? "Edit Produk" : "Buat Produk",
      to: "#",
      disabled: true,
    },
  ];

  return (
    <Container maxW="7xl" mt={10} mb={20}>
      <BreadCrumb items={breadcrumbs} />
      <HStack my={10} gap="4">
        <Link
          to="/my-product"
          search={{
            page: 1,
            limit: 9,
            category: "",
            keyword: "",
          }}
        >
          <Icon color="secondary" size="xl">
            <IconArrowNarrowLeft />
          </Icon>
        </Link>
        <h1
          className={css({
            textStyle: "h6",
            color: "disabled",
            fontWeight: "bold",
          })}
        >
          {productId ? "Edit Product" : "Buat Display Produk"}
        </h1>
      </HStack>
      <form
        onSubmit={onSubmit}
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "8",
          width: "full",
        })}
      >
        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              whiteSpace: "nowrap",
              width: "160px",
            })}
          >
            Foto Produk
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>

          <ControlledFile
            control={control}
            ref={inputFileRef}
            name="image"
            error={errors}
            reset={resetImage}
            handleChange={(e) => {
              handleChange(e, (file) => {
                const mb = file.size / 1000 / 1000;
                if (mb > 5) {
                  toast.error({
                    title: "Gagal Upload Foto",
                    description: "Maksimal Ukuran Foto 5Mb",
                  });
                  return false;
                }
                return true;
              });
            }}
            isLoading={mutation.isPending}
            progress={progress}
            disabled={!!mutation.variables || mutation.isPending}
            preview={getPreviewImage}
            allowedFile="image/*"
          />
        </Stack>
        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Nama Produk
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <ControlledInput
            control={control}
            name="name"
            error={errors}
            inputProps={{
              placeholder: "Ketik nama produk",
              minW: "0",
              width: "full",
            }}
          />
        </Stack>
        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Kategori Produk
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <ControlledCombobox
            hasNextPage={category.hasNextPage}
            isLoading={category.status === "pending" || category.isFetchingNextPage}
            loadingMessage={
              <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                {A.makeWithIndex(5, (i) => (
                  <Skeleton key={i}>
                    <p>loading...</p>
                  </Skeleton>
                ))}
              </Flex>
            }
            fetchNextPage={category.fetchNextPage}
            control={control}
            rootProps={{
              inputValue: keyword,
              onInputValueChange: (v) => {
                debouncedSearch(v.value);
              },
              allowCustomValue: true,
            }}
            items={category.data?.list}
            name="category"
            error={errors}
            inputProps={{
              placeholder: "Pilih disini",
            }}
          />
        </Stack>

        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Harga
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <CurrencyInput
            control={control}
            name="price"
            error={errors}
            inputProps={{
              placeholder: "Rp",
              width: "380px",
            }}
          />
        </Stack>

        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Kota asal
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <ControlledInput
            control={control}
            name="origin"
            error={errors}
            inputProps={{
              placeholder: "Kota asal produk",
              minW: "0",
              width: "full",
            }}
          />
        </Stack>
        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Spesifikasi
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <ControlledInput
            control={control}
            name="spec"
            error={errors}
            inputProps={{
              placeholder: "Ketik spesifikasi produk",
              minW: "0",
              width: "full",
            }}
          />
        </Stack>
        <Stack
          gap={{
            base: "4",
            md: "10",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          width="full"
          alignItems="start"
        >
          <p
            className={css({
              textStyle: "md",
              color: "secondary.darken8",
              fontWeight: "medium",
              width: "160px",
            })}
          >
            Deskripsi Produk
            <span
              className={css({
                color: "red.darken5",
              })}
            >
              *
            </span>
          </p>
          <ControlledFroala control={control} name="description" error={errors} />
        </Stack>

        <HStack justifyContent="end">
          <Button type="submit" loading={mutationProduct.isPending}>
            Simpan & {productId ? "Perbarui" : "Tampilkan"}
          </Button>
        </HStack>
      </form>
    </Container>
  );
};
