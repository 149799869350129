import { FieldValues, FieldErrors, UseControllerProps } from "react-hook-form";
import { FormLabelProps } from "~/components/ui";
import { EditorFroala } from "../froala/Editor";
import { Wrapper } from "./Wrapper";

type ConrolledFroalaProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  labelProps?: FormLabelProps;
} & UseControllerProps<T>;

export const ControlledFroala = <T extends FieldValues>(props: ConrolledFroalaProps<T>) => {
  return (
    <Wrapper {...props}>
      {(__, onChange, value, ___, ref) => <EditorFroala model={value} onHandleChange={onChange} ref={ref} />}
    </Wrapper>
  );
};