import React from "react";
import { Button } from ".";
import { Box, Center, Container } from "styled-system/jsx";
import { css } from "styled-system/css";
import { Image } from ".";

interface ErrorElement {
  statusCode: "404" | "500";
}

export const ErrorElement: React.FC<ErrorElement> = ({ statusCode }) => {
  const placeholder: {
    [key in typeof statusCode]: {
      title: string;
      description: string;
      image: string;
    };
  } = {
    "404": {
      image: "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709824284702_Empty states (1).svg",
      title: "Halaman tidak ditemukan",
      description:
        "Pastikan Anda mengetikkan URL dengan benar, atau coba gunakan pencarian kami di menu atas atau kembali ke beranda",
    },
    "500": {
      image: "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1710308997522_Empty states (2).svg",
      title: "Terjadi kesalahan",
      description: "Anda dapat refresh halaman atau mencoba lagi nanti",
    },
  };

  return (
    <>
      <Container my="16" maxW="3xl">
        <Center flexDir="column" width="full">
          <Box>
            <Image src={placeholder[statusCode].image} alt="error image" loading="lazy" />
            <h1
              className={css({
                textStyle: "lg",
                color: "secondary.darken8",
                fontWeight: "semibold",
                textAlign: "center",
              })}
            >
              {placeholder[statusCode].title}
            </h1>
          </Box>
          <p
            className={css({
              textStyle: "md",
              color: "secondary",
              textAlign: "center",
              maxW: "45ch",
              mt: 3,
              mb: 6,
            })}
          >
            {placeholder[statusCode].description}
          </p>
          <Button visual="outline" onClick={() => (window.location.href = "/")}>
            Kembali Ke Beranda
          </Button>
        </Center>
      </Container>
    </>
  );
};
