import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import React from "react";
import { Combobox, Icon, IconButton, Input, type FormLabelProps, type StyledInputProps } from "~/components/ui";

import { FieldErrors, type FieldValues, type UseControllerProps } from "react-hook-form";
import { Wrapper } from "./Wrapper";
import { css } from "styled-system/css";
import { InfiniteScroller, type InfiniteScrollProps } from "~/components/InfiniteScroll";
import { EmptyState } from "~/components/content";
import { Portal } from "@ark-ui/react";

type ComboboxProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  inputProps?: StyledInputProps;
  labelProps?: FormLabelProps;
  rootProps?: Omit<Combobox.RootProps, "items">;
  contentProps?: Combobox.ContentProps;
  groupLabel?: React.ReactNode;
  required?: boolean;
  items:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
} & InfiniteScrollProps &
  UseControllerProps<T>;

export const ControlledCombobox = <T extends FieldValues>({
  error,
  name,
  control,
  inputProps,
  label,
  labelProps,
  rootProps,
  groupLabel,
  fetchNextPage,
  contentProps,
  hasNextPage,
  endingMessage,
  isLoading,
  loadingMessage,
  items,
  required,
  ...rest
}: ComboboxProps<T>) => {
  return (
    <Wrapper
      required={required}
      control={control}
      error={error}
      labelProps={labelProps}
      label={label}
      name={name}
      {...rest}
    >
      {(name, onChange, value, disabled, ref) => (
        <Combobox.Root
          onValueChange={(v) => {
            onChange(v.value[0]);
          }}
          openOnClick
          items={Array.isArray(items) ? items : []}
          {...rootProps}
        >
          <Combobox.Control>
            <Combobox.Input asChild>
              <Input ref={ref} disabled={disabled} data-invalid={error[name] && true} type="text" {...inputProps} />
            </Combobox.Input>
            <Combobox.Trigger asChild>
              <IconButton variant="link">
                <Icon
                  data-invalid={error[name] && true}
                  color="secondary"
                  className={css({
                    "&[data-invalid=true]": {
                      color: "error",
                    },
                  })}
                >
                  <IconChevronDown />
                </Icon>
              </IconButton>
            </Combobox.Trigger>
          </Combobox.Control>
          <Portal>
            <Combobox.Positioner>
              <Combobox.Content
                position="relative"
                zIndex="99999999999"
                rounded="16px"
                maxH="275px"
                h="full"
                overflowY="scroll"
                {...contentProps}
              >
                <Combobox.ItemGroup id="item">
                  {groupLabel && (
                    <Combobox.ItemGroupLabel htmlFor="item" asChild={React.isValidElement(groupLabel)}>
                      {groupLabel}
                    </Combobox.ItemGroupLabel>
                  )}
                  <InfiniteScroller
                    isLoading={isLoading}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    endingMessage={endingMessage}
                    loadingMessage={loadingMessage}
                  >
                    {items?.length === 0 ? (
                      <EmptyState
                        title="Kategori tidak ditemukan"
                        description="Gunakan keyword yang relevan :)"
                        my="20px"
                        imageStyle={{
                          width: "200px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      items?.map((item) => (
                        <Combobox.Item key={item.value} item={item} py="12px" px="16px" rounded="8px">
                          <Combobox.ItemText textStyle="sm" color="secondary.darken8">
                            {item.label}
                          </Combobox.ItemText>
                          <Combobox.ItemIndicator>
                            <IconCheck />
                          </Combobox.ItemIndicator>
                        </Combobox.Item>
                      ))
                    )}
                  </InfiniteScroller>
                </Combobox.ItemGroup>
              </Combobox.Content>
            </Combobox.Positioner>
          </Portal>
        </Combobox.Root>
      )}
    </Wrapper>
  );
};
