import { queryOptions } from "@tanstack/react-query";
import { MenuItem } from "~/@types";
import { defaultServices } from "~/services";

export const getMenu = queryOptions({
  queryKey: ["menu"],
  queryFn: async () => {
    interface Data {
      _id: string;
      name: string;
      link: string;
      deleted: boolean;
      createdAt: string;
      updatedAt: string;
      __v: number;
      id: string;
    }

    const res = await defaultServices.getList<Data>({
      resource: "/export",
      query: {
        page: 1,
        limit: 30,
      },
    });

    const transform = res.data.list.map((v) => ({
      external: true,
      label: v.name,
      to: v.link,
      items: [],
    }));

    const MENU: MenuItem[] = [
      {
        label: "Beranda",
        key: "home",
        to: "/",
        items: [],
      },
      {
        label: "Forum",
        key: "forum",
        to: "/forum",
        items: [],
      },
      {
        label: "Event",
        key: "event",
        to: "/event",
        items: [],
      },
      {
        label: "Produk",
        key: "product",
        to: "/product",
        items: [],
      },
      {
        label: "Modul",
        key: "modul",
        to: "/module",
        items: [],
      },
      {
        label: "Artikel",
        key: "article",
        to: "/article",
        items: [
          {
            label: "Kiat-kiat",
            key: "kiat-kiat",
            to: "/article/kiat-kiat",
            items: [],
          },
          {
            label: "Berita",
            key: "news",
            to: "/article/news",
            items: [],
          },
          {
            label: "Komunitas",
            key: "community",
            to: "/article/community",
            items: [],
          },
        ],
      },
      {
        label: "Informasi Ekspor",
        key: "export_information",
        items: [],
      },
      {
        label: "Lainnya",
        key: "other",
        items: [
          {
            label: "Badan Pusat Statistika Provinsi Jawa Barat",
            to: "https://jabar.bps.go.id/",
            external: true,
            items: [],
          },
        ],
      },
      {
        label: "Explore",
        key: "explore",
        to: "/explore",
        items: [],
      },
      {
        label: "Kontak Kami",
        key: "contact-us",
        to: "/contact-us",
        items: [],
      },
    ];

    MENU.forEach((v) => {
      if (v.key === "export_information") {
        v.items = transform || [];
      }
    });

    return {
      MENU,
      mobile: MENU,
      dekstop: MENU.filter((v) => !/^(Beranda|Kiat-kiat|Berita|Explore)$/.test(v.label)),
    };
  },
  staleTime: Infinity,
});
