import { forwardRef, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import Froala from "react-froala-wysiwyg";
import { css } from "styled-system/css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// plugin
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/image_manager.min.css";
import "froala-editor/css/plugins/line_breaker.min.css";
import "froala-editor/css/plugins/quick_insert.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/third_party/image_tui.min.js";
import "froala-editor/js/plugins/track_changes.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/word_paste.min.js";

interface EditorFroalaProps {
  model: string | object | null;
  onHandleChange: (e: any) => void;
  optsConfig?: Record<string, any>;
}

const DEFAULT_CONFIG = {
  imageManagerPreloader: "Loading...",
  imageManagerLoadURL: "",
  imageManagerPageSize: 3,
  attribution: false,
  width: "100%",
  imageManagerDeleteURL: "",
  toolbarSticky: true,
  imageManagerDeleteMethod: "DELETE",
  videoUploadURL: "",
  imageUploadURL: "",
  fileUploadURL: "",
  key: import.meta.env.VITE_FROALA_KEY,
  requestHeaders: {
    authorization: "",
  },
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
    },
    moreParagraph: {
      buttons: ["indent", "quote"],
    },
    moreRich: {
      buttons: ["insertLink"],
    },
    moreMisc: {
      buttons: ["undo", "redo"],
      align: "right",
      buttonsVisible: 2,
    },
  },
};

export const EditorFroala = forwardRef<HTMLInputElement, EditorFroalaProps>(
  ({ model, onHandleChange, optsConfig }, ref) => {
    const [config] = useState(DEFAULT_CONFIG);
    const froalaRef = useRef<FroalaEditor>(null);

    return (
      <>
        <Froala ref={froalaRef} tag="textarea" model={model} onModelChange={onHandleChange} config={config} />
        <input
          ref={ref}
          className={css({
            srOnly: true,
          })}
          defaultValue={model as string}
        />
      </>
    );
  },
);
