import { IconLogout } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import { css } from "styled-system/css";
import { Box, HStack } from "styled-system/jsx";

import { Avatar } from "../../Avatar";
import { Icon } from "../../Icon";
import * as Menu from "../../Menu";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getMe } from "~/features/profile";
import { auth } from "~/services";

interface ProfileProps {
  withInformation?: boolean;
}

export const Profile: React.FC<ProfileProps> = ({ withInformation = true }) => {
  const profile = useSuspenseQuery(getMe);

  const handleLogout = async () => {
    await auth.logout();
    window.location.reload();
  };

  return (
    <Menu.Root>
      <Menu.Trigger asChild>
        <HStack cursor="pointer">
          <Avatar src={profile.data?.data?.avatar} name={profile.data.data?.name} />
          {withInformation && (
            <Box>
              <h5
                className={css({
                  textStyle: "sm",
                  fontWeight: "medium",
                  color: "secondary.darken8",
                })}
              >
                {profile?.data?.data?.name}
              </h5>
              <p
                className={css({
                  textStyle: "xs",
                  color: "secondary",
                  lineClamp: 1,
                })}
              >
                {profile?.data?.data?.email}
              </p>
            </Box>
          )}
        </HStack>
      </Menu.Trigger>
      <Menu.Positioner>
        <Menu.Content
          width="204px"
          borderRadius="12px"
          boxShadow="shadow_1"
          p="0"
          gap="0px"
          borderColor="secondary.lighten2"
          borderWidth={1}
        >
          <Menu.Item
            id="profile"
            mx={0}
            borderTopRadius="12px"
            borderBottomRadius="0px"
            h="fit-content"
            py="12px"
            px="16px"
            asChild
          >
            <Link
              to="/profile"
              search={{
                type: "settings",
              }}
              className={css({
                color: "secondary.darken8",
                textStyle: "sm",
                fontWeight: "normal",
                "&[data-status=active]": {
                  color: "primary",
                  fontWeight: "medium",
                },
              })}
            >
              Ubah Profile
            </Link>
          </Menu.Item>
          <Menu.Item id="product" mx={0} h="fit-content" py="12px" px="16px" asChild>
            <Link
              to="/my-product"
              search={{
                category: "",
                keyword: "",
                limit: 9,
                page: 1,
              }}
              className={css({
                color: "secondary.darken8",
                textStyle: "sm",
                fontWeight: "normal",
                "&[data-status=active]": {
                  color: "primary",
                  fontWeight: "medium",
                },
              })}
            >
              {" "}
              Produk Saya
            </Link>
          </Menu.Item>
          <Menu.Item id="meeting" mx={0} h="fit-content" py="12px" px="16px" asChild>
            <Link
              to="/meeting"
              search={{
                page: 1,
                keyword: "",
                limit: 9,
              }}
              className={css({
                color: "secondary.darken8",
                textStyle: "sm",
                fontWeight: "normal",
                "&[data-status=active]": {
                  color: "primary",
                  fontWeight: "medium",
                },
              })}
            >
              Meeting Room
            </Link>
          </Menu.Item>

          <HStack
            mx={0}
            borderTopRadius="0px"
            borderBottomRadius="12px"
            h="fit-content"
            py="12px"
            px="16px"
            _hover={{
              bgColor: "#F0F0F0",
            }}
            cursor="pointer"
            gap="4px"
            onClick={handleLogout}
          >
            <p
              className={css({
                textStyle: "sm",
                fontWeight: "medium",
                color: "red.darken5",
              })}
            >
              Keluar
            </p>
            <Icon color="red.darken5">
              <IconLogout />
            </Icon>
          </HStack>
        </Menu.Content>
      </Menu.Positioner>
    </Menu.Root>
  );
};
