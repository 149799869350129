import { IconArrowLeft } from "@tabler/icons-react";
import { Layout } from "../../layout";
import { Link } from "@tanstack/react-router";
import { Box, Flex } from "styled-system/jsx";
import { css } from "styled-system/css";
import { Button, ControlledInput } from "~/components";
import { useAuth } from "../../hooks";
import { Confirmation } from "../dialog/Confirmation";
import { forgotPasswordSchema } from "~/services";
import { useDisClosure } from "~/hooks";

export const ForgetPassword = () => {
  const { onToggle, open } = useDisClosure();
  const { control, errors, mutation, onSubmit, reset } = useAuth(
    "forgotPassword",
    forgotPasswordSchema,
    {
      defaultValues: {
        email: "",
      },
    },
    {
      onSuccess: () => {
        onToggle();
      },
    },
  );

  return (
    <Layout gap="40px" h="100vh">
      <Flex flexDir="column" gap="24px">
        <Link to="/login">
          <IconArrowLeft />
        </Link>
        <Box>
          <h1
            className={css({
              textStyle: "h6",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            Atur ulang kata sandi
          </h1>
          <p
            className={css({
              textStyle: "md",
              mt: "8px",
              color: "secondary",
            })}
          >
            Mohon masukkan email Anda yang sudah terdaftar. Kami akan mengirimkan tautan verifikasi untuk atur ulang
            kata sandi.
          </p>
        </Box>
        <form
          onSubmit={onSubmit}
          className={css({
            display: "flex",
            flexDir: "column",
            gap: "24px",
          })}
        >
          <ControlledInput
            control={control}
            name="email"
            error={errors}
            label="Email"
            inputProps={{
              placeholder: "ketik disini",
            }}
          />
          <Button loading={mutation.isPending}>Kirim</Button>
        </form>
      </Flex>
      <Confirmation
        open={open}
        type="default"
        email={mutation.variables?.email as string}
        onOpenChange={(v) => {
          onToggle(v.open);
          reset();
        }}
      />
    </Layout>
  );
};
