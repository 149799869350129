import { keepPreviousData, queryOptions } from "@tanstack/react-query";

import { defaultServices } from "~/services";
import { Event } from "~/features/event";
import { BaseQuery } from "~/@types";

export type FetchListEventParams = {
  status: Event["status"];
} & BaseQuery;

export const fetchListEvent = async (q: BaseQuery) => {
  const res = await defaultServices.getList<Event>({
    resource: "/event",
    query: {
      ...q,
    },
  });

  return res;
};

export const fethDetailEvent = async (eventId: string) => {
  const res = await defaultServices.getOne<Event>({
    resource: "/event",
    id: eventId,
  });

  return res;
};

export const getOneEvent = (eventId: string) =>
  queryOptions({
    queryKey: [`event-${eventId}`],
    queryFn: () => fethDetailEvent(eventId),
    staleTime: Infinity,
  });

export const getListEvent = (q: FetchListEventParams) =>
  queryOptions({
    queryKey: ["event", { ...q }],
    queryFn: () => fetchListEvent(q),
    placeholderData: keepPreviousData,
  });
