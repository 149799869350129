/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from "@tanstack/react-router";
import { Detail, articleGetOne } from "~/features/article";

import { ErrorElement } from "~/components/ErrorElement";
import { SEO } from "~/components";

export const Route = createFileRoute("/_layout/article/$articleId")({
  loader: async ({ context: { queryClient }, params: { articleId } }) => {
    return queryClient.ensureQueryData(articleGetOne(articleId));
  },
  component: () => {
    const detail = Route.useLoaderData();

    return (
      <>
        <SEO
          description={detail.data.content ? detail.data.content.slice(0, 100) : "-"}
          title={detail.data.title}
          image={detail.data.image}
          url={`${window.location.hostname}/product/${detail.data.id}`}
        />
        <Detail />
      </>
    );
  },
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
});
