import dayjs from "dayjs";

import { css } from "styled-system/css";
import { type INews } from "../../@types";
import * as Card from "~/components/ui/Card";
import { Link } from "@tanstack/react-router";
import { Image } from "~/components";

interface NewsProps extends Card.RootProps {
  datum: INews;
  hash: string;
}

export const CardNews: React.FC<NewsProps> = ({ datum, hash, ...rest }) => {
  return (
    <Link
      to="/article/$articleId"
      hash={hash}
      params={{
        articleId: datum._id,
      }}
    >
      <Card.Root boxShadow="none" cursor="pointer" bg="transparent" borderRadius="16px" width="100%" {...rest}>
        <Card.Header p="0">
          <Image
            src={datum.image}
            loading="lazy"
            alt={`${datum.title} news`}
            pictureClassName={css({
              height: "240px",
            })}
            imageStyle={{
              borderRadius: "16px",
              width: "full",
              objectFit: "cover",
              height: "240px",
            }}
            style={{
              objectFit: "cover",
            }}
          />
        </Card.Header>
        <Card.Body pb="8px" pt="16px" px="0">
          <Card.Title textStyle="lg" fontWeight="medium" color="secondary.darken8" lineClamp={2}>
            {datum.title}
          </Card.Title>
        </Card.Body>
        <Card.Footer textStyle="md" px="0" color="disabled" justifyContent="flex-start">
          {dayjs(datum.createdAt).format("DD MMMM YYYY")}
        </Card.Footer>
      </Card.Root>
    </Link>
  );
};
