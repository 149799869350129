import React, { useEffect, useRef, useState } from "react";
import { css } from "styled-system/css";
import { Box, HStack, VStack, Flex } from "styled-system/jsx";
import { Avatar, Button, Card, Icon, Image, Popover } from "~/components";
import { useInView } from "framer-motion";
import { Forum } from "../../@types";
import {
  IconArrowsMaximize,
  IconDots,
  IconHeart,
  IconHeartFilled,
  IconMessageCircle,
  IconShare3,
  IconTrash,
} from "@tabler/icons-react";
import { InfiniteData, useQuery, useQueryClient } from "@tanstack/react-query";
import { getMe } from "~/features/profile";
import { Portal } from "@ark-ui/react";
import { useGetComment, useLikeForum, useMutationComment } from "../../hooks";
import { List as CommentList } from "~/features/comment/components/List";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { Link } from "@tanstack/react-router";
import { GetManyResponse } from "~/services";
import "dayjs/locale/id";
import { getId, isYoutubeUrl, openUrl } from "~/utils";
import { useInfiniteScroll } from "~/hooks";

dayjs.locale("id");
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

interface CardForumProps extends Card.RootProps {
  datum: Forum;
  setDatum?: React.Dispatch<React.SetStateAction<Forum>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  search?: any;
  showComment?: boolean;
  onMutate?: () => void;
  maxCategoryWidth?: string;
}

const MAX_CHARACTERS = 300;

export const CardForum: React.FC<CardForumProps> = ({
  datum,
  setDatum,
  setOpen,
  search,
  showComment = true,
  onMutate,
  maxCategoryWidth,
  ...rest
}) => {
  const refComment = useInfiniteScroll();
  const queryClient = useQueryClient();
  const isLogin = queryClient.getQueryData(["isLogin"]);
  const isInView = useInView(refComment, {
    once: true,
  });
  const profile = useQuery(getMe);
  const ref = useRef<HTMLTextAreaElement>(null);
  const { result } = useGetComment(datum.id, isInView);
  const { mutation, mutationDelete, setCommentId } = useMutationComment(datum.id);

  const mutationLike = useLikeForum(
    {
      id: datum.id,
      isLiked: datum.like,
    },
    {
      onMutate: () => {
        if (typeof onMutate === "function") {
          onMutate();
          return;
        }

        const queryKey = [`forum_${search.category}`];

        queryClient.setQueryData(queryKey, (oldData: InfiniteData<GetManyResponse<Forum>>) => {
          return {
            ...oldData,
            pages: oldData.pages.map((v) => ({
              ...v,
              data: {
                ...v.data,
                list: v.data.list.map((v) => {
                  if (v.id === datum.id) {
                    return {
                      ...v,
                      totalLike: datum.like ? v.totalLike - 1 : v.totalLike + 1,
                      like: !datum.like,
                    };
                  }

                  return v;
                }),
              },
            })),
          };
        });
      },
    },
  );

  const [content, setContent] = useState(() => {
    if (datum.description.length > MAX_CHARACTERS) {
      return datum.description.slice(0, MAX_CHARACTERS);
    }

    return datum.description;
  });

  const handleSnippet = () => {
    if (content.length !== datum.description.length) {
      setContent(datum.description);
    } else {
      setContent((v) => {
        return v.slice(0, MAX_CHARACTERS);
      });
    }
  };

  return (
    <Card.Root width="full" boxShadow="shadow" border="none" rounded="16px" {...rest}>
      <Card.Header p="4">
        <Card.Title asChild>
          <Flex justifyContent="space-between" alignItems="center">
            <HStack>
              <Avatar src={datum.createdBy?.avatar as string} name={datum.createdBy?.name} />
              <VStack alignItems="start" gap="0">
                <h3
                  className={css({
                    textStyle: "md",
                    color: "secondary.darken8",
                    fontWeight: "semibold",
                    fontFamily: "outfit!",
                  })}
                >
                  {datum.createdBy?.name}
                </h3>
                <HStack>
                  <p
                    className={css({
                      textStyle: {
                        base: "xs",
                        sm: "sm",
                      },
                      color: "secondary",
                    })}
                  >
                    {dayjs(datum.createdAt).tz("Asia/Jakarta").fromNow()}
                  </p>
                  <div
                    className={css({
                      width: "2px",
                      height: "2px",
                      bgColor: "secondary",
                      rounded: "full",
                    })}
                  />
                  <p
                    className={css({
                      color: "primary",
                      textStyle: {
                        base: "xs",
                        sm: "sm",
                      },
                      lineClamp: 1,
                      fontWeight: "normal",
                      maxW: maxCategoryWidth,
                    })}
                  >
                    {datum.category?.name}
                  </p>
                </HStack>
              </VStack>
            </HStack>
            {profile?.data?.data?.id === datum.createdBy?.id && (
              <Popover.Root>
                <Popover.Trigger minH="fit-content" h="fit-content">
                  <Icon color="disabled" cursor="pointer">
                    <IconDots />
                  </Icon>
                </Popover.Trigger>
                <Portal>
                  <Popover.Positioner>
                    <Popover.Content>
                      <Popover.Title asChild>
                        <VStack alignItems="start" gap="4">
                          <Button
                            visual="ghost"
                            h="fit-content!"
                            minH="fit-content!"
                            onClick={() => {
                              setOpen?.(true);
                              setDatum?.(datum);
                            }}
                            alignItems="center"
                            justifyContent="center"
                            px={0}
                          >
                            <Icon color="secondary">
                              <IconTrash />
                            </Icon>
                            <p
                              className={css({
                                fontWeight: "medium",
                                color: "secondary.darken8",
                                textStyle: "sm",
                              })}
                            >
                              Hapus Postingan
                            </p>
                          </Button>
                        </VStack>
                      </Popover.Title>
                    </Popover.Content>
                  </Popover.Positioner>
                </Portal>
              </Popover.Root>
            )}
          </Flex>
        </Card.Title>
        <Card.Description py="4" asChild>
          <Box>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              className={css({
                textStyle: "md",
                color: "secondary.darken8",
                lineClamp: 2,
                "& > *": {
                  textStyle: "md",
                  color: "secondary.darken8",
                  lineClamp: 2,
                },
              })}
            />

            {datum.description.length > MAX_CHARACTERS && (
              <span
                onClick={handleSnippet}
                className={css({
                  cursor: "pointer",
                  color: "primary",
                  fontWeight: "bold",
                  textDecoration: "underline",
                })}
              >
                {content.length !== datum.description.length ? "Baca Selengkapnya" : "Lihat lebih sedikit"}
              </span>
            )}
          </Box>
        </Card.Description>
      </Card.Header>
      <Card.Body p="0" width="full">
        <Link
          to="/forum/$forumId"
          params={{
            forumId: datum.id,
          }}
          resetScroll={false}
        >
          {!!datum.image && (
            <Box position="relative" w="full">
              <Image
                alt="forum"
                pictureClassName={css({
                  maxH: "355px",
                })}
                imageStyle={{
                  width: "full",
                  height: "auto",
                  maxH: "355px",
                }}
                style={{
                  objectFit: "cover",
                }}
                src={datum.image}
              />
              <Box
                w="52px"
                h="52px"
                rounded="full"
                bgColor="white"
                boxShadow="shadow"
                pos="absolute"
                bottom={19}
                right={19}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="secondary" size="md">
                  <IconArrowsMaximize />
                </Icon>
              </Box>
            </Box>
          )}
          {datum.video ? (
            isYoutubeUrl(datum.video) ? (
              <iframe
                width="100%"
                allowFullScreen
                height="345"
                src={`https://www.youtube.com/embed/${getId(datum.video)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className={css({
                  border: "0px",
                })}
              ></iframe>
            ) : (
              <video
                controls
                className={css({
                  width: "full",
                })}
              >
                <source src={datum.video!} type="video/ogg" />
                <source src={datum.video!} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          ) : null}
        </Link>

        <Flex
          px={{
            base: "4",
            md: "8",
            lg: "12",
          }}
          py="4"
          justifyContent="space-between"
          borderTopWidth={1}
          borderBottomWidth={1}
          borderColor="secondary.lighten2"
        >
          <HStack
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              mutationLike.mutate();
            }}
            tabIndex={0}
          >
            {datum.like ? (
              <Icon color="primary">
                <IconHeartFilled />
              </Icon>
            ) : (
              <Icon color="secondary">
                <IconHeart />
              </Icon>
            )}
            <p
              className={css({
                textStyle: "md",
                color: "secondary",
                fontWeight: "medium",
              })}
            >
              {`${datum.totalLike} Suka`}
            </p>
          </HStack>
          <HStack
            cursor="pointer"
            onClick={() => {
              if (ref.current) {
                ref.current.focus();
              }
            }}
          >
            <Icon color="secondary">
              <IconMessageCircle />
            </Icon>
            <p
              className={css({
                textStyle: "md",
                color: "secondary",
                fontWeight: "medium",
              })}
            >
              {result.data?.paging.count} Komentar
            </p>
          </HStack>
          <HStack
            cursor="pointer"
            onClick={() =>
              openUrl(
                `whatsapp://send?text=Lihat artikel ecobiz ini: https://${window.location.hostname}/forum/${datum._id}`,
              )
            }
          >
            <Icon color="secondary">
              <IconShare3 />
            </Icon>
            <p
              className={css({
                textStyle: "md",
                color: "secondary",
                fontWeight: "medium",
              })}
            >
              Bagikan
            </p>
          </HStack>
        </Flex>

        {showComment && (
          <Box pt="4" px="4" h="calc(100% - 24px)">
            {result.hasNextPage && (
              <Button
                onClick={() => result.fetchNextPage()}
                visual="ghost"
                colorPalette="secondary"
                loading={result.isFetchingNextPage}
              >
                <p
                  className={css({
                    fontFamily: "dmSans",
                  })}
                >
                  Lihat Komentar lain
                </p>
              </Button>
            )}

            <CommentList
              comments={result.data?.list}
              mutation={mutation}
              data-login={!!isLogin}
              isLoading={result.isLoading}
              setCommentId={setCommentId}
              maxH="650px"
              overflowY="auto"
              flexDir="column-reverse"
              ref={ref}
              mutationDelete={mutationDelete}
              className={css({
                "&[data-login=false]": {
                  mb: 4,
                },
              })}
            />
            <div ref={refComment} />
          </Box>
        )}
      </Card.Body>
    </Card.Root>
  );
};
