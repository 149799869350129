import { queryOptions, keepPreviousData, infiniteQueryOptions } from "@tanstack/react-query";
import { type FetchListProductParams, fetchListProduct, fetchOneProduct } from "../service";

export const getListProduct = (q: FetchListProductParams) =>
  queryOptions({
    queryKey: ["product", { ...q }],
    queryFn: () => fetchListProduct(q),
    placeholderData: keepPreviousData,
  });

export const getOneProduct = (productId: string) =>
  queryOptions({
    queryKey: [`detail-product-${productId}`],
    queryFn: () => fetchOneProduct(productId),
    staleTime: Infinity,
    enabled: !!productId,
  });

export const getListProductInfiniteQuery = (q: FetchListProductParams) =>
  infiniteQueryOptions({
    queryKey: [`product_${q.keyword}_${q.category}`],
    queryFn: async ({ pageParam }) => {
      const res = await fetchListProduct({
        ...q,
        page: pageParam,
      });

      return res;
    },
    staleTime: 50_000,
    initialPageParam: q.page,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });
