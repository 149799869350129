import { defaultServices } from "~/services";
import { INews } from "../@types";
import { BaseQuery } from "~/@types";

type Article = "default" | "tips" | "community";

export type Params = {
  type: Article;
  except?: string;
} & BaseQuery;

export const fetchArticle = async (q: Params) => {
  const res = await defaultServices.getList<INews>({
    resource: "/news",
    query: {
      ...q,
    },
  });

  return res;
};

export const fetchDetail = async (articleId: string) => {
  const res = await defaultServices.getOne<INews>({
    resource: "/news",
    id: articleId,
  });

  return res;
};
