import { createFileRoute } from "@tanstack/react-router";
import { List } from "~/features/forum";
import { z } from "zod";
import { getListForumInfiniteQuery } from "~/features/forum/query-options";
import { BackToTop, SEO } from "~/components";
import { ErrorElement } from "~/components/ErrorElement";

const forumSearchSchema = z.object({
  category: z.string().catch(""),
  createdBy: z.string().catch(""),
  userCategory: z.string().catch(""),
});

type ForumSearchSchema = z.infer<typeof forumSearchSchema>;

export const Route = createFileRoute("/_layout/forum/")({
  validateSearch: (search: Record<string, unknown>): ForumSearchSchema => forumSearchSchema.parse(search),
  loaderDeps: ({ search: { category, createdBy, userCategory } }) => ({ category, createdBy, userCategory }),
  loader: ({ context: { queryClient }, deps: { category, createdBy, userCategory } }) => {
    return queryClient.fetchInfiniteQuery(
      getListForumInfiniteQuery({
        category,
        createdBy,
        userCategory,
      }),
    );
  },

  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,

  component: () => (
    <>
      <SEO title="Forum" description="Forum Ecobiz" />
      <List />
      <BackToTop
        right={{
          base: "12px",
        }}
      />
    </>
  ),
});
