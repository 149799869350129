import { css } from "styled-system/css";
import { Flex, Box, Center } from "styled-system/jsx";

import { BaseModal } from "~/components/dialog";
import { Badge } from "~/components/ui/Badge";
import type * as Dialog from "~/components/ui/Dialog";
import type React from "react";
import { Button } from "~/components";

interface Datum {
  label: string;
  value: string;
}

interface CategoryProps extends Dialog.RootProps {
  open: boolean;
  onToggle: (param?: boolean) => void;
  onHandleChange: (v: string) => void;
  value: string;
  datum: Datum[] | undefined;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export const Category: React.FC<CategoryProps> = ({
  open,
  onToggle,
  onHandleChange,
  value,
  datum,
  fetchNextPage,
  hasNextPage,
  ...rest
}) => {
  const onMutatelist = (v: Datum) => {
    onHandleChange(v.value);
    onToggle();
  };

  return (
    <BaseModal
      title="Kategori Lainnya"
      description={
        <Box w="full">
          <Flex flexWrap="wrap" gap="12px" maxW="552px">
            {datum?.map((option) => (
              <Badge
                height="40px"
                px="24px"
                py="8px"
                cursor="pointer"
                colorPalette="secondary"
                bgColor="colorPalette.lighten1"
                minW="fit-content"
                borderColor="colorPalette.lighten2"
                borderRadius="8px"
                textStyle="md"
                color="colorPalette"
                onClick={() => onMutatelist(option)}
                data-state={value === option.value ? "checked" : "unchecked"}
                key={option.value}
                className={css({
                  "&[data-state=checked]": {
                    colorPalette: "primary",
                    borderColor: "colorPalette",
                    backgroundColor: "green.lighten1",
                  },
                })}
              >
                {option.label}
              </Badge>
            ))}
          </Flex>
          {hasNextPage && (
            <Center my={4}>
              <Button onClick={() => fetchNextPage()} visual="ghost" variant="primary">
                Lihat Lebih Banyak
              </Button>
            </Center>
          )}
        </Box>
      }
      open={open}
      onToggle={onToggle}
      contentProps={{
        width: "552px",
      }}
      rootProps={{
        ...rest,
      }}
    />
  );
};
