import React from "react";
import { css } from "styled-system/css";
import { HStack } from "styled-system/jsx";
import { Dialog, Button } from "~/components";

interface DeleteProps extends Dialog.RootProps {
  name: string;
  isLoading: boolean;
  onHandleDelete: () => void;
}

export const Delete: React.FC<DeleteProps> = ({ name, onHandleDelete, isLoading, ...props }) => {
  return (
    <Dialog.Root {...props}>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content p={6} maxW="512px" boxShadow="shadow_2" rounded="12px">
          <h3
            className={css({
              textStyle: "lg",
              color: "secondary.darken8",
              fontWeight: "semibold",
              fontFamily: "outfit",
            })}
          >
            Hapus Produk {name}?
          </h3>
          <p
            className={css({
              textStyle: "sm",
              fontWeight: "normal",
              marginTop: "8px",
              color: "secondary",
            })}
          >
            Produk yang sudah dihapus tidak dapat dikembalikan.
          </p>
          <HStack justifyContent="end" mt={6}>
            <Dialog.CloseTrigger asChild>
              <Button visual="outline" colorPalette="secondary">
                Batal
              </Button>
            </Dialog.CloseTrigger>
            <Button colorPalette="red" onClick={onHandleDelete} loading={isLoading}>
              Hapus
            </Button>
          </HStack>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};
