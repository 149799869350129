import { createFileRoute, redirect } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { z } from "zod";
import { ErrorElement } from "~/components/ErrorElement";

import { Profile } from "~/features/profile/components";

const profileSearchSchema = z.object({
  type: z.enum(["settings", "update-password", ""]).catch("settings"),
});

type ProfileSearchShema = z.infer<typeof profileSearchSchema>;

export const Route = createFileRoute("/_layout/profile/")({
  beforeLoad: ({ context: { queryClient } }) => {
    const isLogin = queryClient.getQueryData(["isLogin"]);

    if (!isLogin) {
      throw redirect({
        to: "/",
      });
    }
  },
  validateSearch: (search: Record<string, unknown>): ProfileSearchShema => profileSearchSchema.parse(search),

  component: () => (
    <>
      <Helmet>
        <title>Profile - Ecobiz</title>
      </Helmet>
      <Profile />
    </>
  ),
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
});
