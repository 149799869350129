import { Layout } from "../../layout";
import { Box, Flex } from "styled-system/jsx";
import { css } from "styled-system/css";
import { Button, ControlledInput, Image } from "~/components";
import { Confirmation } from "../dialog/Confirmation";
import { useAuth } from "../../hooks";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { setPasswordSchema } from "~/services";
import { useDisClosure } from "~/hooks";
import { useToast } from "~/context/Toast";

const routeResetPassword = getRouteApi("/reset-password");

export const ResetPassword = () => {
  const search = routeResetPassword.useSearch();
  const { onToggle, open } = useDisClosure();
  const { toast } = useToast();
  const navigate = useNavigate({ from: "/reset-password" });

  const { control, errors, mutation, onSubmit } = useAuth(
    "setPassword",
    setPasswordSchema,
    {
      values: {
        confirmPassword: "",
        password: "",
        token: search.token,
      },
    },
    {
      onSuccess: () => {
        navigate({
          to: "/login",
          search: {
            destinationPath: "/",
          },
        });
        toast.success({
          title: "Berhasil Reset Password",
        });
      },
      onError: () => {
        onToggle();
      },
    },
  );

  return (
    <Layout gap="40px" h="100vh">
      <Flex flexDir="column" gap="16px">
        <Image
          width={48}
          height={48}
          src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709538687970_UI User Website key.svg"
          alt="logo-key"
        />
        <Box>
          <h1
            className={css({
              textStyle: "h6",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            Kata sandi baru
          </h1>
          <p
            className={css({
              textStyle: "md",
              mt: "8px",
              color: "secondary",
            })}
          >
            Silakan masukkan kata sandi baru Anda.
          </p>
        </Box>
        <form
          onSubmit={onSubmit}
          className={css({
            display: "flex",
            flexDir: "column",
            gap: "24px",
          })}
        >
          <ControlledInput
            control={control}
            name="password"
            error={errors}
            label="Kata Sandi Baru"
            inputProps={{
              placeholder: "Ketik disini",
              type: "password",
            }}
          />
          <ControlledInput
            control={control}
            name="confirmPassword"
            error={errors}
            label="Ulangi Kata Sandi Baru"
            inputProps={{
              placeholder: "Ketik disini",
              type: "password",
            }}
          />
          <Button loading={mutation.isPending}>Kirim</Button>
        </form>
      </Flex>
      <Confirmation
        open={open}
        errorMessage={mutation.error?.message}
        type="expired"
        onOpenChange={(v) => {
          onToggle(v.open);
          navigate({ to: "/login" });
        }}
      />
    </Layout>
  );
};
