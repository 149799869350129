import { createFileRoute, redirect } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { z } from "zod";
import { List } from "~/features/my-product";
import { getListProduct } from "~/features/product";

const myProductSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(9),
  keyword: z.string().catch(""),
  category: z.string().catch(""),
});

type MyProductSearch = z.infer<typeof myProductSearchSchema>;

export const Route = createFileRoute("/_layout/my-product/")({
  beforeLoad: ({ context: { queryClient } }) => {
    const isLogin = queryClient.getQueryData(["isLogin"]);

    if (!isLogin) {
      throw redirect({
        to: "/",
      });
    }
  },
  validateSearch: (search: Record<string, unknown>): MyProductSearch => myProductSearchSchema.parse(search),
  loaderDeps: ({ search: { keyword, limit, page, category } }) => ({ keyword, limit, page, category }),
  loader: ({ context: { queryClient }, deps: { page, category, keyword } }) => {
    return queryClient.ensureQueryData(
      getListProduct({
        category,
        keyword,
        page,
        myProduct: true,
      }),
    );
  },
  gcTime: 0,
  shouldReload: false,
  wrapInSuspense: true,
  component: () => (
    <>
      <Helmet>
        <title>Produk Saya - Ecobiz</title>
      </Helmet>
      <List />
    </>
  ),
});
