import { useState } from "react";

export const useDisClosure = () => {
  const [open, setOpen] = useState(false);

  const onToggle = (v?: boolean) => {
    typeof v === "boolean" ? setOpen(v) : setOpen((v) => !v);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return { open, onOpen, onClose, onToggle };
};
