import { IconMicrophone, IconPhoto, IconSend, IconVideo, IconX } from "@tabler/icons-react";
import { UseMutationResult, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { forwardRef, useEffect } from "react";
import { css } from "styled-system/css";
import { Box, Flex, HStack, VStack } from "styled-system/jsx";
import { P, match } from "ts-pattern";
import { IconButton, Image } from "~/components";
import { Avatar } from "~/components/ui/Avatar";
import { Button } from "~/components/ui/Button";
import { Icon } from "~/components/ui/Icon";
import { Input } from "~/components/ui/Input";
import * as Popover from "~/components/ui/Popover";
import { Progress } from "~/components/ui/Progress";
import * as Tabs from "~/components/ui/Tabs";
import { Textarea, type TextareaProps } from "~/components/ui/Textarea";
import {
  ALLOWED_FILES,
  AUDIO_MIME_TYPES,
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES,
  type AuidoMimeTypes,
  type ImageMimeTypes,
  type VideoMimeTypes,
} from "~/constant";
import { useEditor } from "../hooks";
import { BaseCommentSchema } from "../schema";
import { getMe } from "~/features/profile";
import { Comment } from "../@types";
import { getId, mergeRefs } from "~/utils";

type EditorProps = {
  mutation: UseMutationResult<Record<string, any>, AxiosError, BaseCommentSchema>;
  placeholderData?: Comment;
} & TextareaProps;

export const Editor = forwardRef<HTMLTextAreaElement, EditorProps>(({ mutation, placeholderData, ...rest }, ref) => {
  const profile = useQuery(getMe);
  const {
    editorRef,
    handleChange,
    toggleFileUpload,
    inputFileRef,
    setValue,
    setFile,
    file,
    value,
    handleFile,
    progress,
    mutationMedia,
    type,
    resetEditor,
    embedYoutube,
    setEmbedYoutube,
    setValueEmbetYoutube,
    setType,
    valueEmbedYoutube,
    error,
    setErorr,
    handleEmbedLink,
  } = useEditor();

  const onHandleSubmit = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.code === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      if (!mutation.isPending && value) {
        mutation.mutate({
          content: value,
          [type]: mutationMedia?.data?.data?.url ?? embedYoutube,
        });
      }
      return false;
    }
  };

  const PartialSectionStatus = match([mutationMedia.status, mutationMedia.data?.data.mimetype])
    .with(["pending", P._], () => {
      return (
        <>
          <p
            className={css({
              textStyle: "md",
              color: "secondary",
            })}
          >
            Upload Media..
          </p>
          <Progress value={progress} min={0} size="lg" max={100} width="70%" colorPalette="primary" />
        </>
      );
    })
    .with(["success", P.select()], (mimeType) => (
      <Box pos="relative">
        {IMAGE_MIME_TYPES.includes(mimeType as unknown as ImageMimeTypes) && (
          <Image
            src={file!}
            imageStyle={{
              maxW: "218px",
              width: "full",
              height: "auto",
              objectFit: "cover",
              rounded: "12px",
            }}
            loading="eager"
          />
        )}

        {VIDEO_MIME_TYPES.includes(mimeType as unknown as VideoMimeTypes) && (
          <video
            width="320"
            height="240"
            controls
            className={css({
              maxW: "320px",
              width: "full",
              rounded: "12px",
            })}
          >
            <source src={file!} type="video/ogg" />
            <source src={file!} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        {AUDIO_MIME_TYPES.includes(mimeType as unknown as AuidoMimeTypes) && (
          <audio controls>
            <source src={file!} type="audio/ogg" />
            <source src={file!} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="20px"
          height="20px"
          pos="absolute"
          top="-5px"
          right="-10px"
          cursor="pointer"
          color="error"
          bgColor="white"
          borderColor="error"
          borderWidth="1px"
          rounded="50%"
          onClick={() => mutationMedia.reset()}
        >
          <Icon color="error" width="14px" height="14px">
            <IconX />
          </Icon>
        </Box>
      </Box>
    ))
    .with(["error", P._], () => (
      <>
        <p
          className={css({
            textStyle: "md",
            color: "secondary",
          })}
        >
          Terjadi Kesalahan
        </p>
        <Button size="sm" onClick={() => mutationMedia.mutate(mutationMedia.variables as File)}>
          Ulangi Lagi
        </Button>
      </>
    ))
    .otherwise(() => null);

  useEffect(() => {
    if (mutation.isSuccess) {
      resetEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (placeholderData?.image || placeholderData?.video || placeholderData?.audio) {
      setFile(placeholderData.image || placeholderData.video || placeholderData.audio);
    }

    if (placeholderData?.content) {
      setValue(placeholderData.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allowedFile = ALLOWED_FILES[type].join(",");

  return (
    <Flex gap="8px" width="full">
      <Avatar src={profile.data?.data.avatar} name={profile.data?.data.name} />
      <Box width="full">
        <Box
          p="16px"
          width="inherit"
          bgColor="secondary.lighten1"
          borderColor="secondary.lighten3"
          rounded="20px"
          display="flex"
          flexDir="column"
          gap="16px"
        >
          <Textarea
            {...rest}
            ref={mergeRefs([editorRef, ref])}
            rows={1}
            className={css({
              resize: "none",
              overflow: "hidden",
              border: "unset",
              borderWidth: "0px!",
            })}
            placeholder="Tulis Komentar.."
            textStyle="md"
            _placeholder={{
              textStyle: "md",
              color: "disabled",
            }}
            _focus={{
              borderColor: "unset",
              boxShadow: "unset",
            }}
            resize="none"
            overflow="hidden"
            p="0"
            border="unset"
            borderWidth="0px"
            onChange={handleChange}
            value={value}
            onKeyDown={onHandleSubmit}
          />
          <Flex justifyContent="space-between">
            <HStack>
              <Icon size="lg" color="secondary" onClick={toggleFileUpload("image")} cursor="pointer">
                <IconPhoto />
              </Icon>
              <Popover.Root
                positioning={{
                  placement: "top",
                }}
              >
                <Popover.Trigger asChild>
                  <Icon size="lg" color="secondary" cursor="pointer">
                    <IconVideo />
                  </Icon>
                </Popover.Trigger>
                <Popover.Positioner>
                  <Popover.Content minW="302px">
                    <Popover.Arrow>
                      <Popover.ArrowTip />
                    </Popover.Arrow>
                    <Popover.Title asChild>
                      <Tabs.Root>
                        <Tabs.List boxShadow="unset">
                          <Tabs.Trigger value="file" asChild>
                            <Box
                              px="16px"
                              height="fit-content"
                              py="4px"
                              bgColor="secondary.lighten1"
                              borderWidth="1px"
                              borderColor="secondary.lighten2"
                              rounded="8px"
                              className={css({
                                "&[aria-selected=true]": {
                                  borderColor: "primary",
                                  bgColor: "green.lighten1",
                                  color: "primary",
                                  "& > p": {
                                    color: "primary!",
                                    fontWeight: "medium",
                                  },
                                },
                              })}
                            >
                              <p
                                className={css({
                                  textStyle: "sm",
                                  color: "secondary",
                                  fontWeight: "normal",
                                })}
                              >
                                Upload File
                              </p>
                            </Box>
                          </Tabs.Trigger>
                          <Tabs.Trigger value="link" asChild>
                            <Box
                              px="16px"
                              height="fit-content"
                              py="4px"
                              bgColor="secondary.lighten1"
                              borderWidth="1px"
                              borderColor="secondary.lighten2"
                              rounded="8px"
                              className={css({
                                "&[aria-selected=true]": {
                                  borderColor: "primary",
                                  bgColor: "green.lighten1",
                                  fontWeight: "medium",
                                  "& > p": {
                                    color: "primary!",
                                    fontWeight: "medium",
                                  },
                                },
                              })}
                            >
                              <p
                                className={css({
                                  textStyle: "sm",
                                  color: "secondary",
                                  fontWeight: "normal",
                                })}
                              >
                                Youtube Link
                              </p>
                            </Box>
                          </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="file">
                          <Box>
                            <Button width="full" textStyle="md" onClick={toggleFileUpload("video")}>
                              Pilih File
                            </Button>
                          </Box>
                        </Tabs.Content>
                        <Tabs.Content value="link">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (error || !valueEmbedYoutube) {
                                setErorr(true);
                                return;
                              }
                              setType("video");
                              setEmbedYoutube(valueEmbedYoutube);
                              setValueEmbetYoutube("");
                            }}
                          >
                            <VStack width="100%" alignItems="start">
                              <Input
                                value={valueEmbedYoutube}
                                onChange={handleEmbedLink}
                                width="full"
                                placeholder="Paste link disini"
                              />
                              {error && (
                                <p
                                  className={css({
                                    textStyle: "sm",
                                    color: "error",
                                  })}
                                >
                                  Link youtube tidak valid
                                </p>
                              )}
                              <Button width="full" type="submit" textStyle="md">
                                Embed Link
                              </Button>
                            </VStack>
                          </form>
                        </Tabs.Content>
                      </Tabs.Root>
                    </Popover.Title>
                  </Popover.Content>
                </Popover.Positioner>
              </Popover.Root>

              <Icon size="lg" cursor="pointer" color="secondary" onClick={toggleFileUpload("audio")}>
                <IconMicrophone />
              </Icon>
            </HStack>
            <IconButton
              size="lg"
              variant="ghost"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                mutation.mutate({
                  content: value,
                  [type]: mutationMedia?.data?.data?.url ?? embedYoutube,
                });
              }}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bgColor: "transparent",
              }}
              cursor="pointer"
              aria-disabled={!value}
              className={css({
                color: "primary",
                "&[aria-disabled=true]": {
                  cursor: "not-allowed!",
                  color: "disabled.text",
                },
              })}
            >
              {mutation.isPending ? <Progress value={null} type="circular" /> : <IconSend />}
            </IconButton>
            <input
              ref={inputFileRef}
              type="file"
              accept={allowedFile}
              onChange={handleFile}
              className={css({
                srOnly: true,
              })}
            />
          </Flex>
        </Box>

        {embedYoutube && (
          <Box position="relative" mt="16px">
            <iframe
              width="100%"
              height="345"
              allowFullScreen
              src={`https://www.youtube.com/embed/${getId(embedYoutube)}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              className={css({
                rounded: "16px",
                border: "0px",
              })}
            ></iframe>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="20px"
              height="20px"
              pos="absolute"
              top="0px"
              right="-5px"
              cursor="pointer"
              color="error"
              bgColor="white"
              borderColor="error"
              borderWidth="1px"
              rounded="50%"
              onClick={() => setEmbedYoutube("")}
            >
              <Icon color="error" width="14px" height="14px">
                <IconX />
              </Icon>
            </Box>
          </Box>
        )}

        {mutationMedia.variables && (
          <Flex gap="8px" justifyContent="space-between" width="full" alignItems="center" mt="16px">
            {PartialSectionStatus!}
          </Flex>
        )}
      </Box>
    </Flex>
  );
});
