import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { fetchDetailMeeting, fetchListMeeting } from "../service";
import { BaseQuery } from "~/@types";

export const getOneMeeting = (articleId: string) =>
  queryOptions({
    queryKey: [`news-${articleId}`],
    queryFn: () => fetchDetailMeeting(articleId),
    staleTime: Infinity,
  });

export const getListMeeting = (q: BaseQuery) =>
  queryOptions({
    queryKey: ["news", { ...q }],
    queryFn: () => fetchListMeeting(q),
    staleTime: 50_000,
    placeholderData: keepPreviousData,
  });
