import { Box, Flex, HStack, VStack, Container, Grid, GridItem } from "styled-system/jsx";
import { css } from "styled-system/css";
import { Badge } from "~/components/ui/Badge";
import { Avatar } from "~/components/ui/Avatar";
import { normalizeIDR, openUrl } from "~/utils";
import { IconMapPinFilled } from "@tabler/icons-react";
import { Button } from "~/components/ui/Button";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import { List as CommentList } from "~/features/comment/components/List";
import { BreadCrumb } from "~/components/ui/Breadcrumb";
import { type IBreadCrumb } from "~/@types";
import { getRouteApi } from "@tanstack/react-router";
import { useGetComment, useMutationComment } from "../../hooks";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { getOneProduct } from "../../query-options";
import Lightbox from "yet-another-react-lightbox";
import { useDisClosure } from "~/hooks";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";
import { Image, SEO } from "~/components";

export const Detail = () => {
  const { open, onOpen, onClose } = useDisClosure();
  const getRoute = getRouteApi("/_layout/product/$productId");
  const productId = getRoute.useParams().productId;
  const queryClient = useQueryClient();
  const isLogin = queryClient.getQueryData(["isLogin"]);
  const datum = useSuspenseQuery(getOneProduct(productId));
  const { result } = useGetComment();
  const { mutation, setCommentId, mutationDelete } = useMutationComment();

  const breadcrumbs: IBreadCrumb[] = [
    {
      path: "Produk",
      to: "/product",
    },
    {
      path: datum.data.data?.category?.name,
      to: `/product?category=${datum.data.data?.category?.id}`,
    },
    {
      path: datum.data.data.name,
      to: "#",
      disabled: true,
    },
  ];

  return (
    <>
      <Container maxW="7xl" pt="24px" pb="80px">
        <BreadCrumb items={breadcrumbs} mb="40px" />
        <VStack alignItems="start" gap="40px">
          <Grid
            display="grid"
            width="full"
            gridTemplateColumns={{
              base: "1",
              md: "repeat(3, 1fr)",
            }}
            gap="32px"
          >
            <GridItem gridColumn="1">
              <Image
                src={
                  datum.data.data.image ??
                  "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg"
                }
                onClick={onOpen}
                alt={datum.data.data.name}
                imageStyle={{
                  objectFit: "cover",
                  borderRadius: "24px",
                  width: "full",
                  cursor: "pointer",
                }}
              />
            </GridItem>
            <GridItem
              gridColumn={{
                base: "1",
                md: "2",
              }}
            >
              <Flex alignItems="start" gap="16px" flexDir="column" width="100%">
                <Box>
                  <Badge
                    colorPalette="primary"
                    bgColor="colorPalette"
                    px="16px"
                    py="4px"
                    color="white"
                    borderRadius="4px"
                    border="unset"
                    textStyle="xs"
                    fontFamily="outfit"
                    fontWeight="semibold"
                  >
                    {datum.data.data?.category?.name}
                  </Badge>
                  <Box mt="17px">
                    <h1
                      className={css({
                        textStyle: "lg",
                        fontWeight: "medium",
                        color: "secondary.darken8",
                        mb: "4px",
                      })}
                    >
                      {datum.data.data.name}
                    </h1>
                    <h2
                      className={css({
                        textStyle: "h5",
                        fontWeight: "bold",
                        color: "secondary.darken8",
                      })}
                    >
                      {normalizeIDR(datum.data.data.price)}
                    </h2>
                  </Box>
                </Box>
                <Flex
                  colorPalette="secondary"
                  bgColor="colorPalette.lighten1"
                  rounded="16px"
                  width="100%"
                  flexDir={{
                    base: "column",
                    md: "row",
                  }}
                  p="16px"
                  justifyContent="space-between"
                  gap="16px"
                  alignItems={{
                    base: "start",
                    md: "center",
                  }}
                >
                  <HStack>
                    <Avatar
                      src={datum.data?.data.createdBy?.avatar as string}
                      name={datum.data.data.createdBy.name}
                      size="2xl"
                    />
                    <VStack alignItems="start">
                      <p
                        className={css({
                          textStyle: "md",
                          fontWeight: "medium",
                          color: " secondary.darken8",
                        })}
                      >
                        {datum.data.data.createdBy.name}
                      </p>
                      <HStack>
                        <IconMapPinFilled
                          className={css({
                            color: "disabled",
                            width: "20px",
                            height: "20px",
                          })}
                        />
                        <p
                          className={css({
                            textStyle: "sm",
                            color: " secondary",
                          })}
                        >
                          {datum.data.data.origin}
                        </p>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Button
                    onClick={() =>
                      openUrl(
                        `https://api.whatsapp.com/send/?phone=${datum.data.data.createdBy.whatsapp}&text&type=phone_number&app_absent=0`,
                      )
                    }
                    disabled={!datum.data.data.createdBy.whatsapp}
                    width={{
                      base: "full",
                      md: "fit-content",
                    }}
                  >
                    <IconBrandWhatsapp
                      className={css({
                        color: "white",
                      })}
                    />
                    Hubungi Penjual
                  </Button>
                </Flex>
                <hr
                  className={css({
                    width: "full",
                    bgColor: "secondary.lighten2",
                  })}
                />
                <Box>
                  <h5
                    className={css({
                      textStyle: "md",
                      color: "secondary.darken8",
                      fontWeight: "semibold",
                      fontFamily: "outfit",
                    })}
                  >
                    Spesifikasi
                  </h5>
                  <p
                    className={css({
                      mt: "8px",
                      textStyle: "md",
                      color: "secondary",
                    })}
                  >
                    {datum.data.data.spec}
                  </p>
                </Box>
                <hr
                  className={css({
                    width: "full",
                    bgColor: "secondary.lighten2",
                  })}
                />
                <Box>
                  <h5
                    className={css({
                      textStyle: "md",
                      color: "secondary.darken8",
                      fontWeight: "semibold",
                      fontFamily: "outfit",
                    })}
                  >
                    Deskripsi Produk
                  </h5>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: datum.data.data.description,
                    }}
                    className={css({
                      "& > *": {
                        textStyle: "md",
                        color: "secondary",
                      },
                    })}
                  />
                </Box>
              </Flex>
            </GridItem>
          </Grid>

          {!!isLogin && (
            <>
              <hr
                className={css({
                  width: "full",
                  bgColor: "secondary.lighten2",
                })}
              />
              <h4
                className={css({
                  textStyle: "h6",
                  color: "secondary.darken8",
                })}
              >
                Komentar
              </h4>
              <VStack maxW="744px" w="full" p="16px" display="flex" gap="16px" alignItems="start">
                <CommentList
                  comments={result.data}
                  mutation={mutation}
                  isLoading={result.isLoading}
                  flexDir="column-reverse"
                  setCommentId={setCommentId}
                  mutationDelete={mutationDelete}
                />
              </VStack>
            </>
          )}
        </VStack>
        <Lightbox
          open={open}
          close={onClose}
          slides={[
            {
              src:
                datum.data.data.image ??
                "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg",
              alt: "detail image article",
            },
          ]}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          portal={{
            root: document.body,
          }}
          plugins={[Zoom]}
        />
      </Container>
    </>
  );
};
