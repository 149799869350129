import React, { useRef } from "react";
import { css } from "styled-system/css";
import { Box, HStack, VStack, Flex } from "styled-system/jsx";
import { Avatar, Button, Card, Icon, Popover } from "~/components";
import { Forum } from "../../@types";
import {
  IconDots,
  IconHeart,
  IconHeartFilled,
  IconMessageCircle,
  IconShare3,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { getMe } from "~/features/profile";
import { Portal } from "@ark-ui/react";
import { useGetComment, useLikeForum, useMutationComment } from "../../hooks";
import { List as CommentList } from "~/features/comment/components/List";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/id";
import { Link } from "@tanstack/react-router";
import { openUrl } from "~/utils";

dayjs.locale("id");
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

interface CardForumProps extends Card.RootProps {
  datum: Forum;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CardDetailForum: React.FC<CardForumProps> = ({ datum, setOpen, ...rest }) => {
  const queryClient = useQueryClient();
  const profile = useQuery(getMe);
  const ref = useRef<HTMLTextAreaElement>(null);
  const { result } = useGetComment(datum.id, true);
  const { mutation, mutationDelete, setCommentId } = useMutationComment(datum.id);

  const mutationLike = useLikeForum(
    {
      id: datum.id,
      isLiked: datum.like,
    },
    {
      onMutate: () => {
        const queryKey = [`detail-forum-${datum.id}`];

        queryClient.setQueryData(queryKey, (oldData: UseQueryResult<Forum>) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              like: !oldData.data?.like,
              totalLike: oldData.data?.like ? oldData.data?.totalLike - 1 : (oldData.data?.totalLike as number) + 1,
            },
          };
        });
      },
    },
  );

  return (
    <Card.Root width="full" boxShadow="shadow" rounded="none" border="none" {...rest}>
      <Card.Header p="4">
        <Card.Title asChild>
          <Flex justifyContent="space-between" alignItems="center">
            <HStack>
              <Avatar src={datum.createdBy?.avatar as string} name={datum.createdBy?.name} />
              <VStack alignItems="start" gap="0">
                <h3
                  className={css({
                    textStyle: "md",
                    color: "secondary.darken8",
                    fontWeight: "semibold",
                    fontFamily: "outfit!",
                  })}
                >
                  {datum.createdBy?.name}
                </h3>
                <HStack>
                  <p
                    className={css({
                      textStyle: {
                        base: "xs",
                        sm: "sm",
                      },
                      color: "secondary",
                    })}
                  >
                    {dayjs(datum.createdAt).tz("Asia/Jakarta").fromNow()}
                  </p>

                  <p
                    className={css({
                      color: "primary",
                      textStyle: {
                        base: "xs",
                        sm: "sm",
                      },
                      fontWeight: "normal",
                    })}
                  >
                    {datum.category?.name}
                  </p>
                </HStack>
              </VStack>
            </HStack>
            <HStack>
              {profile.data?.data?.id === datum.createdBy?.id && (
                <Popover.Root>
                  <Popover.Trigger minH="fit-content" h="fit-content">
                    <Icon color="disabled" cursor="pointer">
                      <IconDots />
                    </Icon>
                  </Popover.Trigger>
                  <Portal>
                    <Popover.Positioner>
                      <Popover.Content>
                        <Popover.Title asChild>
                          <VStack alignItems="start" gap="4">
                            <Button
                              visual="ghost"
                              h="fit-content!"
                              minH="fit-content!"
                              onClick={() => {
                                setOpen(true);
                              }}
                              alignItems="center"
                              justifyContent="center"
                              px={0}
                            >
                              <Icon color="secondary">
                                <IconTrash />
                              </Icon>
                              <p
                                className={css({
                                  fontWeight: "medium",
                                  color: "secondary.darken8",
                                  textStyle: "sm",
                                })}
                              >
                                Hapus Postingan
                              </p>
                            </Button>
                          </VStack>
                        </Popover.Title>
                      </Popover.Content>
                    </Popover.Positioner>
                  </Portal>
                </Popover.Root>
              )}
              <Link
                to="/forum"
                search={{
                  category: "",
                }}
              >
                <Icon>
                  <IconX />
                </Icon>
              </Link>
            </HStack>
          </Flex>
        </Card.Title>
        <Card.Description py="4" asChild>
          <Box maxH="275px" overflowY="auto">
            <div
              dangerouslySetInnerHTML={{
                __html: datum.description,
              }}
              className={css({
                textStyle: "md",
                color: "secondary.darken8",
                "& > *": {
                  textStyle: "md",
                  color: "secondary.darken8",
                },
              })}
            />
          </Box>
        </Card.Description>
      </Card.Header>
      <Card.Body p="0">
        {profile.data?.data && (
          <Flex
            px={{
              base: "4",
              md: "8",
              lg: "12",
            }}
            py="4"
            justifyContent="space-between"
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor="secondary.lighten2"
          >
            <HStack cursor="pointer" onClick={() => mutationLike.mutate()}>
              {datum.like ? (
                <Icon color="primary">
                  <IconHeartFilled />
                </Icon>
              ) : (
                <Icon color="secondary">
                  <IconHeart />
                </Icon>
              )}
              <p
                className={css({
                  textStyle: "md",
                  color: "secondary",
                  fontWeight: "medium",
                })}
              >
                {`${datum.totalLike} Suka`}
              </p>
            </HStack>
            <HStack
              cursor="pointer"
              onClick={() => {
                if (ref.current) {
                  ref.current.focus();
                }
              }}
            >
              <Icon color="secondary">
                <IconMessageCircle />
              </Icon>
              <p
                className={css({
                  textStyle: "md",
                  color: "secondary",
                  fontWeight: "medium",
                })}
              >
                {result.data?.paging.count} Komentar
              </p>
            </HStack>
            <HStack
              cursor="pointer"
              onClick={() =>
                openUrl(
                  `whatsapp://send?text=Lihat artikel ecobiz ini: https://${window.location.hostname}/forum/d6f69714-710b-4133-91a9-2aca71473c45`,
                )
              }
            >
              <Icon color="secondary">
                <IconShare3 />
              </Icon>
              <p
                className={css({
                  textStyle: "md",
                  color: "secondary",
                  fontWeight: "medium",
                })}
              >
                Bagikan
              </p>
            </HStack>
          </Flex>
        )}

        <Box pt="4" px="4">
          {result.hasNextPage && (
            <Button
              onClick={() => result.fetchNextPage()}
              visual="ghost"
              colorPalette="secondary"
              loading={result.isFetchingNextPage}
              minH="fit-content"
              h="fit-content"
              position="sticky"
              px={6}
              mb={2}
              top={0}
            >
              <p
                className={css({
                  fontFamily: "dmSans",
                })}
              >
                Lihat Komentar lain
              </p>
            </Button>
          )}

          <CommentList
            style={{
              maxHeight: result.hasNextPage ? "485px" : "528px",
            }}
            overflowY="auto"
            comments={result.data?.list}
            data-login={!!profile.data?.data}
            mutation={mutation}
            isLoading={result.isLoading}
            setCommentId={setCommentId}
            flexDir="column-reverse"
            ref={ref}
            mutationDelete={mutationDelete}
            className={css({
              "&[data-login=false]": {
                mb: 4,
              },
            })}
          />
        </Box>
      </Card.Body>
    </Card.Root>
  );
};
