import React from "react";
import { Grid, GridItem } from "styled-system/jsx";
import { CardNews } from "~/features/article";
import { INews } from "~/features/article/@types";
import { Wrapper } from "../shared/Wrapper";
import { EmptyState } from "./EmptyState";

interface NewsProps {
  datum: INews[];
}

export const News: React.FC<NewsProps> = ({ datum }) => {
  return (
    <Wrapper
      title="Berita"
      subTitle="Kumpulan tips untuk menerapkan praktik bisnis yang berkelanjutan dan ramah lingkungan"
      to="/article/news"
    >
      {datum.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid
          gridTemplateColumns={{
            base: "1",
            sm: "2",
            lg: "3",
          }}
          mt={6}
        >
          {datum.map((v) => (
            <GridItem gridColumn="1" key={v.id}>
              <CardNews datum={v} hash="Kiat-kiat" />
            </GridItem>
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};
