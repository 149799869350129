import { Link } from "@tanstack/react-router";
import React from "react";
import { css } from "styled-system/css";
import { Box } from "styled-system/jsx";
import { Button } from "~/components";

interface WrapperProps extends React.PropsWithChildren {
  title: string;
  subTitle?: string;
  to: string;
}

export const Wrapper: React.FC<WrapperProps> = ({ to, subTitle, title, children }) => {
  return (
    <section>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <h1
            className={css({
              textStyle: "h2",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            {title}
          </h1>
          {subTitle && (
            <p
              className={css({
                textStyle: "lg",
                color: "secondary",
              })}
            >
              {subTitle}
            </p>
          )}
        </Box>
        <Link to={to}>
          <Button bgColor="accent.ligthen5">Lihat Semua</Button>
        </Link>
      </Box>
      {children}
    </section>
  );
};
