import { Pagination as ArkPagination, type PaginationRootProps } from "@ark-ui/react/pagination";
import { IconArrowNarrowLeft, IconArrowNarrowRight } from "@tabler/icons-react";
import { forwardRef } from "react";
import { css, cx } from "styled-system/css";
import { splitCssProps } from "styled-system/jsx";
import { pagination, type PaginationVariantProps } from "styled-system/recipes";
import type { Assign, JsxStyleProps } from "styled-system/types";
import { Button } from "~/components/ui/Button";
import { IconButton } from "~/components/ui/IconButton";

export interface PaginationProps extends Assign<JsxStyleProps, PaginationRootProps>, PaginationVariantProps {}

export const Pagination = forwardRef<HTMLElement, PaginationProps>((props, ref) => {
  const [variantProps, paginationProps] = pagination.splitVariantProps(props);
  const [cssProps, localProps] = splitCssProps(paginationProps);
  const { className, ...rootProps } = localProps;
  const styles = pagination(variantProps);

  return (
    <ArkPagination.Root
      // @ts-expect-error TODO cssProps is to complex to be typed
      className={cx(styles.root, css(cssProps), className)}
      ref={ref}
      page={paginationProps.page}
      {...rootProps}
    >
      {({ pages, isFirstPage, isLastPage }) => (
        <>
          <ArkPagination.PrevTrigger className={styles.prevTrigger} asChild>
            <IconButton
              data-state={isFirstPage ? "hidden" : "block"}
              className={css({
                "&[data-state=hidden]": {
                  display: "none",
                },
              })}
              variant="ghost"
              aria-label="Next Page"
            >
              <IconArrowNarrowLeft />
            </IconButton>
          </ArkPagination.PrevTrigger>
          {pages.map((page, index) =>
            page.type === "page" ? (
              <ArkPagination.Item className={styles.item} key={index} {...page} asChild>
                <Button
                  w="40px!"
                  minH="40px!"
                  px="20px"
                  bgColor="transparent"
                  color="disabled"
                  textStyle="md"
                  fontWeight="medium"
                  _selected={{
                    bgColor: "green.lighten1",
                    color: "primary",
                  }}
                >
                  {page.value}
                </Button>
              </ArkPagination.Item>
            ) : (
              <ArkPagination.Ellipsis className={styles.ellipsis} key={index} index={index}>
                &#8230;
              </ArkPagination.Ellipsis>
            ),
          )}
          <ArkPagination.NextTrigger className={styles.nextTrigger} asChild>
            <IconButton
              className={css({
                "&[data-state=hidden]": {
                  display: "none",
                },
              })}
              data-state={isLastPage ? "hidden" : "block"}
              variant="ghost"
              aria-label="Next Page"
            >
              <IconArrowNarrowRight />
            </IconButton>
          </ArkPagination.NextTrigger>
        </>
      )}
    </ArkPagination.Root>
  );
});

Pagination.displayName = "Pagination";
