import React from "react";
import { MenuItem } from "~/@types";

export interface RenderTreeViewProps {
  menu: MenuItem[];
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MENU_FOOTER = {
  sites: [
    {
      label: "Beranda",
      to: "#",
    },
    {
      label: "Forum",
      to: "/forum",
    },
    {
      label: "Event",
      to: "/event",
    },
    {
      label: "Produk",
      to: "/product",
    },
    {
      label: "Berita",
      to: "/article/news",
    },
  ],
  mitra: [
    {
      label: "Dekopinwil Jabar",
      to: "#",
    },
    {
      label: "Jaringan Usaha Koperasi",
      to: "#",
    },
    {
      label: "Institut Koperasi Indonesia",
      to: "#",
    },
    {
      label: "Sekolah Bisnis dan Manajemen ITB",
      to: "#",
    },
    {
      label: "Kementerian Perdagangan",
      to: "#",
    },
  ],
};

export const VIDEO = [
  {
    extension: ".flv",
    mimetype: "video/x-flv",
  },
  {
    extension: ".mp4",
    mimetype: "video/mp4",
  },
  {
    extension: ".m3u8",
    mimetype: "application/x-mpegURL",
  },
  {
    extension: ".ts",
    mimetype: "video/MP2T",
  },
  {
    extension: ".3gp",
    mimetype: "video/3gpp",
  },
  {
    extension: ".mov",
    mimetype: "video/quicktime",
  },
  {
    extension: ".avi",
    mimetype: "video/x-msvideo",
  },
];

export const IMAGE = [
  {
    extension: "bmp",
    mimetype: "image/bmp",
  },
  {
    extension: "webp",
    mimetype: "image/webp",
  },
  {
    extension: "cod",
    mimetype: "image/cis-cod",
  },
  {
    extension: "gif",
    mimetype: "image/gif",
  },
  {
    extension: "ief",
    mimetype: "image/ief",
  },
  {
    extension: "jpe",
    mimetype: "image/jpeg",
  },
  {
    extension: "jpeg",
    mimetype: "image/jpeg",
  },
  {
    extension: "png",
    mimetype: "image/png",
  },
  {
    extension: "jpg",
    mimetype: "image/jpeg",
  },
  {
    extension: "jfif",
    mimetype: "image/pipeg",
  },
  {
    extension: "svg",
    mimetype: "image/svg+xml",
  },
  {
    extension: "tif",
    mimetype: "image/tiff",
  },
  {
    extension: "tiff",
    mimetype: "image/tiff",
  },
  {
    extension: "ras",
    mimetype: "image/x-cmu-raster",
  },
  {
    extension: "cmx",
    mimetype: "image/x-cmx",
  },
  {
    extension: "ico",
    mimetype: "image/x-icon",
  },
  {
    extension: "pnm",
    mimetype: "image/x-portable-anymap",
  },
  {
    extension: "pbm",
    mimetype: "image/x-portable-bitmap",
  },
  {
    extension: "pgm",
    mimetype: "image/x-portable-graymap",
  },
  {
    extension: "ppm",
    mimetype: "image/x-portable-pixmap",
  },
  {
    extension: "rgb",
    mimetype: "image/x-rgb",
  },
  {
    extension: "xbm",
    mimetype: "image/x-xbitmap",
  },
  {
    extension: "xpm",
    mimetype: "image/x-xpixmap",
  },
  {
    extension: "xwd",
    mimetype: "image/x-xwindowdump",
  },
  {
    extension: "jpg",
    mimetype: "application/octet-stream",
  },
];

export const IMAGE_MIME_TYPES = [
  "image/gif",
  "image/bmp",
  "image/vnd.microsoft.icon",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
] as const;

export type ImageMimeTypes = (typeof IMAGE_MIME_TYPES)[number];

export const VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/x-msvideo",
  "video/mpeg",
  "video/ogg",
  "video/mp2t",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
] as const;

export type VideoMimeTypes = (typeof VIDEO_MIME_TYPES)[number];

export const AUDIO_MIME_TYPES = [
  "audio/aac",
  "audio/midi",
  "audio/mpeg",
  "audio/ogg",
  "audio/opus",
  "audio/wav",
  "audio/webm",
] as const;

export type AuidoMimeTypes = (typeof AUDIO_MIME_TYPES)[number];

export const ALLOWED_FILES = {
  image: [IMAGE_MIME_TYPES],
  video: [VIDEO_MIME_TYPES],
  audio: [AUDIO_MIME_TYPES],
};
