import { IconSearch } from "@tabler/icons-react";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { css } from "styled-system/css";
import { Box, Center, Container, Flex } from "styled-system/jsx";
import { useDebouncedCallback } from "use-debounce";
import { EmptyState, Icon, Input, Pagination } from "~/components";
import { CardModule } from "..";

export const List = () => {
  const getRoute = getRouteApi("/_layout/module/");
  const result = getRoute.useLoaderData();
  const params = getRoute.useSearch();

  const [search, setSearch] = useState(params.keyword);
  const debounced = useDebouncedCallback((value) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          keyword: value,
        };
      },
      replace: true,
    });
    setSearch(value);
  }, 350);

  const navigate = useNavigate({
    from: "/module",
  });

  const range = useMemo(
    () => ({
      startRange: (+params.page - 1) * result.data.paging.limit + 1,
      endRange: Math.min(+params.page * result.data.paging.limit, result.data.paging.count),
    }),
    [result.data.paging],
  );

  return (
    <Container maxW="7xl" my={20}>
      <Flex flexDir="column" gap="40px">
        <Flex
          justifyContent="space-between"
          flexDir={{
            base: "column",
            md: "row",
          }}
        >
          <h1
            className={css({
              textStyle: "h2",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            Modul Belajar{" "}
            <span
              className={css({
                color: "primary",
              })}
            >
              Ecobiz
            </span>
          </h1>
          <Box position="relative" role="group" tabIndex={1}>
            <Icon
              pos="absolute"
              height="54px"
              display="flex"
              flexDir="column"
              alignItems="center"
              aria-disabled={!params.keyword}
              left={4}
              className={css({
                color: "primary",
                "&[aria-disabled=true]": {
                  color: "disabled.text",
                },
              })}
              _groupFocus={{
                color: "primary!",
              }}
            >
              <IconSearch />
            </Icon>
            <Input
              borderRadius="12"
              size="md"
              pl="40px"
              minW="340px"
              placeholder="Cari modul belajar"
              defaultValue={search}
              onChange={(e) => debounced(e.target.value)}
            />
          </Box>
        </Flex>
        <Flex flexDir="column" gap="16px">
          {result.data.list.length > 0 && (
            <h2
              className={css({
                textStyle: "md",
                color: "secondary",
              })}
            >
              Menampilkan {range.startRange} - {range.endRange} dari {result.data.paging.count}
            </h2>
          )}
          {result.data.list.length === 0 ? (
            <EmptyState
              title="Modul tidak ditemukan"
              description="Maaf, modul yang kamu cari tidak dapat ditemukan, mohon menggunakan keyword yang lain."
            />
          ) : (
            <Box>
              {result.data.list.map((v) => (
                <CardModule key={v._id} datum={v} />
              ))}
              <Center mt="40px">
                <Pagination
                  count={result.data.paging.count}
                  pageSize={result.data.paging.limit}
                  siblingCount={0}
                  page={result.data.paging.page}
                  onPageChange={(v) => {
                    navigate({
                      search: (prev) => {
                        return {
                          ...prev,
                          page: v.page,
                        };
                      },
                    });
                  }}
                />
              </Center>
            </Box>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
