import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { ErrorElement } from "~/components/ErrorElement";
import { Detail } from "~/features/my-product";
import { getOneProduct } from "~/features/product";

const mutationMyProductSchema = z.object({
  productId: z.string().optional(),
});

type MutationMyProductSchema = z.infer<typeof mutationMyProductSchema>;

export const Route = createFileRoute("/_layout/my-product/mutation")({
  validateSearch: (search: Record<string, unknown>): MutationMyProductSchema => mutationMyProductSchema.parse(search),
  beforeLoad: ({ search, context: { queryClient } }) => {
    if (search.productId) {
      return queryClient.ensureQueryData(getOneProduct(search.productId as string));
    }
  },
  gcTime: 0,
  shouldReload: false,
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  component: () => <Detail />,
});
