import React, { useRef } from "react";
import { Box, Container } from "styled-system/jsx";
import { css } from "styled-system/css";
import { Icon, Input } from "~/components";
import { IconSearch, IconXboxX } from "@tabler/icons-react";
import { useDebouncedCallback } from "use-debounce";
import { getRouteApi, useNavigate, useRouter } from "@tanstack/react-router";

const getRoute = getRouteApi("/explore/");

export const NavbarExplore = () => {
  const params = getRoute.useSearch();
  const router = useRouter();

  const ref = useRef<HTMLInputElement>(null);

  const keyword = params.keyword;

  const navigate = useNavigate({
    from: "/explore",
  });

  const debounced = useDebouncedCallback((value) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          keyword: value,
        };
      },
      replace: true,
    });
  }, 500);

  return (
    <Box
      borderBottomWidth={1}
      backgroundColor="white"
      zIndex={999}
      borderColor="secondary.lighten2"
      pos="sticky"
      top={0}
      bgColor="white"
    >
      <Container maxW="7xl" py={6}>
        <Box
          pos="relative"
          mx={{
            base: "unset",
            md: "auto",
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Input
            ref={ref}
            defaultValue={keyword}
            onChange={(e) => debounced(e.target.value)}
            borderRadius="12"
            size="md"
            borderColor="transparent"
            pl="48px"
            placeholder="Ketik apapun disini untuk melakukan pencarian"
          />
          <Icon
            pos="absolute"
            height="54px"
            display="flex"
            flexDir="column"
            left={4}
            top={0}
            alignItems="start"
            className={css({
              color: "secondary.darken8",
              "&[aria-disabled=true]": {
                color: "disabled.text",
              },
            })}
            _groupFocus={{
              color: "primary!",
            }}
          >
            <IconSearch />
          </Icon>
          <Icon
            className={css({
              color: "secondary.lighten4",
              cursor: "pointer",
            })}
            size="lg"
            tabIndex={0}
            onClick={() => {
              router.history.back();
            }}
          >
            <IconXboxX />
          </Icon>
        </Box>
      </Container>
    </Box>
  );
};
