import { createFileRoute } from "@tanstack/react-router";
import { ForgetPassword } from "~/features/auth/components/templates";

import { Helmet } from "react-helmet-async";

export const Route = createFileRoute("/forget-password")({
  component: () => (
    <>
      <Helmet>
        <title>Lupa Password - Ecobiz</title>
      </Helmet>
      <ForgetPassword />
    </>
  ),
});
