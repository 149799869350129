export const useNotification = () => {
  const isNotificationSupported = () => {
    return "Notification" in window;
  };

  const notificationRequestPermission = async () => {
    if (!("serviceWorker" in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      console.warn("Service Worker is not supported or not installed.");
      return;
    }
    if (!("PushManager" in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      console.warn("Push messaging is not supported.");
      return;
    }

    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      return true;
    } else {
      console.warn("Unable to get permission to notify.");
      return false;
    }
  };

  return {
    isNotificationSupported,
    notificationRequestPermission,
  };
};
