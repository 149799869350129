import { List as TemplateList } from "~/features/article";
import { getRouteApi, useNavigate } from "@tanstack/react-router";

export const List = () => {
  const getRoute = getRouteApi("/_layout/article/news/");
  const result = getRoute.useLoaderData();
  const params = getRoute.useSearch();

  const navigate = useNavigate({
    from: "/article/news",
  });

  return (
    <TemplateList
      hash="Berita"
      title="Berita dari"
      description="Dapatkan berita terbaru seputar Koperasi dan Usaha Kecil Menengah serta informasi mengenai dunia bisnis"
      items={result.data.list}
      onPageChange={(v) => {
        navigate({
          search: (prev) => {
            return {
              ...prev,
              page: v.page,
            };
          },
        });
      }}
      paging={{
        count: result.data.paging.count,
        limit: result.data.paging.limit,
      }}
      page={params.page}
    />
  );
};
