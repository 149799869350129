import React from "react";
import {
  Controller,
  FieldErrors,
  RefCallBack,
  type Path,
  type PathValue,
  type FieldValues,
  type UseControllerProps,
} from "react-hook-form";
import { Box } from "styled-system/jsx";

import { css } from "styled-system/css";
import { FormLabel, type FormLabelProps } from "~/components/ui";

type WrapperProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  labelProps?: FormLabelProps;
  required?: boolean;
  children: (
    name: Path<T>,
    onChange: (event: any) => void,
    value: PathValue<T, Path<T>>,
    disabled: boolean | undefined,
    ref: RefCallBack,
  ) => React.ReactNode;
} & UseControllerProps<T>;

export const Wrapper = <T extends FieldValues>({
  error,
  control,
  label,
  name,
  labelProps,
  children,
  required,
  ...rest
}: WrapperProps<T>) => {
  return (
    <Controller
      {...rest}
      control={control}
      name={name}
      render={({ field: { name, onChange, value, disabled, ref } }) => (
        <section
          className={css({
            display: "flex",
            flexDir: "column",
            gap: "8px",
            minW: 0,
            width: "full",
          })}
        >
          {label && (
            <FormLabel htmlFor={name} {...labelProps}>
              {label}
              {required && (
                <span
                  className={css({
                    color: "red",
                  })}
                >
                  *
                </span>
              )}
            </FormLabel>
          )}
          <Box position="relative" h="full" display="flex" alignItems="center">
            {children(name, onChange, value, disabled, ref)}
          </Box>
          {error[name] && (
            <p
              className={css({
                textStyle: "sm",
                color: "error",
              })}
            >
              {error[name]?.message as React.ReactNode}
            </p>
          )}
        </section>
      )}
    />
  );
};
