import { defaultServices } from "~/services";
import { Event as Meeting } from "~/features/event";
import { BaseQuery } from "~/@types";

export const fetchListMeeting = async (q: BaseQuery) => {
  const res = await defaultServices.getList<Meeting>({
    resource: "/meeting-room",
    query: {
      ...q,
    },
  });

  return res;
};

export const fetchDetailMeeting = async (meetingId: string) => {
  const res = await defaultServices.getOne<Meeting>({
    resource: "/meeting-room",
    id: meetingId,
  });

  return res;
};
