import { useMemo } from "react";

import { IconSearch } from "@tabler/icons-react";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { css } from "styled-system/css";
import { Box, Center, Container, Flex, Grid, GridItem, HStack } from "styled-system/jsx";
import { useDebouncedCallback } from "use-debounce";
import { Badge, EmptyState, Icon, Input, Pagination } from "~/components";
import { CardEvent } from "~/features/event";

const getRoute = getRouteApi("/_layout/event/");

export const List = () => {
  const result = getRoute.useLoaderData();

  const params = getRoute.useSearch();

  const search = params.keyword;
  const status = params.status;

  interface Filters {
    label: string;
    value: typeof params.status;
  }

  const filters = useMemo<Filters[]>(() => {
    return [
      {
        label: "Semua Event",
        value: "",
      },
      {
        label: "Akan Datang",
        value: "pending",
      },
      {
        label: "Selesai",
        value: "done",
      },
    ];
  }, []);

  const debouncedSearch = useDebouncedCallback(
    // function
    (value) => {
      navigate({
        search: (prev) => {
          return {
            ...prev,
            page: 1,
            keyword: value,
          };
        },
        replace: true,
      });
    },
    500,
  );
  const debouncedFilter = (value: typeof params.status) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          status: value,
        };
      },
      replace: true,
    });
  };

  const navigate = useNavigate({
    from: "/event",
  });

  return (
    <Container maxW="7xl" mt={10} mb={20}>
      <Flex flexDir="column" gap="10">
        <h1
          className={css({
            textStyle: "h6",
            color: "disabled",
            fontWeight: "bold",
          })}
        >
          Event
        </h1>
        <Flex
          justifyContent="space-between"
          gap={4}
          flexDir={{
            base: "column",
            lg: "row",
          }}
        >
          <Box position="relative" role="group" tabIndex={1}>
            <Icon
              pos="absolute"
              height="54px"
              display="flex"
              flexDir="column"
              alignItems="center"
              aria-disabled={!params.keyword}
              left={4}
              className={css({
                color: "primary",
                "&[aria-disabled=true]": {
                  color: "disabled.text",
                },
              })}
              _groupFocus={{
                color: "primary!",
              }}
            >
              <IconSearch />
            </Icon>
            <Input
              borderRadius="12"
              size="md"
              pl="40px"
              minW={{
                base: "0",
                md: "412px",
              }}
              width="full!"
              placeholder="Cari Event"
              defaultValue={search}
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </Box>
          <HStack gap="4" flexWrap="wrap">
            {filters.map((filter) => (
              <Badge
                height="40px"
                px="24px"
                py="8px"
                cursor="pointer"
                colorPalette="secondary"
                bgColor="colorPalette.lighten1"
                minW="fit-content"
                borderColor="colorPalette.lighten2"
                data-state={status === filter.value ? "checked" : "unchecked"}
                onClick={() => debouncedFilter(filter.value)}
                borderRadius="8px"
                textStyle="md"
                color="colorPalette"
                key={filter.value}
                className={css({
                  "&[data-state=checked]": {
                    colorPalette: "primary",
                    borderColor: "colorPalette",
                    backgroundColor: "green.lighten1",
                  },
                })}
              >
                {filter.label}
              </Badge>
            ))}
          </HStack>
        </Flex>
        {result.data.list.length === 0 ? (
          <Center>
            <EmptyState
              title="Belum ada event yang tersedia"
              description="Semua event tersedia akan muncul di halaman ini."
            />
          </Center>
        ) : (
          <Flex gap="40px" flexDir="column">
            <Grid
              gridTemplateColumns={{
                base: "1",
                sm: "2",
                lg: "3",
              }}
              gap="24px"
            >
              {result.data.list.map((v) => (
                <GridItem gridColumn="1" key={v.id}>
                  <CardEvent
                    datum={v}
                    image="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709088379203_UI (1).svg"
                    to="/event/$eventId"
                    params={{
                      eventId: v.id,
                    }}
                  />
                </GridItem>
              ))}
            </Grid>
            <Center>
              <Pagination
                count={result.data.paging.count}
                pageSize={result.data.paging.limit}
                siblingCount={0}
                page={result.data.paging.page}
                onPageChange={(v) => {
                  navigate({
                    search: (prev) => {
                      return {
                        ...prev,
                        page: v.page,
                      };
                    },
                  });
                }}
              />
            </Center>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
