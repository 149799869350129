import { Helmet } from "react-helmet-async";

import { createFileRoute } from "@tanstack/react-router";
import { List } from "~/features/kiat-kiat";
import { articleGetList } from "~/features/article";

import { z } from "zod";

const kiatKiatSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(9),
  keyword: z.string().catch(""),
});

type KiatKiatSearch = z.infer<typeof kiatKiatSearchSchema>;

export const Route = createFileRoute("/_layout/article/kiat-kiat/")({
  validateSearch: (search: Record<string, unknown>): KiatKiatSearch => kiatKiatSearchSchema.parse(search),
  loaderDeps: ({ search: { keyword, limit, page } }) => ({ keyword, limit, page }),
  loader: ({ context: { queryClient }, deps: { page } }) =>
    queryClient.ensureQueryData(
      articleGetList({
        type: "tips",
        page,
        limit: 9,
      }),
    ),
  gcTime: 0,
  shouldReload: false,
  component: () => (
    <>
      <Helmet>
        <title>Kiat-kiat - Ecobiz</title>
      </Helmet>
      <List />
    </>
  ),
});
