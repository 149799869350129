import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { defaultServices } from "~/services";
import { useDebouncedCallback } from "use-debounce";
import { Category } from "~/@types";

export const useCategory = (resource: string, defaultKeyword?: string, enabled = false, limit = 10, query = {}) => {
  const [keyword, setKeyword] = useState(defaultKeyword ?? "");

  const debouncedSearch = useDebouncedCallback((value) => {
    setKeyword(value);
  }, 500);

  const category = useInfiniteQuery({
    queryKey: [`category_${resource}_${keyword}_${query ? JSON.stringify(query) : null}`],
    queryFn: async ({ pageParam }) => {
      const q = {
        ...query,
        page: pageParam,
        limit,
        keyword: keyword,
      };

      const res = await defaultServices.getList<Category>({
        resource: resource,
        query: q,
      });

      return res;
    },
    select: (data) => {
      return {
        meta: data.pages[0].data.paging,
        list: data.pages.flatMap((v) =>
          v.data.list.map((v) => ({
            value: v._id,
            label: v.name,
          })),
        ),
      };
    },
    enabled: enabled,
    initialPageParam: 1,
    staleTime: 500_000,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });

  useEffect(() => {
    if (defaultKeyword) {
      setKeyword(defaultKeyword);
    }
  }, [defaultKeyword]);

  return { category, keyword, setKeyword, debouncedSearch };
};
