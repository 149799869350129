/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from "@tanstack/react-router";
import { Footer, Navbar, SEO } from "~/components";
import { ErrorElement } from "~/components/ErrorElement";
import { Detail } from "~/features/forum";
import { getDetialForum } from "~/features/forum/query-options";

export const Route = createFileRoute("/forum/$forumId")({
  loader: async ({ context: { queryClient }, params: { forumId } }) =>
    queryClient.ensureQueryData(getDetialForum(forumId)),
  component: () => {
    const getLoader = Route.useLoaderData();
    return (
      <>
        <SEO
          title="Detail Forum"
          description={getLoader.data.description}
          image={getLoader.data?.image}
          url={`${window.location.hostname}/forum/${getLoader.data._id}`}
          keywords={getLoader.data.category.name}
        />
        <Navbar />
        <Detail />
      </>
    );
  },
  notFoundComponent: () => (
    <>
      <Navbar />
      <ErrorElement statusCode="404" />
      <Footer />
    </>
  ),
  errorComponent: () => (
    <>
      <Navbar />
      <ErrorElement statusCode="500" />
      <Footer />
    </>
  ),
});
