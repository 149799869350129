/* eslint-disable react-refresh/only-export-components */
import { CreateToasterReturn, createToaster } from "@ark-ui/react/toast";
import { IconX } from "@tabler/icons-react";
import { createContext, useContext } from "react";
import { HStack } from "styled-system/jsx";
import { IconButton, Toast } from "~/components/ui";

type TToastContext = {
  Toaster: CreateToasterReturn[0];
  toast: CreateToasterReturn[1];
};

export const ToastContext = createContext<TToastContext | null>(null);

interface ToastProvider {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProvider> = ({ children }) => {
  const [Toaster, toast] = createToaster({
    placement: "top-end",
    render(toast) {
      return (
        <Toast.Root>
          <HStack justifyContent="space-between">
            <Toast.Title data-type={toast.type}>{toast.title}</Toast.Title>
            <Toast.CloseTrigger asChild>
              <IconButton data-type={toast.type} size="lg" variant="link">
                <IconX />
              </IconButton>
            </Toast.CloseTrigger>
          </HStack>
          {toast.description && <Toast.Description data-type={toast.type}>{toast.description}</Toast.Description>}
        </Toast.Root>
      );
    },
  });

  return (
    <ToastContext.Provider
      value={{
        toast,
        Toaster,
      }}
    >
      {children}
      <Toaster />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const toast = useContext(ToastContext);

  if (!toast) {
    throw new Error("Toast Context should be in top of component");
  }

  return toast;
};
