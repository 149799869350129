import { Portal } from "@ark-ui/react";
import { A } from "@mobily/ts-belt";
import { IconChevronDown, IconCircleXFilled, IconPhotoPlus, IconVideoPlus, IconX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";
import { css } from "styled-system/css";
import { Box, Flex, HStack, VStack } from "styled-system/jsx";
import { ControlledCombobox, ControlledFroala, Image, InfiniteScroller, Input, Popover, Tabs } from "~/components";
import { Avatar, Button, Card, Dialog, Icon, Menu, Progress } from "~/components/ui";
import { Skeleton } from "~/components/ui/Skeleton";
import { ALLOWED_FILES, VIDEO_MIME_TYPES, VideoMimeTypes } from "~/constant";
import { getMe } from "~/features/profile";
import { ComboboxUser } from "~/features/users/component";
import { useCategory } from "~/hooks";
import { getId } from "~/utils";
import { useCreateForum } from "../../hooks";

export const Create = () => {
  const getRoute = getRouteApi("/_layout/forum/");
  const search = getRoute.useSearch();
  const profile = useQuery(getMe);
  const {
    control,
    errors,
    handleFileChange,
    mutationMedia,
    progress,
    resetImage,
    inputFileRef,
    onSubmit,
    toggleFileUpload,
    isOpen,
    setIsOpen,
    type,
    mutation,
    embedYoutube,
    setErorr,
    error,
    handleEmbedLink,
    valueEmbedYoutube,
    errorFile,
    setEmbedYoutube,
    setType,
    setValue,
    setValueEmbetYoutube,
  } = useCreateForum();

  const selectedCategory = search.category;
  const selectedUser = search.createdBy;
  const selectedUserCategory = search.userCategory;

  const categoryUser = useCategory("/user-category");
  const { category, debouncedSearch } = useCategory("/forum-category");

  const transformCategory = useMemo(() => {
    if (category.data?.list) {
      return [
        {
          label: "Semua",
          value: "",
        },
      ].concat(category.data?.list);
    }
  }, [category.data]);

  const transformCategoryUser = useMemo(() => {
    if (categoryUser.category.data?.list) {
      return [
        {
          label: "Semua",
          value: "",
        },
      ].concat(categoryUser.category.data?.list);
    }
  }, [categoryUser.category.data]);

  const navigate = useNavigate({
    from: "/forum",
  });

  const handleCategory = (v: string) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          userCategory: "",
          page: 1,
          category: v,
        };
      },
      replace: true,
    });
  };

  const handleUserCategory = (v: string) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          userCategory: v,
        };
      },
      replace: true,
    });
  };

  const handleUser = (v: string) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          createdBy: v,
        };
      },
      replace: true,
    });
  };

  const allowedFile = ALLOWED_FILES[type].join(",");

  return (
    <>
      {/* Trigger dialog */}
      <Card.Root boxShadow="none" rounded="16px" width="full">
        <Card.Body p="4">
          <HStack>
            <Avatar src={profile.data?.data.avatar} name={profile.data?.data.name} />
            <Box
              cursor="pointer"
              onClick={() => setIsOpen(!isOpen)}
              px="4"
              py="2"
              rounded="16px"
              bgColor="secondary.lighten1"
              w="full"
            >
              <p
                className={css({
                  textStyle: "lg",
                  color: "disabled",
                })}
              >
                Buat postingan publik...
              </p>
            </Box>
          </HStack>
          <Card.Footer flexDir="column" p="0" gap={4} mt={6}>
            <Flex justifyContent="space-between">
              <HStack>
                <p
                  className={css({
                    textStyle: "md",
                    color: "disabled",
                  })}
                >
                  Postingan terbaru
                </p>
                <Menu.Root>
                  <Menu.Trigger asChild>
                    <HStack cursor="pointer">
                      <p
                        className={css({
                          textStyle: "sm",
                          color: "secondary.darken8",
                        })}
                      >
                        {category.data?.list?.find((v) => v.value === selectedCategory)?.label ?? "Semua"}
                      </p>
                      <Icon color="disabled">
                        <IconChevronDown />
                      </Icon>
                    </HStack>
                  </Menu.Trigger>
                  <Portal>
                    <Menu.Positioner>
                      <Menu.Content maxH="275px" rounded="16px" overflowY="scroll" h="full">
                        <InfiniteScroller
                          fetchNextPage={category.fetchNextPage}
                          hasNextPage={category.hasNextPage}
                          isLoading={category.isFetching}
                          loadingMessage={
                            <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                              {A.makeWithIndex(5, (i) => (
                                <Skeleton key={i}>
                                  <p>loading...</p>
                                </Skeleton>
                              ))}
                            </Flex>
                          }
                        >
                          {transformCategory?.map((v) => (
                            <Menu.Item
                              id={v.value}
                              textStyle="sm"
                              color="secondary.darken8"
                              data-selected={v.value === selectedCategory}
                              onClick={() => handleCategory(v.value)}
                              className={css({
                                "&[data-selected=true]": {
                                  color: "primary",
                                },
                              })}
                            >
                              {v.label}
                            </Menu.Item>
                          ))}
                        </InfiniteScroller>
                      </Menu.Content>
                    </Menu.Positioner>
                  </Portal>
                </Menu.Root>
              </HStack>
              <HStack>
                <p
                  className={css({
                    textStyle: "md",
                    color: "disabled",
                  })}
                >
                  Kategori User
                </p>
                <Menu.Root>
                  <Menu.Trigger asChild>
                    <HStack cursor="pointer">
                      <p
                        className={css({
                          textStyle: "sm",
                          color: "secondary.darken8",
                        })}
                      >
                        {categoryUser.category.data?.list?.find((v) => v.value === selectedUserCategory)?.label ??
                          "Semua"}
                      </p>
                      <Icon color="disabled">
                        <IconChevronDown />
                      </Icon>
                    </HStack>
                  </Menu.Trigger>
                  <Portal>
                    <Menu.Positioner>
                      <Menu.Content maxH="275px" rounded="16px" overflowY="scroll" h="full">
                        <InfiniteScroller
                          fetchNextPage={categoryUser.category.fetchNextPage}
                          hasNextPage={categoryUser.category.hasNextPage}
                          isLoading={categoryUser.category.isFetching}
                          loadingMessage={
                            <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                              {A.makeWithIndex(5, (i) => (
                                <Skeleton key={i}>
                                  <p>loading...</p>
                                </Skeleton>
                              ))}
                            </Flex>
                          }
                        >
                          {transformCategoryUser?.map((v) => (
                            <Menu.Item
                              id={v.value}
                              textStyle="sm"
                              color="secondary.darken8"
                              data-selected={v.value === selectedUserCategory}
                              onClick={() => handleUserCategory(v.value)}
                              className={css({
                                "&[data-selected=true]": {
                                  color: "primary",
                                },
                              })}
                            >
                              {v.label}
                            </Menu.Item>
                          ))}
                        </InfiniteScroller>
                      </Menu.Content>
                    </Menu.Positioner>
                  </Portal>
                </Menu.Root>
              </HStack>
            </Flex>

            <ComboboxUser
              value={[selectedUser]}
              onValueChange={(v) => {
                handleUser(v.value[0]);
              }}
            />
          </Card.Footer>
        </Card.Body>
      </Card.Root>
      <Dialog.Root open={isOpen} onOpenChange={(e) => setIsOpen(e.open)} trapFocus>
        <Dialog.Backdrop backdropFilter="blur(0px)" backgroundColor="black.a10" />
        <Portal>
          <Dialog.Positioner>
            <Dialog.Content maxW="552px" w="full" rounded="16px">
              <form onSubmit={onSubmit}>
                <Box
                  borderBottomWidth="1px"
                  borderBottomColor="secondary.lighten2"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  roundedTop="16px"
                  zIndex="3"
                  py="24px"
                  px="16px"
                  position="sticky"
                  top={0}
                  bgColor="white"
                >
                  <Dialog.Title textStyle="lg" color="secondary.darken8" fontWeight="semibold">
                    Buat Postingan
                  </Dialog.Title>
                  <Dialog.CloseTrigger asChild>
                    <Icon size="lg" cursor="pointer">
                      <IconX />
                    </Icon>
                  </Dialog.CloseTrigger>
                </Box>
                <Dialog.Description asChild maxH="550px" overflowY="auto">
                  <VStack alignItems="start" gap="8" px="4" my="4" w="full">
                    <ControlledFroala control={control} name="description" error={errors} />
                    <Box width="full" flexDir="column" display="flex" gap="4">
                      <Box display="flex" gap="4" alignItems="center">
                        {mutationMedia.isPending ? (
                          <Progress value={progress} type="circular" min={0} max={100} />
                        ) : (
                          <>
                            {embedYoutube ? (
                              <Box position="relative" width="full">
                                <iframe
                                  width="100%"
                                  height="345"
                                  allowFullScreen
                                  src={`https://www.youtube.com/embed/${getId(embedYoutube)}`}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  className={css({
                                    rounded: "16px",
                                    border: "0px",
                                  })}
                                ></iframe>

                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="20px"
                                  height="20px"
                                  pos="absolute"
                                  top="0px"
                                  right="-5px"
                                  cursor="pointer"
                                  color="error"
                                  bgColor="white"
                                  borderColor="error"
                                  borderWidth="1px"
                                  rounded="50%"
                                  onClick={() => setEmbedYoutube("")}
                                >
                                  <Icon color="error" width="14px" height="14px">
                                    <IconX />
                                  </Icon>
                                </Box>
                              </Box>
                            ) : mutationMedia.variables ? (
                              <Box pos="relative">
                                {VIDEO_MIME_TYPES.includes(
                                  mutationMedia.data?.data.mimetype as unknown as VideoMimeTypes,
                                ) ? (
                                  <video
                                    width="320"
                                    height="240"
                                    controls
                                    className={css({
                                      width: "full",
                                      rounded: "12px",
                                    })}
                                  >
                                    <source src={mutationMedia.data?.data?.url} type="video/ogg" />
                                    <source src={mutationMedia.data?.data?.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <Image
                                    src={mutationMedia.data?.data.url}
                                    imageStyle={{
                                      width: "160px",
                                      height: "160px",
                                      rounded: "8px",
                                      objectFit: "cover",
                                      overflow: "hidden",
                                    }}
                                  />
                                )}
                                <Icon
                                  position="absolute"
                                  color="red"
                                  bgColor="white"
                                  rounded="full"
                                  cursor="pointer"
                                  top={0}
                                  right={0}
                                  zIndex={2}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    resetImage();
                                  }}
                                >
                                  <IconCircleXFilled />
                                </Icon>
                              </Box>
                            ) : (
                              <>
                                <p
                                  className={css({
                                    textStyle: "sm",
                                    fontWeight: "medium",
                                    color: "secondary.darken8",
                                  })}
                                >
                                  Tambah
                                </p>
                                <HStack gap="1" onClick={toggleFileUpload("image")} cursor="pointer">
                                  <Icon color="primary" size="lg">
                                    <IconPhotoPlus />
                                  </Icon>
                                  <p
                                    className={css({
                                      textStyle: "md",
                                      color: "primary",
                                      fontWeight: "normal",
                                    })}
                                  >
                                    Foto
                                  </p>
                                </HStack>

                                <Popover.Root
                                  positioning={{
                                    placement: "bottom-start",
                                  }}
                                >
                                  <Popover.Trigger asChild>
                                    <HStack cursor="pointer" gap="1">
                                      <Icon color="primary" size="lg">
                                        <IconVideoPlus />
                                      </Icon>
                                      <p
                                        className={css({
                                          textStyle: "md",
                                          color: "primary",
                                          fontWeight: "normal",
                                        })}
                                      >
                                        Video
                                      </p>
                                    </HStack>
                                  </Popover.Trigger>
                                  <Popover.Positioner>
                                    <Popover.Content minW="302px">
                                      <Popover.Arrow>
                                        <Popover.ArrowTip />
                                      </Popover.Arrow>
                                      <Popover.Title asChild>
                                        <Tabs.Root>
                                          <Tabs.List boxShadow="unset">
                                            <Tabs.Trigger value="file" asChild>
                                              <Box
                                                px="16px"
                                                height="fit-content"
                                                py="4px"
                                                bgColor="secondary.lighten1"
                                                borderWidth="1px"
                                                borderColor="secondary.lighten2"
                                                rounded="8px"
                                                className={css({
                                                  "&[aria-selected=true]": {
                                                    borderColor: "primary",
                                                    bgColor: "green.lighten1",
                                                    color: "primary",
                                                    "& > p": {
                                                      color: "primary!",
                                                      fontWeight: "medium",
                                                    },
                                                  },
                                                })}
                                              >
                                                <p
                                                  className={css({
                                                    textStyle: "sm",
                                                    color: "secondary",
                                                    fontWeight: "normal",
                                                  })}
                                                >
                                                  Upload File
                                                </p>
                                              </Box>
                                            </Tabs.Trigger>
                                            <Tabs.Trigger value="link" asChild>
                                              <Box
                                                px="16px"
                                                height="fit-content"
                                                py="4px"
                                                bgColor="secondary.lighten1"
                                                borderWidth="1px"
                                                borderColor="secondary.lighten2"
                                                rounded="8px"
                                                className={css({
                                                  "&[aria-selected=true]": {
                                                    borderColor: "primary",
                                                    bgColor: "green.lighten1",
                                                    fontWeight: "medium",
                                                    "& > p": {
                                                      color: "primary!",
                                                      fontWeight: "medium",
                                                    },
                                                  },
                                                })}
                                              >
                                                <p
                                                  className={css({
                                                    textStyle: "sm",
                                                    color: "secondary",
                                                    fontWeight: "normal",
                                                  })}
                                                >
                                                  Youtube Link
                                                </p>
                                              </Box>
                                            </Tabs.Trigger>
                                          </Tabs.List>
                                          <Tabs.Content value="file">
                                            <Box>
                                              <Button width="full" textStyle="md" onClick={toggleFileUpload("video")}>
                                                Pilih File
                                              </Button>
                                            </Box>
                                          </Tabs.Content>
                                          <Tabs.Content value="link">
                                            <VStack width="100%" alignItems="start">
                                              <Input
                                                value={valueEmbedYoutube}
                                                onChange={handleEmbedLink}
                                                width="full"
                                                placeholder="Paste link disini"
                                              />
                                              {error && (
                                                <p
                                                  className={css({
                                                    textStyle: "sm",
                                                    color: "error",
                                                  })}
                                                >
                                                  Link youtube tidak valid
                                                </p>
                                              )}
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (error || !valueEmbedYoutube) {
                                                    setErorr(true);
                                                    return;
                                                  }
                                                  setValue("video", valueEmbedYoutube);
                                                  setType("video");
                                                  setEmbedYoutube(valueEmbedYoutube);
                                                  setValueEmbetYoutube("");
                                                }}
                                                width="full"
                                                type="button"
                                                textStyle="md"
                                              >
                                                Embed Link
                                              </Button>
                                            </VStack>
                                          </Tabs.Content>
                                        </Tabs.Root>
                                      </Popover.Title>
                                    </Popover.Content>
                                  </Popover.Positioner>
                                </Popover.Root>
                              </>
                            )}
                          </>
                        )}

                        <input
                          ref={inputFileRef}
                          onChange={handleFileChange}
                          type="file"
                          accept={allowedFile}
                          className={css({
                            srOnly: true,
                          })}
                        />
                      </Box>
                      {errorFile && (
                        <p
                          className={css({
                            fontSize: "xs",
                            color: "red.darken5",
                          })}
                        >
                          {errorFile}
                        </p>
                      )}
                    </Box>

                    <ControlledCombobox
                      hasNextPage={category.hasNextPage}
                      isLoading={category.status === "pending" || category.isFetchingNextPage}
                      loadingMessage={
                        <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                          {A.makeWithIndex(5, (i) => (
                            <Skeleton key={i}>
                              <p>loading...</p>
                            </Skeleton>
                          ))}
                        </Flex>
                      }
                      fetchNextPage={category.fetchNextPage}
                      control={control}
                      rootProps={{
                        onInputValueChange: (v) => {
                          debouncedSearch(v.value);
                        },
                      }}
                      items={category.data?.list}
                      name="category"
                      error={errors}
                      required
                      label="Kategori"
                      inputProps={{
                        placeholder: "Pilih disini",
                      }}
                    />
                  </VStack>
                </Dialog.Description>
                <Box p="4" position="sticky" bottom={0}>
                  <Button type="submit" width="full" loading={mutation.isPending}>
                    Posting
                  </Button>
                </Box>
              </form>
            </Dialog.Content>
          </Dialog.Positioner>
        </Portal>
      </Dialog.Root>
    </>
  );
};
