import dayjs from "dayjs";
import { css } from "styled-system/css";
import { Box, Center, CenterProps } from "styled-system/jsx";

interface LayoutProps extends CenterProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children, ...rest }) => {
  return (
    <>
      <Center {...rest} flexDir="column" mx="4">
        <Box
          maxW="552px"
          width="100%"
          rounded="24px"
          borderColor="secondary.lighten2"
          borderWidth="1px"
          borderStyle="solid"
          p="24px"
          display="flex"
          flexDir="column"
          gap="40px"
        >
          {children}
        </Box>
        <p
          className={css({
            textStyle: "sm",
            fontWeight: "medium",
            color: "disabled",
          })}
        >
          ECOBIZ KUKM Jawa Barat | &copy;{dayjs().format("YYYY")} Ecobizitb All Rights Reserved
        </p>
      </Center>
    </>
  );
};
