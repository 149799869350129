import { IconCategory, IconChevronLeft, IconSearch } from "@tabler/icons-react";
import React, { useMemo, useState } from "react";
import { css } from "styled-system/css";
import { Box, Container, GridItem, HStack, VStack } from "styled-system/jsx";

import { Badge } from "~/components/ui/Badge";
import { Button } from "~/components/ui/Button";
import * as Card from "~/components/ui/Card";
import { Icon } from "~/components/ui/Icon";
import { Input } from "~/components/ui/Input";
import { useCategory, useDisClosure, useScroll } from "~/hooks";
import { useDebouncedCallback } from "use-debounce";

import { CardProduct } from "../data-display";
import { getRouteApi } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { EmptyState, IconButton } from "~/components";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { getListProductInfiniteQuery } from "../../query-options";
import { IconChevronRight } from "@tabler/icons-react";
import { Category } from "../dialog";

const getRoute = getRouteApi("/_layout/product/");

export const List = () => {
  const { open, onToggle } = useDisClosure();
  const {
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    handleNext,
    handlePrev,
    handleScroll,
    ref,
  } = useScroll();

  const params = getRoute.useSearch();

  const products = useSuspenseInfiniteQuery(
    getListProductInfiniteQuery({
      ...params,
    }),
  );

  const { category } = useCategory("/product-category", "", true, 6);

  const isNotInViewCategory = useMemo(() => {
    if (params.category) {
      const find = category.data?.list.slice(0, 6).find((v) => v.value === params.category);

      if (!find) {
        return true;
      }

      return false;
    }

    return false;
  }, [params.category, category.data?.list]);

  const transformCategory = useMemo(() => {
    let baseRefine = [
      {
        label: "Semua Kategori",
        value: "",
      },
      ...(category.data?.list.slice(0, 6) || []),
    ];

    if ((category.data?.meta?.count as number) > 6) {
      baseRefine = [
        ...baseRefine,
        {
          label: "Lainnya",
          value: "others",
        },
      ];
    }

    return baseRefine;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.data?.list]);

  const [search, setSearch] = useState(params.keyword);

  const navigate = useNavigate({
    from: "/product",
  });

  const debounced = useDebouncedCallback((value) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          keyword: value,
        };
      },
      replace: true,
    });
    setSearch(value);
  }, 500);

  const handleCategory = (v: string) => {
    if (v === "others") {
      onToggle();
      category.fetchNextPage();
    } else {
      navigate({
        search: (prev) => {
          return {
            ...prev,
            page: 1,
            category: v,
          };
        },
        replace: true,
      });
    }
  };

  return (
    <Container maxW="7xl" mt="40px" mb="80px">
      <VStack gap="40px" alignItems="unset">
        <h1
          className={css({
            textStyle: "h2",
            fontWeight: "bold",
            color: "secondary.darken8",
            textAlign: "center",
          })}
        >
          Display
          <span
            className={css({
              color: "primary",
            })}
          >
            &nbsp; Product
          </span>
        </h1>
        <Box
          pos="relative"
          mx={{
            base: "unset",
            md: "auto",
          }}
        >
          <Input
            defaultValue={search}
            onChange={(e) => debounced(e.target.value)}
            borderRadius="12"
            size="md"
            w={{
              base: "full",
              md: "744px!",
            }}
            pl="40px"
            placeholder="Cari produk di Ecobiz"
          />
          <Icon
            pos="absolute"
            height="54px"
            display="flex"
            flexDir="column"
            left={4}
            top={0}
            alignItems="start"
            aria-disabled={!search}
            className={css({
              color: "primary",
              "&[aria-disabled=true]": {
                color: "disabled.text",
              },
            })}
            _groupFocus={{
              color: "primary!",
            }}
          >
            <IconSearch />
          </Icon>
        </Box>

        <Card.Root>
          <Card.Header flexDir="row" gap="2">
            <Icon size="2xl" color="primary">
              <IconCategory />
            </Icon>
            <h3
              className={css({
                textStyle: "h6",
              })}
            >
              Kategori Pilihan
            </h3>
          </Card.Header>
          <Card.Body asChild>
            <Box position="relative">
              <IconButton
                position="absolute"
                display={{
                  base: "none",
                  md: "flex",
                }}
                left="0"
                top="20%"
                zIndex={1}
                transform="translate(20%, -40%)"
                onClick={handlePrev}
                bgColor="rgba(255, 255, 255, 0.8)"
                boxShadow="shadow_1"
                borderRadius="50%"
              >
                <IconChevronLeft
                  className={css({
                    fontSize: "24px",
                    color: "secondary.darken8",
                  })}
                />
              </IconButton>
              <Box overflow="hidden">
                <Box
                  display="flex"
                  ref={ref}
                  onMouseMove={handleMouseMove}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                  onScroll={handleScroll}
                  gap="24px"
                  cursor="grab"
                  transform="auto"
                  overflowX="scroll"
                  flexWrap="nowrap"
                  className={css({
                    "&::-webkit-scrollbar": {
                      width: 0,
                    },
                    listStyle: "none",
                  })}
                >
                  {transformCategory?.map((v) => (
                    <Badge
                      height="40px"
                      px="24px"
                      py="8px"
                      cursor="pointer"
                      colorPalette="secondary"
                      bgColor="colorPalette.lighten1"
                      minW="fit-content"
                      borderColor="colorPalette.lighten2"
                      borderRadius="8px"
                      textStyle="md"
                      color="colorPalette"
                      onClick={() => handleCategory(v.value)}
                      data-state={
                        isNotInViewCategory && v.value === "others"
                          ? "checked"
                          : params.category === v.value
                            ? "checked"
                            : "unchecked"
                      }
                      key={v.value}
                      className={css({
                        "&[data-state=checked]": {
                          colorPalette: "primary",
                          borderColor: "colorPalette",
                          backgroundColor: "green.lighten1",
                        },
                      })}
                    >
                      {v.label}
                    </Badge>
                  ))}
                </Box>
              </Box>
              <IconButton
                position="absolute"
                top="20%"
                right={0}
                display={{
                  base: "none",
                  md: "flex",
                }}
                zIndex={1}
                transform="translate(-20%, -40%)"
                onClick={handleNext}
                bgColor="rgba(255, 255, 255, 0.8)"
                boxShadow="shadow_1"
                borderRadius="50%"
              >
                <IconChevronRight
                  className={css({
                    fontSize: "24px",
                    color: "secondary.darken8",
                  })}
                />
              </IconButton>
            </Box>
          </Card.Body>
        </Card.Root>

        <Box
          display="grid"
          gap="16px"
          gridTemplateColumns={{
            base: "1",
            sm: "3",
            md: "4",
            lg: "6",
          }}
        >
          {products.data.pages.map((page, idx) => (
            <React.Fragment key={idx}>
              {page.data.list.length === 0 ? (
                <GridItem gridColumn="6">
                  <EmptyState
                    title="Produk tidak ditemukan"
                    description="Maaf, produk yang kamu cari tidak dapat ditemukan, mohon menggunakan keyword yang lain."
                  />
                </GridItem>
              ) : (
                page.data.list.map((v) => <CardProduct datum={v} key={v.id} borderRadius="8px" gridTemplate="1" />)
              )}
            </React.Fragment>
          ))}
        </Box>
        {products.hasNextPage && (
          <HStack justifyContent="center">
            <Button variant="primary" onClick={() => products.fetchNextPage()} loading={products.isFetchingNextPage}>
              Tampilkan Lebih Banyak
            </Button>
          </HStack>
        )}
      </VStack>
      <Category
        open={open}
        onToggle={onToggle}
        datum={category.data?.list.slice(6, category.data.list.length)}
        onHandleChange={handleCategory}
        value={params.category}
        hasNextPage={category.hasNextPage}
        fetchNextPage={category.fetchNextPage}
      />
    </Container>
  );
};
