import { css } from "styled-system/css";
import { Box, Flex, HStack } from "styled-system/jsx";

import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import { Icon, Image } from "~/components";
import * as Card from "~/components/ui/Card";
import { normalizeIDR } from "~/utils/helper";
import type { Product } from "../../@types";

interface Props extends Card.RootProps {
  datum: Product;
  withAction?: boolean;
  onHandleEdit?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onHandleDelete?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const CardProduct: React.FC<Props> = ({ datum, withAction, onHandleEdit, onHandleDelete, ...rest }) => {
  return (
    <Link
      to={`/product/$productId`}
      params={{
        productId: datum.id,
      }}
    >
      <Card.Root
        shadow="shadow_2"
        width="full"
        minW="172.67px"
        borderWidth="1px"
        borderColor="secondary.lighten2"
        height="full"
        borderStyle="solid"
        {...rest}
      >
        <Card.Header position="relative" p="0">
          <Image
            src={datum.image ?? "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg"}
            alt={datum.name}
            loading="lazy"
            pictureClassName={css({
              height: "175px",
            })}
            imageStyle={{
              width: "full",
              height: " 175px",
            }}
            style={{
              objectFit: "cover",
            }}
          />
          <Box
            position="absolute"
            bottom={0}
            left={0}
            bgColor="primary"
            color="white"
            borderTopRightRadius="8px"
            px="4"
            py="2"
            textStyle="xs"
            fontFamily="outfit"
            fontWeight="semibold"
          >
            {datum.category?.name}
          </Box>
        </Card.Header>
        <Card.Body
          p="2"
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="start"
        >
          <Card.Title textStyle="sm" color="secondary.darken8" lineClamp="2">
            {datum.name}
          </Card.Title>
          <div>
            <Card.Description asChild mt={2}>
              <Flex flexDir="column" gap="2">
                <h3
                  className={css({
                    textStyle: "md",
                    fontWeight: "bold",
                    fontFamily: "outfit",
                    color: "secondary.darken8",
                  })}
                >
                  {normalizeIDR(datum.price)}
                </h3>
                <p>{datum.origin}</p>
              </Flex>
            </Card.Description>
          </div>
        </Card.Body>
        {withAction && (
          <Card.Footer asChild pt={0} pb={2} px={2}>
            <HStack>
              <Icon cursor="pointer" tabIndex={1} color="secondary" size="md" onClick={onHandleEdit}>
                <IconEdit />
              </Icon>
              <Icon cursor="pointer" tabIndex={1} color="secondary" size="md" onClick={onHandleDelete}>
                <IconTrash />
              </Icon>
            </HStack>
          </Card.Footer>
        )}
      </Card.Root>
    </Link>
  );
};
