import { IconCircleXFilled } from "@tabler/icons-react";
import React, { ForwardedRef, forwardRef } from "react";
import { FieldErrors, FieldValues, UseControllerProps } from "react-hook-form";
import { css } from "styled-system/css";
import { Flex } from "styled-system/jsx";
import { FormLabel, FormLabelProps, Icon, Image, Progress, StyledInputProps } from "~/components/ui";
import { Wrapper } from "./Wrapper";

type FileProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  inputProps?: StyledInputProps;
  labelProps?: FormLabelProps;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  progress: number;
  isLoading: boolean;
  preview?: string;
  reset: () => void;
  ref: ForwardedRef<HTMLInputElement>;
  allowedFile?: string;
} & UseControllerProps<T>;

interface WithForwardRefType<T extends FieldValues = FieldValues> extends React.FC<FileProps<T>> {
  <T extends FieldValues>(props: FileProps<T>): ReturnType<React.FC<FileProps<T>>>;
}

//@ts-expect-error
export const ControlledFile: WithForwardRefType<FieldValues> = forwardRef<HTMLInputElement, FileProps<FieldValues>>(
  (
    { handleChange, progress, isLoading, preview, reset, control, name, error, allowedFile },
    refForm: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Wrapper control={control} name={name} error={error}>
        {(name, __, ___, disabled) => (
          <FormLabel
            w="160px"
            htmlFor={name}
            h="160px"
            pos="relative"
            cursor="pointer"
            rounded="8.51px"
            bgColor="secondary.lighten1"
            borderStyle="dashed"
            borderColor="secondary.lighten2"
            borderWidth="1.06px"
          >
            <Flex flexDir="column" justifyContent="center" alignItems="center" h="inherit" gap="4px">
              {isLoading ? (
                <Progress value={progress} type="circular" min={0} max={100} />
              ) : preview ? (
                <>
                  <Image
                    src={preview}
                    imageStyle={{
                      width: "full",
                      objectFit: "cover",
                      overflow: "hidden",
                    }}
                    alt="preview file"
                  />
                  <Icon
                    position="absolute"
                    color="red"
                    bgColor="white"
                    rounded="full"
                    top={0}
                    right={0}
                    zIndex={2}
                    onClick={(e) => {
                      e.stopPropagation();
                      reset();
                    }}
                  >
                    <IconCircleXFilled />
                  </Icon>
                </>
              ) : (
                <>
                  <Image
                    src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709173000884_UI User Website (2).svg"
                    alt="upload file"
                  />
                  <p
                    className={css({
                      color: "disabled",
                      textStyle: "sm",
                      maxW: "10ch",
                      textAlign: "center",
                    })}
                  >
                    <span
                      className={css({
                        fontWeight: "semibold",
                      })}
                    >
                      Klik{" "}
                    </span>
                    untuk upload foto
                  </p>
                </>
              )}
            </Flex>
            <input
              ref={refForm}
              id={name}
              disabled={disabled}
              onChange={handleChange}
              accept={allowedFile}
              type="file"
              className={css({
                srOnly: true,
              })}
            />
          </FormLabel>
        )}
      </Wrapper>
    );
  },
);
