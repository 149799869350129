import { Box, Container, Flex, HStack, VStack } from "styled-system/jsx";
import { Create } from "../dialog";
import { CardForum } from "../data-display";
import { InfiniteData, useQueryClient, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { getListForumInfiniteQuery } from "../../query-options";
import { getRouteApi } from "@tanstack/react-router";
import { Card, EmptyState, InfiniteScroller } from "~/components";
import { useDeleteForum } from "../../hooks";
import { Confirmation } from "../dialog/Confirmation";
import { useToast } from "~/context/Toast";
import { GetManyResponse } from "~/services";
import { Forum } from "../../@types";
import { useEffect, useState } from "react";
import { Skeleton } from "~/components/ui/Skeleton";

const getRoute = getRouteApi("/_layout/forum/");

export const List = () => {
  const search = getRoute.useSearch();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const isLogin = queryClient.getQueryData(["isLogin"]);

  const [datum, setDatum] = useState<Forum>({} as Forum);

  const { mutationDelete, open, setOpen } = useDeleteForum({
    onSuccess: () => {
      setOpen(false);
      const queryKey = [`forum_${search.category}_${search.createdBy}_${params.userCategory}`];

      toast.success({
        title: "Postingan Dihapus",
      });

      queryClient.setQueryData(queryKey, (oldData: InfiniteData<GetManyResponse<Forum>>) => {
        return {
          ...oldData,
          pages: oldData.pages.map((v) => ({
            ...v,
            data: {
              ...v.data,
              list: v.data.list.filter((v) => v.id !== datum.id),
            },
          })),
        };
      });
    },
  });

  const params = getRoute.useSearch();

  const forums = useSuspenseInfiniteQuery(
    getListForumInfiniteQuery({
      ...params,
      category: params.category,
      createdBy: params.createdBy,
      userCategory: params.userCategory,
    }),
  );

  return (
    <Box bgColor="secondary.lighten1">
      <Container maxW="4xl" pt={10} pb={16}>
        {isLogin ? <Create /> : null}
        <InfiniteScroller
          hasNextPage={forums.hasNextPage}
          fetchNextPage={forums.fetchNextPage}
          isLoading={forums.isLoading}
          loadingMessage={
            <Card.Root mt="4" boxShadow="none" rounded="16px">
              <Card.Title asChild p="4">
                <Flex gap="4" alignItems="center">
                  <Skeleton height="48px" width="48px" rounded="full" />
                  <VStack alignItems="start" width="full" gap="2">
                    <Skeleton maxW="214px" rounded="100px" width="full" height="21px" />
                    <HStack width="full">
                      <Skeleton rounded="100px" width="77px" height="21px" />
                      <Skeleton rounded="100px" width="77px" height="21px" />
                    </HStack>
                  </VStack>
                </Flex>
              </Card.Title>
              <Card.Body asChild p="4">
                <VStack alignItems="start" width="full">
                  <Skeleton rounded="100px" width="full" height="15px" />
                  <Skeleton rounded="100px" width="50%" height="15px" />
                </VStack>
              </Card.Body>
              <Card.Footer asChild p="0" borderTopWidth={1} borderColor="secondary.lighten2">
                <Flex justifyContent="space-around" alignItems="center" p="4">
                  <HStack>
                    <Skeleton height="24px" width="24px" rounded="full" />
                    <Skeleton height="24px" width="82px" rounded="full" />
                  </HStack>
                  <HStack>
                    <Skeleton height="24px" width="24px" rounded="full" />
                    <Skeleton height="24px" width="82px" rounded="full" />
                  </HStack>
                  <HStack>
                    <Skeleton height="24px" width="24px" rounded="full" />
                    <Skeleton height="24px" width="82px" rounded="full" />
                  </HStack>
                </Flex>
              </Card.Footer>
            </Card.Root>
          }
        >
          <Flex flexDir="column" mt={6} alignItems="center" gap={6} width="full">
            {forums.data.length === 0 ? (
              <EmptyState
                title="Forum tidak ditemukan"
                description="Maaf, forum yang kamu cari tidak dapat ditemukan, mohon menggunakan keyword yang lain."
              />
            ) : (
              forums.data.map((v) => (
                <CardForum key={v.id} datum={v} setDatum={setDatum} setOpen={setOpen} search={search} />
              ))
            )}
          </Flex>
        </InfiniteScroller>
      </Container>

      <Confirmation
        key={String(`${open}-confirmation`)}
        open={open}
        onOpenChange={(v) => setOpen(v.open)}
        isLoading={mutationDelete.isPending}
        onHandleDelete={() => {
          mutationDelete.mutate(datum.id);
        }}
      />
    </Box>
  );
};
