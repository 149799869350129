import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import React from "react";
import { Grid, GridItem } from "styled-system/jsx";
import { CardForum } from "~/features/forum";
import { Forum as IForum } from "~/features/forum/@types";
import { Result } from "../../@types";
import { Wrapper } from "../shared/Wrapper";
import { EmptyState } from "./EmptyState";

interface ForumProps {
  datum: IForum[];
}

const getRoute = getRouteApi("/explore/");

export const Forum: React.FC<ForumProps> = ({ datum }) => {
  return (
    <Wrapper title="Forum" subTitle="Kegiatan untuk berbagi informasi" to="/forum">
      {datum.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid
          gridTemplateColumns={{
            lg: "repeat(3, minmax(0, 1fr))",
          }}
          mt={6}
        >
          {datum.map((v) => (
            <ForumItem item={v} key={v.id} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};

const ForumItem = ({ item }: { item: IForum }) => {
  const params = getRoute.useSearch();
  const queryClient = useQueryClient();

  return (
    <GridItem>
      <CardForum
        datum={item}
        showComment={false}
        search={{
          category: "",
        }}
        maxCategoryWidth="15ch"
        onMutate={async () => {
          const queryKey = [
            `explore`,
            {
              keyword: params.keyword,
            },
          ];
          queryClient.setQueryData(queryKey, (oldData: UseQueryResult<Result>) => {
            return {
              ...oldData,
              data: {
                ...oldData.data,
                forum: oldData.data?.forum.map((v) => {
                  if (v.id === item.id) {
                    return {
                      ...v,
                      totalLike: item.like ? item.totalLike - 1 : item.totalLike + 1,
                      like: !item.like,
                    };
                  }

                  return v;
                }),
              },
            };
          });
        }}
      />
    </GridItem>
  );
};
