// image.tsx

import { SystemStyleObject } from "@pandacss/dev";
import "lazysizes";
import "lazysizes/plugins/blur-up/ls.blur-up";

import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { css, cx } from "styled-system/css";

interface ImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  imageStyle?: SystemStyleObject;
  pictureClassName?: string;
  imageClassName?: string;
}

export const Image: React.FC<ImageProps> = ({ pictureClassName, imageStyle, imageClassName, ...props }) => {
  return (
    <picture className={pictureClassName}>
      <img
        alt={props.alt}
        {...props}
        className={cx(css(imageStyle), `lazyload blur-up`, imageClassName)}
        sizes="(min-width: 1000px) 930px, 90vw"
        data-src={props.src}
        fetchpriority="high"
        loading="lazy"
        onError={(v) => {
          v.currentTarget.src = "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg";
        }}
      />
    </picture>
  );
};
