/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { createRootRouteWithContext, Outlet, ScrollRestoration, useRouterState } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import nookies from "nookies";
import { getMenu } from "~/features/menu/query-options";
import { getMe } from "~/features/profile";
import { client } from "~/store";
import { useNotification, useFirebase } from "~/hooks";
import { useEffect } from "react";

const isTokenAlreadyDefined = nookies.get().token !== undefined;

export const Route = createRootRouteWithContext<{
  queryClient: typeof client;
}>()({
  beforeLoad: async ({ context: { queryClient } }) => {
    if (isTokenAlreadyDefined) {
      await queryClient.prefetchQuery({
        queryKey: ["isLogin"],
        queryFn: () => Promise.resolve(true),
        staleTime: Infinity,
        gcTime: Infinity,
      });
    }
  },

  loader: async ({ context }) => {
    const { queryClient } = context || {};
    if (queryClient) {
      queryClient.prefetchQuery(getMenu);

      if (isTokenAlreadyDefined) {
        return queryClient.ensureQueryData(getMe);
      }
    }
  },

  component: () => {
    return <RootComponents />;
  },

  wrapInSuspense: true,
});

const WHITELIST_ROUTE = ["/forum"];

const RootComponents = () => {
  const { firebaseInitialize, firebaseGetToken } = useFirebase();
  const { isNotificationSupported, notificationRequestPermission } = useNotification();
  const router = useRouterState();

  useEffect(() => {
    if (router.status === "idle") {
      if (!WHITELIST_ROUTE.includes(router.location.pathname)) {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }
    }
  }, [router.status]);

  useEffect(() => {
    firebaseInitialize();

    if (isNotificationSupported()) {
      notificationRequestPermission().then((result) => {
        if (result) {
          firebaseGetToken();
        }
      });
    }
  }, []);

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          return WHITELIST_ROUTE.includes(location.pathname) ? location.pathname : "";
        }}
      />
      <Outlet />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  );
};
