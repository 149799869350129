import { queryOptions } from "@tanstack/react-query";
import { auth } from "~/services";
import { client } from "~/store";

export const getMe = queryOptions({
  queryKey: ["profile"],
  queryFn: auth.getMe,
  staleTime: Infinity,
  enabled: !!client.getQueryData(["isLogin"]),
});
