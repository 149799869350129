import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon, IconButton } from "./ui";
import { IconChevronUp } from "@tabler/icons-react";
import { Box, BoxProps } from "styled-system/jsx";

interface BackToTopProps extends BoxProps {}

export const BackToTop: React.FC<BackToTopProps> = ({ ...rest }) => {
  const [y, setY] = useState(0);
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setY(scrollPosition);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return y ? (
    <Box pos="fixed" bottom="32px" zIndex="2" {...rest}>
      <Button onClick={handleBackToTop} rounded="100px" textStyle="md" color="white" fontWeight="semibold">
        Back to Top
        <Icon color="white" size="lg">
          <IconChevronUp />
        </Icon>
      </Button>
    </Box>
  ) : null;
};
