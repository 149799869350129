import { Input as StyledInput, type StyledInputProps, type FormLabelProps } from "~/components/ui";
import React, { MutableRefObject, forwardRef } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { FieldValues, FieldErrors, UseControllerProps } from "react-hook-form";
import { Wrapper } from "./Wrapper";

const defaultMaskOptions = {
  prefix: "Rp ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: Infinity,
  allowNegative: false,
  allowLeadingZeroes: false,
};

type InputProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  inputProps?: StyledInputProps;
  labelProps?: FormLabelProps;
  maskedProps?: Omit<MaskedInputProps, "mask">;
} & UseControllerProps<T>;

interface WithForwardRefType extends React.FC<InputProps<FieldValues>> {
  <T extends FieldValues>(props: InputProps<T>): ReturnType<React.FC<InputProps<T>>>;
}

const CurrencyInput: WithForwardRefType = forwardRef(
  ({ error, control, label, name, labelProps, inputProps, maskedProps, ...rest }, _ref) => {
    const mask = createNumberMask(defaultMaskOptions);

    return (
      <Wrapper control={control} error={error} labelProps={labelProps} label={label} name={name} {...rest}>
        {(__, onChange, value, disabled, ref) => (
          <MaskedInput
            mask={mask}
            tabIndex={0}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...maskedProps}
            render={(maskRef, props) => (
              <StyledInput
                {...props}
                {...inputProps}
                ref={(node) => {
                  if (node) {
                    maskRef(node);
                    if (ref) {
                      (ref as unknown as MutableRefObject<HTMLInputElement>).current = node;
                    }
                  }
                }}
              />
            )}
          />
        )}
      </Wrapper>
    );
  },
);

export default CurrencyInput;

CurrencyInput.displayName = "Currency Input";
