import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { getRouteApi, useNavigate, useRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import { css } from "styled-system/css";
import { Center, Grid, GridItem } from "styled-system/jsx";
import { useToast } from "~/context/Toast";
import { useDeleteForum } from "../../hooks";
import { getDetialForum } from "../../query-options";
import { CardDetailForum } from "../data-display";
import { Confirmation } from "../dialog/Confirmation";
import { getId, isYoutubeUrl } from "~/utils";
import { Image } from "~/components";

export const Detail = () => {
  const { toast } = useToast();
  const router = useRouter();
  const queryClient = useQueryClient();
  const getRoute = getRouteApi("/forum/$forumId");
  const datum = useSuspenseQuery(getDetialForum(getRoute.useParams().forumId));
  const navigate = useNavigate({
    from: "/forum/$forumId",
  });
  const { mutationDelete, open, setOpen } = useDeleteForum({
    onSuccess: () => {
      navigate({
        to: "/forum",
        search: {
          category: "",
        },
      });
      queryClient.invalidateQueries({
        queryKey: [`forum_`],
      });
      toast.success({
        title: "Postingan Dihapus",
      });
    },
  });

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        router.history.back();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <Grid
      gridTemplateColumns={{
        base: "1",
        md: "1fr 35%",
      }}
      gap="0"
    >
      <GridItem gridColumn="1" bgColor="black">
        <Center>
          {!datum.data.data.image && !datum.data.data.video && (
            <Image
              src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709787397697_UI User Website (5).svg"
              alt="image forum"
              imageStyle={{
                maxW: "100%",
                height: {
                  base: "calc(100% - 95px)",
                  lg: "calc(100vh - 95px)",
                },
                objectFit: "contain",
              }}
            />
          )}
          {!!datum.data.data.image && (
            <Image
              src={datum.data.data.image}
              alt="image forum"
              imageStyle={{
                maxW: "100%",
                height: {
                  base: "calc(100% - 95px)",
                  lg: "calc(100vh - 95px)",
                },
                objectFit: "contain",
              }}
            />
          )}
          {datum.data.data.video ? (
            isYoutubeUrl(datum.data.data.video) ? (
              <iframe
                width="100%"
                allowFullScreen
                height="345"
                src={`https://www.youtube.com/embed/${getId(datum.data.data.video)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className={css({
                  width: "full",
                  height: {
                    base: "calc(100% - 95px)",
                    lg: "calc(100vh - 95px)",
                  },
                  objectFit: "contain",
                })}
              ></iframe>
            ) : (
              <video
                controls
                className={css({
                  width: "full",
                  height: {
                    base: "calc(100% - 95px)",
                    lg: "calc(100vh - 95px)",
                  },
                  objectFit: "contain",
                })}
              >
                <source src={datum.data.data.video!} type="video/ogg" />
                <source src={datum.data.data.video!} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          ) : null}
        </Center>
      </GridItem>
      <GridItem gridColumn="auto">
        <CardDetailForum datum={datum.data.data} setOpen={setOpen} />
        <Confirmation
          open={open}
          onOpenChange={(v) => setOpen(v.open)}
          isLoading={mutationDelete.isPending}
          onHandleDelete={() => {
            mutationDelete.mutate(datum.data.data.id);
          }}
        />
      </GridItem>
    </Grid>
  );
};
