import { css } from "styled-system/css";
import { Flex, VStack } from "styled-system/jsx";
import { Avatar } from "../../Avatar";
import { Icon } from "../../Icon";
import * as Menu from "../../Menu";
import { IconBell } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useMemo, forwardRef } from "react";
import { getListNotificationInfiniteQuery, getCountNotification } from "~/features/notification/query-options";
import { updateReadNotification } from "~/features/notification/service";
import {
  useSuspenseInfiniteQuery,
  useQuery,
  useQueryClient,
  InfiniteData,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { Button } from "~/components/ui";
import { NotificationCount, BaseNotification } from "~/features/notification/@types";
import { A } from "@mobily/ts-belt";
import { Skeleton } from "~/components/ui/Skeleton";
import { Image, InfiniteScroller } from "~/components";
import { GetManyResponse } from "~/services";
import { HttpError } from "~/@types";
import { useNavigate } from "@tanstack/react-router";

type RedirectNotification = (notification: BaseNotification) => void;

export const Notification = () => {
  const queryClient = useQueryClient();

  const notification = useSuspenseInfiniteQuery(
    getListNotificationInfiniteQuery({
      page: 1,
      limit: 10,
    }),
  );

  const notificationCount = useQuery(getCountNotification({ read: false }));

  const navigate = useNavigate({
    from: "/",
  });

  const mutationReadNotification = useMutation<unknown, HttpError, string>({
    mutationFn: (id) => updateReadNotification(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(["notification"], (oldData: InfiniteData<GetManyResponse<BaseNotification>>) => {
        return {
          ...oldData,
          pages: oldData.pages.map((v) => ({
            ...v,
            data: {
              ...v.data,
              list: v.data.list.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    read: true,
                  };
                }

                return item;
              }),
            },
          })),
        };
      });

      queryClient.setQueryData(
        ["notification-count", { read: false }],
        (oldData: UseQueryResult<NotificationCount>) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              count: (oldData?.data?.count as number) - 1,
            },
          };
        },
      );
    },
  });

  const handleRedirectNotification = async (notificationItem: BaseNotification) => {
    mutationReadNotification.mutate(notificationItem.id);
    switch (notificationItem.type) {
      case "comment_on_forum": {
        const commentOnForumNotification = notificationItem as BaseNotification<"comment_on_forum">;
        navigate({
          to: `/forum/$forumId`,
          params: {
            forumId: commentOnForumNotification.data.forumId,
          },
        });
        break;
      }
      case "comment_on_product": {
        const commentOnProductNotification = notificationItem as BaseNotification<"comment_on_product">;
        navigate({
          to: `/product/$productId`,
          params: {
            productId: commentOnProductNotification.data.productId,
          },
        });
        break;
      }
      case "invited_to_meeting_room": {
        const invitedToMeetingRoomNotification = notificationItem as BaseNotification<"invited_to_meeting_room">;
        navigate({
          to: `/meeting/$meetingId`,
          params: {
            meetingId: invitedToMeetingRoomNotification.data.meetingRoomId,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <Menu.Root
      positioning={{
        placement: "top",
        gutter: 20,
      }}
    >
      <Menu.Trigger asChild>
        <NotificationTrigger count={notificationCount.data?.data.count ?? 0} />
      </Menu.Trigger>
      <Menu.Positioner>
        <Menu.Content
          borderRadius="12px"
          maxW="375px"
          width="calc(100% - 35px)"
          boxShadow="shadow_1"
          pb="16px"
          p="0"
          gap="0px"
          borderColor="secondary.lighten2"
          borderWidth={1}
        >
          <Menu.ItemGroup id="notification">
            <Menu.ItemGroupLabel
              htmlFor="notification"
              textStyle="sm"
              fontWeight="medium"
              color="secondary.darken8"
              mx={0}
              h="fit-content"
              p="16px"
            >
              Notifikasi
            </Menu.ItemGroupLabel>

            <Flex flexDir="column" gap="0px" width="full" maxH="500px" overflowY="auto">
              {notification?.data?.length === 0 ? (
                <NotificationEmpty />
              ) : (
                <InfiniteScroller
                  fetchNextPage={notification.fetchNextPage}
                  hasNextPage={notification.hasNextPage}
                  isLoading={notification.isFetchingNextPage}
                  loadingMessage={
                    <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                      {A.makeWithIndex(5, (i) => (
                        <Skeleton key={i} height="86" />
                      ))}
                    </Flex>
                  }
                >
                  {notification.data.map((v) => {
                    return <NotificationItem key={v.id} item={v} handleRedirect={handleRedirectNotification} />;
                  })}
                </InfiniteScroller>
              )}
            </Flex>
          </Menu.ItemGroup>
        </Menu.Content>
      </Menu.Positioner>
    </Menu.Root>
  );
};

const NotificationItem = ({
  item,
  handleRedirect,
}: {
  item: BaseNotification;
  handleRedirect: RedirectNotification;
}) => {
  return (
    <Menu.Item
      id="profile"
      mx={0}
      h="fit-content"
      p="16px"
      borderRadius={0}
      bgColor={item.read ? "white" : "green.lighten1"}
      _hover={{
        bgColor: item.read ? "secondary.lighten1" : "green.lighten2",
      }}
      onClick={() => {
        handleRedirect(item);
      }}
      asChild
    >
      <Flex alignItems="center" gap="4px">
        <Avatar />
        <VStack alignItems="start" gap="4px">
          <p
            className={css({
              textStyle: "sm",
              color: "secondary",
              fontWeight: "normal",
              lineClamp: 3,
            })}
            dangerouslySetInnerHTML={{ __html: item.message }}
          />
          <p
            className={css({
              textStyle: "sm",
              color: "secondary.lighten4",
              fontWeight: "normal",
            })}
          >
            {dayjs(item.createdAt).locale("id").format("DD MMMM YYYY, HH.mm")}
          </p>
        </VStack>

        {item.image && (
          <Image
            src={item.image}
            imageStyle={{
              width: "40px",
              objectFit: "contain",
            }}
          />
        )}
      </Flex>
    </Menu.Item>
  );
};

const NotificationEmpty = () => {
  return (
    <Menu.Item
      id="profile"
      mx={0}
      h="fit-content"
      paddingX="16px"
      paddingY="8px"
      borderRadius={0}
      bgColor={"white"}
      asChild
    >
      <Flex alignItems="center" gap="4px">
        <VStack alignItems="start" gap="4px">
          <p
            className={css({
              textStyle: "sm",
              color: "secondary.lighten4",
              fontWeight: "normal",
            })}
          >
            Belum ada notifikasi.
          </p>
        </VStack>
      </Flex>
    </Menu.Item>
  );
};

const NotificationTrigger = forwardRef<HTMLButtonElement, { count: number }>((props, ref) => {
  const maxSize = useMemo(() => {
    return props.count < 9 ? "16px" : props.count < 99 ? "20px" : props.count < 999 ? "24px" : "28px";
  }, [props.count]);

  return (
    <Button
      ref={ref}
      {...props}
      visual="ghost"
      variant="ghost"
      size="md"
      padding="0"
      position="relative"
      _hover={{
        bgColor: "transparent",
      }}
      _focus={{
        bgColor: "transparent",
        outline: "none",
      }}
      _active={{
        bgColor: "transparent",
        outline: "none",
      }}
    >
      {props.count > 0 && (
        <Flex
          w={maxSize}
          h={maxSize}
          justifyContent="center"
          alignItems="center"
          bgColor="red.darken5"
          borderRadius="50%"
          position="absolute"
          top="0px"
          padding="4px"
          color="white"
          right={props.count < 9 ? "-4px" : props.count < 99 ? "-8px" : "-16px"}
          textStyle="xs"
        >
          {props.count > 99 ? "99+" : props.count}
        </Flex>
      )}
      <Icon cursor="pointer">
        <IconBell />
      </Icon>
    </Button>
  );
});
