import { IconChevronDown, IconChevronRight, IconLogin, IconSearch } from "@tabler/icons-react";
import { Link, useRouterState } from "@tanstack/react-router";
import { Fragment } from "react";
import { css } from "styled-system/css";
import { Flex } from "styled-system/jsx";

import { type RenderTreeViewProps } from "~/constant";

import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Button } from "../Button";
import { Icon } from "../Icon";
import * as Menu from "../Menu";
import { Notification, Profile } from "./menu";
import { type MenuItem } from "~/@types";
import { getMenu } from "~/features/menu/query-options";

const ListTree: React.FC<RenderTreeViewProps> = ({ menu }) => {
  return (
    <Menu.Positioner>
      <Menu.Content bgColor="white" borderRadius="16px" border="1px solid #E4E7EC" boxShadow="shadow_2">
        {menu?.map((v, idx) => {
          const isParent = v.items!.length > 0;

          return (
            <Menu.ItemGroup key={idx} id={`group-${idx}`} mx="0" px="16px">
              {isParent ? (
                <Menu.Root positioning={{ placement: "right-start", gutter: -2 }}>
                  <Menu.TriggerItem asChild>
                    <p
                      className={css({
                        colorPalette: "secondary",
                        color: "colorPalette.darken8",
                        textStyle: "sm",
                        justifyContent: "space-between",
                        fontFamily: "dmSans!",
                        fontWeight: "normal",
                        py: "12px",
                        px: "0px",
                        mx: "0",
                      })}
                    >
                      {v.label}
                      <Icon color="disabled" width="16px" height="16px">
                        <IconChevronRight />
                      </Icon>
                    </p>
                  </Menu.TriggerItem>
                  <ListTree menu={v.items!} />
                </Menu.Root>
              ) : (
                <Menu.Item
                  id={`group-${idx}`}
                  p="0!"
                  asChild
                  _hover={{
                    bgColor: "transparent",
                  }}
                >
                  {v.external ? (
                    <a
                      className={css({
                        textStyle: "sm",
                        fontWeight: "normal",
                        p: "0",
                        mx: "0",
                        color: "secondary.darken8",
                      })}
                      href={v.to}
                      target="_blank"
                    >
                      {v.label}
                    </a>
                  ) : (
                    <Link
                      to={v.to}
                      className={css({
                        textStyle: "sm",
                        fontWeight: "normal",
                        p: "0",
                        mx: "0",
                        color: "secondary.darken8",
                        "&[data-status=active]": {
                          color: "primary",
                          fontWeight: "medium",
                        },
                      })}
                    >
                      {v.label}
                    </Link>
                  )}
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          );
        })}
      </Menu.Content>
    </Menu.Positioner>
  );
};

const RenderTreeView: React.FC<{ menu: MenuItem } & Menu.RootProps> = ({ menu, ...rest }) => {
  const router = useRouterState();

  return (
    <Menu.Root {...rest}>
      <Menu.Trigger asChild>
        <Flex alignItems="center" gap="8px" cursor="pointer">
          <p
            data-active={router.location.pathname.includes(menu.to!)}
            className={css({
              colorPalette: "secondary",
              color: "colorPalette.darken8",
              textStyle: "sm!",
              justifyContent: "space-between",
              fontFamily: "dmSans!",
              fontWeight: "normal",
              py: "12px",
              px: "0px",
              mx: "0",
              "&[data-active=true]": {
                color: "primary!",
                fontWeight: "medium",
              },
            })}
          >
            {menu.label}
          </p>
          <Icon color="disabled" width="16px" height="16px">
            <IconChevronDown />
          </Icon>
        </Flex>
      </Menu.Trigger>
      <ListTree menu={menu.items!} />
    </Menu.Root>
  );
};

export const Desktop: React.FC = () => {
  const queryClient = useQueryClient();
  const isLogin = queryClient.getQueryData(["isLogin"]);
  const data = useSuspenseQuery(getMenu);

  return (
    <>
      <Flex
        display={{
          base: "none",
          lg: "flex",
        }}
        alignItems="center"
        gap={6}
      >
        {data.data.dekstop?.map((v, idx) => {
          const isParent = v.items!.length > 0;

          return (
            <Fragment key={idx}>
              {!isParent ? (
                <Link
                  to={v.to}
                  className={css({
                    textStyle: "sm",
                    "&[data-status=active]": {
                      color: "primary",
                      fontWeight: "medium",
                    },
                  })}
                >
                  {v.label}
                </Link>
              ) : (
                <RenderTreeView menu={v} />
              )}
            </Fragment>
          );
        })}
      </Flex>
      <Flex
        display={{
          base: "none",
          lg: "flex",
        }}
        gap="6"
      >
        <Link
          to="/explore"
          className={css({
            display: "flex",
            alignSelf: "center",
          })}
        >
          <IconSearch size={22} color="#354052" />
        </Link>
        {isLogin ? (
          <Flex gap="24px" alignItems="center">
            <Notification />
            <Profile />
          </Flex>
        ) : (
          <>
            <Link to="/login">
              <Button
                visual="ghost"
                variant="primary"
                colorPalette="secondary"
                color="colorPalette"
                px={0}
                justifyContent="start"
              >
                Masuk
                <Icon color="secondary">
                  <IconLogin />
                </Icon>
              </Button>
            </Link>
            <Link to="/register">
              <Button variant="primary" size="md">
                Daftar
              </Button>
            </Link>
          </>
        )}
      </Flex>
    </>
  );
};
