import React from "react";
import { Grid } from "styled-system/jsx";
import { CardModule } from "~/features/module";
import { Module as IModule } from "~/features/module/@types";
import { Wrapper } from "../shared/Wrapper";
import { EmptyState } from "./EmptyState";

interface ModulProps {
  datum: IModule[];
}

export const Module: React.FC<ModulProps> = ({ datum }) => {
  return (
    <Wrapper title="Modul Belajar Ecobiz" subTitle="Panduan untuk pendidikan tentang bisnis berkelanjutan" to="/module">
      {datum.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid
          gridTemplateColumns={{
            sm: 2,
          }}
          gap={4}
          mt={6}
        >
          {datum.map((v) => (
            <CardModule key={v._id} datum={v} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};
