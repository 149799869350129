import sanitizeHtml from "sanitize-html";
import { Slide, CustomSlide } from "yet-another-react-lightbox";

export function mergeRefs<T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const normalizeIDR = (val: number) =>
  new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(val);

export const replace = (url: string, q: string) => {
  history.pushState({}, "", `${url}?q=${encodeURIComponent(q)}`);
};

export const getParamsKey = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const getValue = urlParams.get(key);

  return getValue;
};

export const openUrl = (url: string) => {
  window.open(url, "_blank");
};

export const removeMask = (str: string) => {
  return str.replace(/\D/g, "");
};

export const getExtension = (str: string) => {
  const splt = str?.split(".");
  return splt[splt.length - 1];
};

export const clearHtmlTags = (val: string) => {
  const sanitized = sanitizeHtml(val, {
    allowedTags: [],
  });

  return sanitized;
};

export const sanitize = (val: string) => {
  const sanitized = sanitizeHtml(val, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });
  return sanitized;
};

export const isYoutubeUrl = (str: string) => {
  const youtubeRegEx = new RegExp(
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  );

  return youtubeRegEx.test(str);
};

export const getId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const isCustomSlide = (slide: Slide): slide is CustomSlide => {
  return slide.type === "custom-slide";
};
