import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { defaultServices } from "~/services";
import { useDebounce } from "use-debounce";
import { User } from "~/@types";
import { useRouter, useSearch } from "@tanstack/react-router";

export const useGetInfiniteQueryUser = () => {
  const search = useSearch({
    from: "/_layout/forum/",
  });
  const [keyword, setKeyword] = useState("");

  const [debouncedSearch] = useDebounce(keyword, 500);

  const result = useInfiniteQuery({
    queryKey: [`users_${debouncedSearch}_${search.userCategory}`],
    queryFn: async ({ pageParam }) => {
      const q = {
        page: pageParam,
        keyword: debouncedSearch,
        limit: 10,
        userCategory: search.userCategory,
      };

      const res = await defaultServices.getList<User>({
        resource: "user",
        query: q,
      });

      return res;
    },
    select: (data) => {
      return {
        meta: data.pages[0].data.paging,
        list: data.pages.flatMap((v) =>
          v.data.list.map((v) => ({
            ...v,
            value: v._id,
            label: v.name,
          })),
        ),
      };
    },
    initialPageParam: 1,
    staleTime: 500_000,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });

  return { result, keyword, setKeyword, debouncedSearch };
};
