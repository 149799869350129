import "./index.css";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/600.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/800.css";
import "@fontsource/outfit/400.css";
import "@fontsource/outfit/500.css";
import "@fontsource/outfit/600.css";
import "@fontsource/outfit/700.css";
import "@fontsource/outfit/800.css";

// Nprogress
import "nprogress/nprogress.css";


import Nprogress from "nprogress";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createRouter } from "@tanstack/react-router";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

import { QueryClientProvider } from "@tanstack/react-query";
import { Footer, Navbar } from "./components";
import { ErrorElement } from "./components/ErrorElement";
import { client } from "./store";
import { ToastProvider } from "./context/Toast";

// Create a new router instance
export const router = createRouter({
  routeTree,
  context: {
    queryClient: client,
  },

  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: () => (
    <>
      <Navbar />
      <ErrorElement statusCode="500" />
      <Footer />
    </>
  ),
  defaultNotFoundComponent: () => (
    <>
      <Navbar />
      <ErrorElement statusCode="404" />
      <Footer />
    </>
  ),
});

Nprogress.configure({
  showSpinner: false,
});

router.subscribe("onBeforeLoad", ({ pathChanged }) => pathChanged && Nprogress.start());
router.subscribe("onLoad", () => Nprogress.done());

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={client}>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
);
