import type { AxiosError, AxiosProgressEvent } from "axios";
import { useRef, useState } from "react";
import { mediaService } from "~/services";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { type MediaResponse } from "~/@types";

type Params = Omit<UseMutationOptions<MediaResponse, AxiosError, File>, "mutationFn">;

export const useUploadMedia = (opts?: Params) => {
  const [progress, setProgress] = useState(0);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onUploadProgress = (ev: AxiosProgressEvent) => {
    setProgress(Math.round((ev.loaded * 100) / ev.total!));
  };

  const mutation = useMutation<MediaResponse, AxiosError, File>({
    ...opts,
    mutationFn: async (v) =>
      await mediaService.upload({
        file: v,
        progressCallback: onUploadProgress,
      }),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, cb?: (file: File) => boolean) => {
    if (e.target.files) {
      if (typeof cb === "function") {
        if (cb(e.target.files[0])) {
          mutation.mutate(e.target.files[0]);
        }
      } else {
        mutation.mutate(e.target.files[0]);
      }
    }
  };

  return {
    progress,
    mutation,
    inputFileRef,
    handleChange,
  };
};
