/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from "@tanstack/react-router";
import { Detail, getOneMeeting } from "~/features/meeting";

import { ErrorElement } from "~/components/ErrorElement";
import { SEO } from "~/components";

export const Route = createFileRoute("/_layout/meeting/$meetingId")({
  loader: async ({ context: { queryClient }, params: { meetingId } }) =>
    queryClient.ensureQueryData(getOneMeeting(meetingId)),
  component: () => {
    const data = Route.useLoaderData();
    return (
      <>
        <SEO
          description={data.data.description}
          title={data.data.title}
          url={`${window.location.hostname}/meeting/${data.data.id}`}
        />
        <Detail />
      </>
    );
  },
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  wrapInSuspense: true,
});
