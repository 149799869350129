import { Portal } from "@ark-ui/react";
import { IconDots, IconTrash } from "@tabler/icons-react";
import { UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import type React from "react";
import { forwardRef, useEffect, useState } from "react";
import { css } from "styled-system/css";
import { Box, Flex, HStack, VStack, VstackProps } from "styled-system/jsx";
import { Button, Icon, Image, Popover } from "~/components";
import { Avatar } from "~/components/ui/Avatar";
import { getMe } from "~/features/profile";
import { type Comment } from "../@types";
import { BaseCommentSchema } from "../schema";
import { Editor } from "./Editor";
import { getId, isYoutubeUrl } from "~/utils";

interface ListProps extends VstackProps {
  isLoading?: boolean;
  mutation: UseMutationResult<Record<string, any>, AxiosError, BaseCommentSchema>;
  mutationDelete: UseMutationResult<Record<string, any>, AxiosError, string>;
  comments: Comment[] | undefined;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
  maxWidthText?: string;
}

interface ListItemProps {
  datum: Comment;
  mutation: ListProps["mutation"];
  mutationDelete: UseMutationResult<Record<string, any>, AxiosError, string>;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
  maxWidthText?: string;
}

const ListItem: React.FC<ListItemProps> = ({ datum, mutation, setCommentId, mutationDelete }) => {
  const profile = useQuery(getMe);
  const [edited, setEdited] = useState(false);
  const [selected, setSelected] = useState<Comment>({} as Comment);

  useEffect(() => {
    if (mutation.isSuccess) {
      if (profile.data?.data.id === datum.createdBy?.id) {
        if (edited) {
          setEdited(false);
          setCommentId("");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  return (
    <VStack alignItems="start" width="full" id={datum.id}>
      <Flex justifyContent="space-between" width="inherit">
        <HStack gap="16px" alignItems="start" width="full">
          <Avatar src={datum?.createdBy?.avatar as string} name={datum?.createdBy?.name} />
          <VStack alignItems="start" width="full">
            <h5
              className={css({
                textStyle: "md",
                fontWeight: "medium",
                fontFamily: "outfit",
                color: "secondary.darken8",
              })}
            >
              {datum?.createdBy?.name}
            </h5>

            <p
              className={css({
                textStyle: "md",
                color: "secondary",
                overflowWrap: "anywhere",
                width: "calc(100% - 30px)",
              })}
            >
              {datum.content}
            </p>

            <Box>
              {!!datum.video &&
                (isYoutubeUrl(datum.video as string) ? (
                  <iframe
                    width="100%"
                    height="345"
                    allowFullScreen
                    src={`https://www.youtube.com/embed/${getId(datum.video)}`}
                    className={css({
                      rounded: "16px",
                    })}
                  ></iframe>
                ) : (
                  <video
                    width="320"
                    height="240"
                    controls
                    className={css({
                      rounded: "12px",
                    })}
                  >
                    <source src={datum.video!} type="video/ogg" />
                    <source src={datum.video!} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ))}
              {!!datum.image && (
                <Image
                  src={datum.image!}
                  alt="commentar"
                  imageStyle={{
                    rounded: "12px",
                    width: "full",
                    maxW: "218px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              )}

              {!!datum.audio && (
                <audio controls>
                  <source src={datum.audio!} type="audio/ogg" />
                  <source src={datum.audio!} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}

              <p
                className={css({
                  mt: "4px",
                  textStyle: "sm",
                  color: "disabled",
                })}
              >
                {dayjs(datum.createdAt).format("DD MMM YYYY")}
              </p>
            </Box>
          </VStack>
        </HStack>

        {profile.data?.data.id === datum.createdBy?.id && (
          <Popover.Root>
            <Popover.Trigger minH="fit-content" h="fit-content">
              <Icon color="disabled" cursor="pointer">
                <IconDots />
              </Icon>
            </Popover.Trigger>
            <Portal>
              <Popover.Positioner>
                <Popover.Content>
                  <Popover.Title asChild>
                    <VStack alignItems="start" gap="4">
                      {/* <Button
                        visual="ghost"
                        onClick={() => {
                          setSelected(datum);
                          setEdited(!edited);
                          setCommentId(datum.id);
                        }}
                        colorPalette="secondary"
                        h="fit-content!"
                        minH="fit-content!"
                        alignItems="start"
                        justifyContent="start"
                        px={0}
                      >
                        Edit
                      </Button> */}
                      <Button
                        visual="ghost"
                        h="fit-content!"
                        minH="fit-content!"
                        onClick={() => {
                          mutationDelete.mutate(datum.id);
                        }}
                        loading={mutationDelete.isPending}
                        alignItems="center"
                        justifyContent="center"
                        px={0}
                      >
                        <Icon color="secondary">
                          <IconTrash />
                        </Icon>
                        <p
                          className={css({
                            fontWeight: "medium",
                            color: "secondary.darken8",
                            textStyle: "sm",
                          })}
                        >
                          Hapus Komentar
                        </p>
                      </Button>
                    </VStack>
                  </Popover.Title>
                </Popover.Content>
              </Popover.Positioner>
            </Portal>
          </Popover.Root>
        )}
      </Flex>
      {edited && (
        <Box pl={8} width="inherit">
          <Editor mutation={mutation} placeholderData={selected} />
        </Box>
      )}
    </VStack>
  );
};

export const List = forwardRef<HTMLTextAreaElement, ListProps>(
  ({ mutation, comments, setCommentId, mutationDelete, maxWidthText, ...rest }, ref) => {
    const queryClient = useQueryClient();
    const isLogin = queryClient.getQueryData(["isLogin"]);
    return (
      <>
        <VStack alignItems="start" gap="4" {...rest} width="full">
          {comments?.map((v) => (
            <ListItem
              datum={v}
              key={v._id}
              mutation={mutation}
              setCommentId={setCommentId}
              mutationDelete={mutationDelete}
              maxWidthText={maxWidthText}
            />
          ))}
        </VStack>
        {isLogin ? (
          <Box py="4" width="full">
            <Editor mutation={mutation} ref={ref} />
          </Box>
        ) : null}
      </>
    );
  },
);
