import { Portal } from "@ark-ui/react";
import { A } from "@mobily/ts-belt";
import { IconCheck, IconCircleXFilled, IconSearch } from "@tabler/icons-react";
import { css } from "styled-system/css";
import { Box, Flex, HStack } from "styled-system/jsx";
import { Avatar, Combobox, Icon, InfiniteScroller, Input } from "~/components";
import { Skeleton } from "~/components/ui/Skeleton";
import { useGetInfiniteQueryUser } from "../query-options";
import { memo } from "react";

interface ComboboxUserProps extends Omit<Combobox.RootProps, "items"> {}

export const ComboboxUser = memo(function ComboboxUser({ ...props }: ComboboxUserProps) {
  const { result, setKeyword } = useGetInfiniteQueryUser();

  return (
    <Combobox.Root
      onInputValueChange={(v) => setKeyword(v.value)}
      openOnClick
      w="full"
      items={Array.isArray(result.data?.list) ? result.data.list : []}
      {...props}
    >
      <Combobox.Control>
        <Box position="relative">
          <Combobox.Input asChild>
            <Input h="40px" pl="35px" rounded="8px" w="full" borderColor="secondary.lighten2" placeholder="Pencarian" />
          </Combobox.Input>
          <Icon
            pos="absolute"
            height="40px"
            display="flex"
            flexDir="column"
            left={3}
            top={0}
            alignItems="start"
            color="secondary.lighten4"
            _groupFocus={{
              color: "primary!",
            }}
            size="sm"
          >
            <IconSearch />
          </Icon>
          <Combobox.ClearTrigger asChild>
            <Icon
              pos="absolute"
              height="40px"
              display="flex"
              flexDir="column"
              right={3}
              top={0}
              alignItems="start"
              cursor="pointer"
              color="secondary.lighten4"
              _groupFocus={{
                color: "primary!",
              }}
            >
              <IconCircleXFilled />
            </Icon>
          </Combobox.ClearTrigger>
        </Box>
      </Combobox.Control>
      <Portal>
        <Combobox.Positioner>
          <Combobox.Content maxH="275px" h="full" overflowY="scroll">
            <InfiniteScroller
              isLoading={result.isLoading}
              fetchNextPage={result.fetchNextPage}
              hasNextPage={result.hasNextPage}
              loadingMessage={
                <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                  {A.makeWithIndex(5, (i) => (
                    <Skeleton key={i}>
                      <p>loading...</p>
                    </Skeleton>
                  ))}
                </Flex>
              }
              className={css({
                display: "flex",
                flexDir: "column",
                my: 2,
              })}
            >
              {result.data?.list?.length === 0 ? (
                <p
                  className={css({
                    textStyle: "sm",
                    color: "secondary.lighten4",
                    p: 4,
                  })}
                >
                  Pencarian yang anda cari tidak ditemukan.
                </p>
              ) : (
                result?.data?.list.map((item) => (
                  <Combobox.Item key={item.value} item={item} py="12px" px="16px" rounded="8px" height="fit-content">
                    <HStack>
                      <Avatar src={item.avatar} size="md" />
                      <Combobox.ItemText asChild>
                        <Box display="flex" flexDir="column">
                          <h3
                            className={css({
                              textStyle: "md",
                              fontWeight: "semibold",
                              color: "secondary.darken8",
                            })}
                          >
                            {item.label}
                          </h3>
                          <p
                            className={css({
                              textStyle: "sm",
                              color: "secondary.lighten4",
                              fontWeight: "medium",
                            })}
                          >
                            {item.userCategory?.name || "-"}
                          </p>
                        </Box>
                      </Combobox.ItemText>
                    </HStack>
                    <Combobox.ItemIndicator>
                      <IconCheck />
                    </Combobox.ItemIndicator>
                  </Combobox.Item>
                ))
              )}
            </InfiniteScroller>
          </Combobox.Content>
        </Combobox.Positioner>
      </Portal>
    </Combobox.Root>
  );
});
