import { IconDownload, IconFileFilled } from "@tabler/icons-react";
import { getRouteApi } from "@tanstack/react-router";
import { css } from "styled-system/css";
import { Box, Container, Flex, HStack } from "styled-system/jsx";
import { BreadCrumb, Button, Card, Icon } from "~/components";
import { CardDetail } from "~/features/event";
import { getId, isYoutubeUrl } from "~/utils";

export const Detail = () => {
  const getRoute = getRouteApi("/_layout/event/$eventId");
  const result = getRoute.useLoaderData();

  const breadcrumbs = [
    {
      path: "Event",
      to: `/event`,
      disabled: false,
    },
    {
      path: "Detail",
      to: "#",
      disabled: true,
    },
  ];

  return (
    <>
      <Box bgColor="secondary.lighten1">
        <Container maxW="4xl" pt={10} pb={20}>
          <Box mb={10}>
            <BreadCrumb items={breadcrumbs} />
          </Box>

          <Flex flexDir="column" gap={6}>
            <CardDetail
              headerImage="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709093278991_UI (2).svg"
              datum={result.data}
            />
            <Card.Root borderColor="secondary.lighten2" borderWidth="1" rounded="16px">
              <Card.Body p="24px" gap={4} asChild flexDir="row">
                <Flex justifyContent="space-between">
                  <HStack gap="16px">
                    <Icon color="disabled" size="2xl">
                      <IconFileFilled />
                    </Icon>
                    <p
                      className={css({
                        textStyle: "md",
                        fontWeight: "semibold",
                        fontFamily: "outfit",
                        color: "secondary.darken8",
                      })}
                    >
                      Lampiran File
                    </p>
                  </HStack>
                  <a href={result.data.file} target="_blank">
                    <Button>
                      <Icon>
                        <IconDownload />
                      </Icon>
                      Download
                    </Button>
                  </a>
                </Flex>
              </Card.Body>
            </Card.Root>
            {result.data.video ? (
              <Card.Root borderColor="secondary.lighten2" borderWidth="1" rounded="16px">
                <Card.Title>
                  <Card.Header textStyle="lg" color="disabled" fontWeight="semibold" fontFamily="outfit!">
                    Video
                  </Card.Header>
                </Card.Title>
                <Card.Body p="24px" asChild>
                  {isYoutubeUrl(result.data.video) ? (
                    <iframe
                      width="100%"
                      height="512"
                      allowFullScreen
                      src={`https://www.youtube.com/embed/${getId(result.data.video)}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      className={css({
                        rounded: "16px",
                        border: "0px",
                        minH: "512px",
                      })}
                    ></iframe>
                  ) : (
                    <video
                      width="100%"
                      height="512"
                      controls
                      className={css({
                        rounded: "16px",
                        minH: "512px",
                      })}
                    >
                      <source src={result.data.video!} type="video/ogg" />
                      <source src={result.data.video!} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Card.Body>
              </Card.Root>
            ) : null}
          </Flex>
        </Container>
      </Box>
    </>
  );
};
