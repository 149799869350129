import React from "react";

import { useInfiniteScroll } from "~/hooks";

export interface InfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  loadingMessage?: React.ReactNode;
  isLoading: boolean;
  endingMessage?: React.ReactNode;
}

export const InfiniteScroller = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(
  ({ fetchNextPage, hasNextPage, endingMessage, isLoading, loadingMessage, children, ...props }, ref) => {
    const observerTarget = useInfiniteScroll(fetchNextPage);
    return (
      <div ref={ref} style={{ overflowAnchor: "none" }} {...props}>
        {children}
        <div ref={observerTarget} />
        {hasNextPage || isLoading ? loadingMessage : endingMessage}
      </div>
    );
  },
);
