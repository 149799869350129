import { Button, ControlledInput, Image } from "~/components";
import { useAuth } from "../../hooks";
import { Layout } from "../../layout";
import { Box, Center, HStack } from "styled-system/jsx";
import { css } from "styled-system/css";

import { Link, getRouteApi } from "@tanstack/react-router";
import { loginSchema } from "~/services";
import { useQueryClient } from "@tanstack/react-query";
import { getMe } from "~/features/profile";
import { useToast } from "~/context/Toast";

export const Login = () => {
  const getRoute = getRouteApi("/login");
  const searchParams = getRoute.useSearch();

  const { toast } = useToast();
  const client = useQueryClient();
  const { control, errors, mutation, onSubmit } = useAuth(
    "login",
    loginSchema,
    {
      defaultValues: {
        email: "",
        password: "",
      },
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.fetchQuery(getMe),
          client.fetchQuery({
            queryKey: ["isLogin"],
            queryFn: () => Promise.resolve(true),
            staleTime: Infinity,
          }),
        ]);

        if (searchParams.destinationPath) {
          window.location.href = "/";
        } else {
          window.history.back();
        }
      },
      onError: (error) => {
        toast.error({
          title: "Login gagal",
          description: error.message,
        });
      },
    },
  );

  return (
    <Layout gap="40px" h="100vh">
      <Center>
        <Link to="/">
          <Image
            src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709537934285_UI (4).svg"
            imageStyle={{
              width: "218.42px",
              objectFit: "contain",
            }}
          />
        </Link>
      </Center>
      <Box>
        <h1
          className={css({
            textAlign: "center",
            textStyle: "h6",
            color: "secondary.darken8",
            fontWeight: "bold",
          })}
        >
          Masuk ke Ecobiz
        </h1>
        <p
          className={css({
            textStyle: "md",
            textAlign: "center",
            mt: "8px",
            color: "secondary",
          })}
        >
          Silakan masuk menggunakan email yang sudah terdaftar ya.
        </p>
      </Box>

      <form
        onSubmit={onSubmit}
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "16px",
        })}
      >
        <ControlledInput
          control={control}
          name="email"
          error={errors}
          label="Email"
          inputProps={{
            placeholder: "Ketik disini",
          }}
        />
        <ControlledInput
          control={control}
          name="password"
          error={errors}
          label="Kata Sandi"
          inputProps={{
            placeholder: "Ketik disini",
            type: "password",
          }}
        />
        <HStack justifyContent="end">
          <Link to="/forget-password">
            <p
              role="link"
              className={css({
                textStyle: "sm",
                color: "secondary.darken8",
                textDecoration: "underline",
              })}
            >
              Lupa kata sandi?
            </p>
          </Link>
        </HStack>
        <Button type="submit" mt="24px" loading={mutation.isPending}>
          Masuk
        </Button>
      </form>

      <p
        className={css({
          textStyle: "md",
          color: "secondary.darken8",
          textAlign: "center",
        })}
      >
        Belum memiliki akun?{" "}
        <Link to="/register">
          <span
            className={css({
              color: "primary",
              textDecoration: "underline",
            })}
          >
            Daftar
          </span>
        </Link>
      </p>
    </Layout>
  );
};
