import React from "react";
import { Wrapper } from "../shared/Wrapper";
import { CardEvent, Event as IEvent } from "~/features/event";
import { Grid, GridItem } from "styled-system/jsx";
import { EmptyState } from "./EmptyState";

interface EventProps {
  datum: IEvent[];
}

export const Event: React.FC<EventProps> = ({ datum }) => {
  return (
    <Wrapper title="Event" subTitle="Cari event menarik di ecobiz" to="/event">
      {datum.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid
          gridTemplateColumns={{
            lg: "repeat(3, minmax(0, 1fr))",
          }}
          mt={6}
        >
          {datum.map((v) => (
            <GridItem key={v._id}>
              <CardEvent
                datum={v}
                image="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709088379203_UI (1).svg"
                to="/event/$eventId"
                params={{
                  eventId: v.id,
                }}
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};
