import { Helmet } from "react-helmet-async";

import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { List, getListProductInfiniteQuery } from "~/features/product";
import { BackToTop } from "~/components";
import { ErrorElement } from "~/components/ErrorElement";

const productSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(18),
  keyword: z.string().catch(""),
  category: z.string().catch(""),
});

type ProductSearchSchema = z.infer<typeof productSearchSchema>;

export const Route = createFileRoute("/_layout/product/")({
  validateSearch: (search: Record<string, unknown>): ProductSearchSchema => productSearchSchema.parse(search),
  loaderDeps: ({ search: { keyword, limit, page, category } }) => ({ keyword, limit, page, category }),
  loader: ({ context: { queryClient }, deps: { page, category, keyword, limit } }) => {
    return queryClient.fetchInfiniteQuery(
      getListProductInfiniteQuery({
        page,
        category,
        keyword,
        limit,
      }),
    );
  },
  gcTime: 0,
  wrapInSuspense: true,
  shouldReload: false,

  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  component: () => (
    <>
      <Helmet>
        <title>Product - Ecobiz</title>
      </Helmet>
      <List />
      <BackToTop
        right={{
          base: "12px",
        }}
      />
    </>
  ),
});
