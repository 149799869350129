import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useDisClosure, useUploadMedia } from "~/hooks";
import {
  ProductSchema,
  createProduct,
  deleteProduct,
  getOneProduct,
  productSchema,
  updateProduct,
} from "~/features/product";
import { D } from "@mobily/ts-belt";
import { useToast } from "~/context/Toast";

const DEFAULT_VALUES: ProductSchema = {
  category: "",
  description: "",
  image: "",
  name: "",
  origin: "",
  price: "",
  spec: "",
};

export const useMutationMyProduct = () => {
  const getRoute = getRouteApi("/_layout/my-product/mutation");
  const productId = getRoute.useSearch().productId;
  const { toast } = useToast();

  const product = useQuery({
    ...getOneProduct(productId as string),
  });
  const client = useQueryClient();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ProductSchema>({
    resolver: zodResolver(productSchema),
    values: productId
      ? {
          category: product.data?.data?.category._id as string,
          image: null,
          description: product.data?.data?.description as string,
          name: product.data?.data?.name as string,
          origin: product.data?.data?.origin as string,
          price: String(product.data?.data?.price) as string,
          spec: product.data?.data?.spec as string,
        }
      : DEFAULT_VALUES,
  });

  const { mutation, progress, inputFileRef, handleChange } = useUploadMedia({
    onSuccess: (data) => {
      if (inputFileRef.current) {
        inputFileRef.current.value = "";
      }
      setValue("image", data.data.url);
    },
  });

  const resetImage = () => {
    setValue("image", "");
    mutation.reset();
  };

  const mutationProduct = useMutation<unknown, AxiosError, ProductSchema>({
    mutationFn: (v) => (productId ? updateProduct(v, productId) : createProduct(v)),
    onSuccess: () => {
      if (productId) {
        toast.success({
          title: "Product Berhasil Perbarui",
        });
        client.invalidateQueries({
          queryKey: [`detail-product-${productId}`],
        });
      } else {
        toast.success({
          title: "Product Berhasil Dibuat",
        });
      }
      navigate({
        to: "/my-product",
        search: {
          category: "",
          keyword: "",
          limit: 9,
          page: 1,
        },
      });
    },
  });

  const getPreviewImage = watch("image");

  return {
    product,
    resetImage,
    control,
    handleChange,
    progress,
    inputFileRef,
    mutation,
    mutationProduct,
    errors,
    productId,
    onSubmit: handleSubmit((v) => mutationProduct.mutate(v)),
    open,
    getPreviewImage,
  };
};

export const useDeleteProduct = () => {
  const getRouteList = getRouteApi("/_layout/my-product/");
  const { open, onToggle } = useDisClosure();
  const getQuerySearchList = getRouteList.useSearch();
  const client = useQueryClient();
  const { toast } = useToast();

  const mutationDelete = useMutation<unknown, AxiosError, string>({
    mutationFn: (v) => deleteProduct(v),
    onSuccess: () => {
      toast.success({
        title: "1 Produk dihapus",
      });
      client.invalidateQueries({
        queryKey: ["product", { ...D.deleteKey(getQuerySearchList, "limit"), myProduct: true }],
      });
      onToggle();
    },
  });

  return {
    mutationDelete,
    open,
    onToggle,
  };
};
