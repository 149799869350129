import React from "react";
import { Divider, Flex, HStack } from "styled-system/jsx";
import { Card, Icon, Image } from "~/components";
import { Status } from "../badge";
import { Event } from "~/features/event/@types";
import { IconCalendarEvent } from "@tabler/icons-react";
import dayjs from "dayjs";
import { css } from "styled-system/css";
import { IconClockHour3 } from "@tabler/icons-react";

interface CardDetail {
  headerImage: string;
  datum: Omit<Event, "link">;
}

export const CardDetail: React.FC<CardDetail> = ({ datum, headerImage }) => {
  return (
    <Card.Root borderColor="secondary.lighten2" borderWidth="1" rounded="16px">
      <Card.Header p="0">
        <Card.Title>
          <Image
            src={headerImage}
            imageStyle={{
              width: "full",
              height: "120px",
              objectFit: "cover",
            }}
          />
        </Card.Title>
      </Card.Header>
      <Card.Body p="24px" gap={4}>
        <Flex
          alignItems={{
            base: "start",
            sm: "center",
          }}
          gap="24px"
          flexDir={{
            base: "column",
            sm: "row",
          }}
        >
          <Status position="relative" status={datum.status} />
          <Divider
            orientation="vertical"
            display={{
              base: "none",
              md: "block",
            }}
            h="32px"
          />
          <HStack>
            <Icon size="md" color="secondary">
              <IconCalendarEvent />
            </Icon>
            <p
              className={css({
                textStyle: "sm",
                fontWeight: "medium",
                color: "secondary",
              })}
            >
              {dayjs(datum.refDate).format("DD MMMM YYYY")}
            </p>
          </HStack>
          <HStack>
            <Icon size="md" color="secondary">
              <IconClockHour3 />
            </Icon>
            <p
              className={css({
                textStyle: "sm",
                fontWeight: "medium",
                color: "secondary",
              })}
            >
              {dayjs(datum.refDate).format("HH.mm")} WIB
            </p>
          </HStack>
        </Flex>
        <Divider
          my={6}
          orientation="horizontal"
          display={{
            base: "block",
            md: "none",
          }}
        />
        <h1
          className={css({
            textStyle: "h3",
            color: "secondary.darken8",
            fontWeight: "bold",
          })}
        >
          {datum.title}
        </h1>
        <p
          className={css({
            textStyle: "lg",
            whiteSpace: "pre-line",
            color: "secondary",
          })}
        >
          {datum.description}
        </p>
      </Card.Body>
    </Card.Root>
  );
};
