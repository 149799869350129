import { createFileRoute } from "@tanstack/react-router";
import { List } from "~/features/module/components";

import { z } from "zod";
import { moduleGetList } from "~/features/module";
import { Helmet } from "react-helmet-async";
import { ErrorElement } from "~/components/ErrorElement";

const moduleSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(10),
  keyword: z.string().catch(""),
});

type ModuleSearch = z.infer<typeof moduleSearchSchema>;

export const Route = createFileRoute("/_layout/module/")({
  validateSearch: (search: Record<string, unknown>): ModuleSearch => moduleSearchSchema.parse(search),

  loaderDeps: ({ search: { keyword, limit, page } }) => ({ keyword, limit, page }),
  loader: ({ context: { queryClient }, deps: { page, keyword } }) =>
    queryClient.ensureQueryData(
      moduleGetList({
        page,
        keyword,
      }),
    ),
  gcTime: 0,
  shouldReload: false,

  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  component: () => (
    <>
      <Helmet>
        <title>Modul - Ecobiz</title>
      </Helmet>
      <List />
    </>
  ),
});
