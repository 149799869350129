import { IconCheckbox, IconRun } from "@tabler/icons-react";
import React from "react";
import { css } from "styled-system/css";
import { Box, BoxProps, HStack } from "styled-system/jsx";
import { Icon } from "~/components";
import { Event } from "~/features/event/@types";

interface StatusProps extends BoxProps {
  status: Event["status"];
}

export const Status: React.FC<StatusProps> = ({ status, ...rest }) => {
  return (
    <Box
      {...rest}
      py={1}
      px={3}
      rounded="full"
      data-state={status}
      className={css({
        "&[data-state=pending]": {
          bgColor: "accent.ligthen5",
        },
        "&[data-state=done]": {
          bgColor: "primary",
        },
      })}
    >
      <HStack gap="1">
        <Icon color="white" size="lg">
          {status === "done" ? <IconCheckbox /> : <IconRun />}
        </Icon>
        <p
          className={css({
            textStyle: "xs",
            color: "white",
            fontWeight: "semibold",
          })}
        >
          {status === "done" ? "SELESAI" : "AKAN DATANG"}
        </p>
      </HStack>
    </Box>
  );
};
