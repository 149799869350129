import { createFileRoute } from "@tanstack/react-router";
import { ResetPassword } from "~/features/auth/components/templates";

import { Helmet } from "react-helmet-async";

type ResetPasswordSearch = {
  token: string;
};

export const Route = createFileRoute("/reset-password")({
  validateSearch: (search: Record<string, unknown>): ResetPasswordSearch => {
    return {
      token: search.token as string,
    };
  },
  component: () => (
    <>
      <Helmet>
        <title>Reset Password - Ecobiz</title>
      </Helmet>
      <ResetPassword />
    </>
  ),
});
