import { Portal } from "@ark-ui/react";
import { IconX } from "@tabler/icons-react";
import React from "react";
import { Box } from "styled-system/jsx";

import { Button } from "~/components/ui/Button";
import * as Dialog from "~/components/ui/Dialog";
import { IconButton } from "~/components/ui/IconButton";

interface ModalProps extends Dialog.RootProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  withAction?: boolean;
  onToggle: (v: boolean) => void;
  rootProps?: Dialog.RootProps;
  contentProps?: Dialog.ContentProps;
  descriptionProps?: Dialog.DescriptionProps;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onToggle,
  title,
  description,
  withAction = false,
  rootProps,
  contentProps,
  descriptionProps,
}) => {
  return (
    <Dialog.Root lazyMount open={open} onOpenChange={(e) => onToggle(e.open)} {...rootProps}>
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content {...contentProps}>
            <Box
              borderBottomWidth="1px"
              borderBottomColor="secondary.lighten2"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py="24px"
              px="16px"
              position="sticky"
              top={0}
              bgColor="white"
            >
              <Dialog.Title asChild={React.isValidElement(title)}>{title}</Dialog.Title>
              <Dialog.CloseTrigger asChild>
                <IconButton aria-label="Close Dialog" variant="ghost" size="sm">
                  <IconX />
                </IconButton>
              </Dialog.CloseTrigger>
            </Box>

            <Box p="16px" {...descriptionProps}>
              <Dialog.Description asChild={React.isValidElement(description)}>{description}</Dialog.Description>
            </Box>
            {withAction && (
              <>
                <Dialog.CloseTrigger asChild>
                  <Button variant="outline" width="full">
                    Cancel
                  </Button>
                </Dialog.CloseTrigger>
                <Button width="full">Confirm</Button>
                <Dialog.CloseTrigger asChild position="absolute" top="2" right="2">
                  <IconButton aria-label="Close Dialog" variant="ghost" size="sm">
                    <IconX />
                  </IconButton>
                </Dialog.CloseTrigger>
              </>
            )}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};

export default Modal;
