import React from "react";
import { css } from "styled-system/css";
import { Center, CenterProps } from "styled-system/jsx";
import { Image } from "..";
import { SystemStyleObject } from "@pandacss/dev";

type EmptyStateProps = {
  image?: string;
  title: string;
  description: string;
  imageStyle?: SystemStyleObject;
} & CenterProps;

export const EmptyState: React.FC<EmptyStateProps> = ({
  description,
  imageStyle,
  image = "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1708913660837_Empty states.svg",
  title,
  ...rest
}) => {
  return (
    <Center flexDir="column" {...rest}>
      <Image src={image} imageStyle={imageStyle} alt="empty state" />
      <h3
        className={css({
          textStyle: "lg",
          fontWeight: "semibold",
          fontFamily: "outfit!",
        })}
      >
        {title}
      </h3>
      {description && (
        <p
          className={css({
            textStyle: "md",
            textAlign: "center",
            color: "secondary",
            maxW: "50ch",
          })}
        >
          {description}
        </p>
      )}
    </Center>
  );
};
