/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from "@tanstack/react-router";
import { Detail, getOneProduct } from "~/features/product";

import { ErrorElement } from "~/components/ErrorElement";
import { SEO } from "~/components";

export const Route = createFileRoute("/_layout/product/$productId")({
  loader: async ({ context: { queryClient }, params: { productId } }) => {
    return queryClient.ensureQueryData(getOneProduct(productId));
  },
  component: () => {
    const data = Route.useLoaderData();

    return (
      <>
        <SEO
          description={data.data.description}
          title={data.data.name}
          image={data.data.image}
          url={`${window.location.hostname}/product/${data.data.id}`}
        />
        <Detail />
      </>
    );
  },

  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,

  wrapInSuspense: true,
});
