import { Center, Container, Flex, Grid, GridItem } from "styled-system/jsx";
import { css } from "styled-system/css";
import { CardEvent } from "~/features/event";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { EmptyState, Pagination } from "~/components";

export const List = () => {
  const getRoute = getRouteApi("/_layout/meeting/");

  const result = getRoute.useLoaderData();

  const navigate = useNavigate({
    from: "/meeting",
  });

  return (
    <Container maxW="7xl" mt={10} mb={20}>
      <h1
        className={css({
          textStyle: "h6",
          color: "disabled",
          fontWeight: "bold",
        })}
      >
        Meeting Room
      </h1>
      {result.data.list.length === 0 ? (
        <Center>
          <EmptyState
            title="Belum ada meeting yang tersedia"
            description="Semua meeting tersedia akan muncul di halaman ini."
          />
        </Center>
      ) : (
        <Flex gap="40px" flexDir="column" mt={10}>
          <Grid
            gridTemplateColumns={{
              base: "1",
              sm: "2",
              lg: "3",
            }}
            gap="24px"
          >
            {result.data.list.map((v) => (
              <GridItem gridColumn="1" key={v.id}>
                <CardEvent
                  datum={v}
                  to="/meeting/$meetingId"
                  image="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709042983754_UI User Website (1).svg"
                  params={{
                    meetingId: v.id,
                  }}
                />
              </GridItem>
            ))}
          </Grid>
          <Center>
            <Pagination
              count={result.data.paging.count}
              pageSize={result.data.paging.limit}
              siblingCount={0}
              page={result.data.paging.page}
              onPageChange={(v) => {
                navigate({
                  search: (prev) => {
                    return {
                      ...prev,
                      page: v.page,
                    };
                  },
                });
              }}
            />
          </Center>
        </Flex>
      )}
    </Container>
  );
};
