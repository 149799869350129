import { fetchDetail, fetchArticle, type Params } from "~/features/article";
import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { getExtension, isYoutubeUrl } from "~/utils";
import { IMAGE } from "~/constant";
import { type Slide } from "yet-another-react-lightbox";

type ExtsParams = {
  extension: string;
  mimetype: string;
};

const exts = (arr: ExtsParams[]) => arr.map((v) => v.extension);

const imagesExtension = exts(IMAGE);

export const articleGetOne = (articleId: string) =>
  queryOptions({
    queryKey: [`news-${articleId}`],
    queryFn: () => fetchDetail(articleId),
    staleTime: Infinity,
    select: (data) => {
      return {
        ...data,
        data: {
          ...data.data,

          attachments: () => {
            const result: Slide[] = [];

            data.data.attachments.forEach((v) => {
              const decideType = imagesExtension.includes(getExtension(v))
                ? "image"
                : isYoutubeUrl(v)
                  ? "custom-slide"
                  : "video";

              if (decideType === "video") {
                result.push({
                  type: "video",
                  poster: "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1710306427538_UI (5).svg",
                  width: 512,
                  height: 512,
                  sources: [
                    {
                      src: v,
                      type: `video/mp4`,
                    },
                  ],
                });
              } else if (decideType === "image") {
                result.push({
                  src: v,
                  type: "image",
                });
              } else {
                result.push({
                  type: "custom-slide",
                  poster: "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1710306427538_UI (5).svg",
                  src: v,
                });
              }
            });

            return result;
          },
        },
      };
    },
  });

export const articleGetList = (q: Params) =>
  queryOptions({
    queryKey: ["news", { ...q }],
    queryFn: () => fetchArticle(q),
    staleTime: 50_000,
    placeholderData: keepPreviousData,
  });
