import { z } from "zod";

export const baseCommentSchema = z.object({
  content: z.string().min(1, {
    message: "Content tidak boleh kosong",
  }),
  image: z.string().nullable().optional(),
  video: z.string().nullable().optional(),
  audio: z.string().nullable().optional(),
});

export type BaseCommentSchema = z.infer<typeof baseCommentSchema>;
