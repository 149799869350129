import { BaseQuery } from "~/@types";
import { defaultServices } from "~/services";
import { Module } from "../@types";

export const fetchModule = async (q: BaseQuery) => {
  const res = await defaultServices.getList<Module>({
    resource: "/lesson",
    query: {
      ...q,
    },
  });

  return res;
};
