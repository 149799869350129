import { defaultServices } from "~/services";
import { BaseNotification, NotificationCount } from "../@types";
import { http } from "~/libs/axios";
import qs from "qs";
import { BaseQuery } from "~/@types";

interface FetchListNotificationParams extends Omit<BaseQuery, "keyword"> {
  read?: boolean;
}

export type FetchCountNotificationParams = {
  read?: boolean;
};

export const fetchListNotification = async (q: FetchListNotificationParams) => {
  const res = await defaultServices.getList<BaseNotification>({
    resource: "/notification",
    query: {
      ...q,
    },
  });

  return res;
};

export const fetchCountNotification = async (q: FetchCountNotificationParams) => {
  const query = qs.stringify(q);

  const res = await http<{
    data: NotificationCount;
  }>("GET", {
    url: `/notification/count?${query}`,
  });

  return res;
};

export const updateReadNotification = async (id: string) => {
  const res = await defaultServices.update<BaseNotification>({
    resource: `/notification/${id}/read`,
    variables: {},
  });

  return res;
};
