import React from "react";
import { css } from "styled-system/css";
import { Box, Center, Container, Flex, Grid, GridItem } from "styled-system/jsx";
import { CardNews } from "../data-display";
import { Pagination, type PaginationProps } from "~/components/ui";
import { INews } from "../../@types";

type ListProps = {
  title: string;
  description: string;
  items: INews[];
  hash: string;
  paging: {
    count: number;
    limit: number;
  };
} & Pick<PaginationProps, "onPageChange" | "page">;

export const List: React.FC<ListProps> = ({ title, description, items, paging, onPageChange, page, hash }) => {
  return (
    <Container maxW="7xl" my={20}>
      <Flex gap="40px" flexDir="column">
        <Box>
          <h1
            className={css({
              textStyle: "h2",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            {title}{" "}
            <span
              className={css({
                color: "primary",
              })}
            >
              Ecobiz
            </span>
          </h1>
          <p
            className={css({
              textStyle: "lg",
              color: "secondary",
            })}
          >
            {description}
          </p>
        </Box>
        <Grid
          gridTemplateColumns={{
            base: "1",
            sm: "2",
            lg: "3",
          }}
          gap="24px"
        >
          {items.map((v) => (
            <GridItem gridColumn="1" key={v.id}>
              <CardNews datum={v} hash={hash} />
            </GridItem>
          ))}
        </Grid>
        <Center>
          <Pagination
            count={paging.count}
            pageSize={paging.limit}
            siblingCount={0}
            page={page}
            onPageChange={onPageChange}
          />
        </Center>
      </Flex>
    </Container>
  );
};
