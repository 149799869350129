import { defaultServices } from "~/services";
import { DetailProduct, Product } from "../@types";
import { BaseQuery } from "~/@types";
import { z } from "zod";
import { baseCommentSchema } from "~/features/comment";
import { D } from "@mobily/ts-belt";
import { Comment } from "~/features/comment";
import { removeMask } from "~/utils";

export type FetchListProductParams = {
  category: string;
  myProduct?: boolean;
} & BaseQuery;

export const fetchListProduct = async (q: FetchListProductParams) => {
  const res = await defaultServices.getList<Product>({
    resource: "/product",
    query: {
      ...q,
    },
  });

  return res;
};

export const fetchOneProduct = async (productId: string) => {
  const res = await defaultServices.getOne<DetailProduct>({
    resource: "/product",
    id: productId,
  });

  return res;
};

export const productSchema = z.object({
  name: z.string().min(1, {
    message: "Nama product tidak boleh kosong",
  }),
  image: z.string().min(1, {
    message: "Gambar tidak boleh kosong.",
  }),
  category: z.string().min(1, {
    message: "Kategori tidak boleh kosong",
  }),
  price: z
    .string()
    .min(1, {
      message: "Harga tidak boleh kosong",
    })
    .transform((v) => Number(removeMask(v))),
  origin: z.string().min(1, {
    message: "Wilayah tidak boleh kosong",
  }),
  spec: z.string().min(1, {
    message: "Spesifikasi tidak boleh kosong",
  }),
  description: z.string().min(1, {
    message: "Deskripsi tidak boleh kosong",
  }),
});

export type ProductSchema = z.input<typeof productSchema>;

export const createProduct = async (body: ProductSchema) => {
  const res = await defaultServices.create({
    resource: "/product",
    variables: body,
  });

  return res;
};

export const updateProduct = async (body: ProductSchema, id: string) => {
  const res = await defaultServices.update({
    resource: `/product/${id}`,
    variables: body,
  });

  return res;
};

export const deleteProduct = async (id: string) => {
  const res = await defaultServices.delete({
    resource: "/product",
    id,
  });

  return res;
};

export const commentSchema = baseCommentSchema
  .merge(
    z.object({
      product: z.string(),
    }),
  )
  .transform((v) => D.filter(v, Boolean));

export type CommentSchema = z.input<typeof commentSchema>;

export const comment = {
  getList: async (q: BaseQuery, productId: string) => {
    const res = await defaultServices.getList<Comment>({
      resource: `/product/${productId}/comment`,
      query: { ...q },
    });

    return res;
  },

  create: async (body: Partial<CommentSchema>) => {
    const res = await defaultServices.create({
      resource: "/product/comment",
      variables: body,
    });

    return res;
  },
  update: async (body: CommentSchema, commentId: string) => {
    const res = await defaultServices.update({
      resource: `/product/comment/${commentId}`,
      variables: body,
    });

    return res;
  },
  delete: async (commentId: string) => {
    const res = await defaultServices.delete({
      resource: "/product/comment",
      id: commentId,
    });

    return res;
  },
};
