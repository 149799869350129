import { IconChevronDown, IconSearch, IconSquareRoundedPlusFilled } from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import { getRouteApi } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { css } from "styled-system/css";
import { Box, Center, Container, Flex, HStack, Stack } from "styled-system/jsx";
import { useDebouncedCallback } from "use-debounce";
import { Button, EmptyState, Icon, InfiniteScroller, Input, Menu, Pagination } from "~/components";
import { CardProduct, type Product, getListProduct } from "~/features/product";
import { Delete } from "../dialog";
import { useSuspenseQuery } from "@tanstack/react-query";
import { A, D } from "@mobily/ts-belt";
import { useCategory } from "~/hooks";
import { Skeleton } from "~/components/ui/Skeleton";
import { useDeleteProduct } from "../../hooks";

export const List = () => {
  const getRoute = getRouteApi("/_layout/my-product/");
  const { category } = useCategory("/product-category", "", true);
  const { mutationDelete, open, onToggle } = useDeleteProduct();

  const transformCategory = useMemo(() => {
    if (category.data?.list) {
      return [
        {
          label: "Semua Kategori",
          value: "",
        },
      ].concat(category.data?.list);
    }
  }, [category.data?.list]);

  const params = getRoute.useSearch();
  const [selectedCategory, setSelectedCategory] = useState(params.category);

  const products = useSuspenseQuery(
    getListProduct({
      ...D.deleteKey(params, "limit"),
      myProduct: true,
    }),
  );

  const navigate = useNavigate({
    from: "/my-product",
  });

  const [datum, setDatum] = useState<Product>({} as Product);
  const [search, setSearch] = useState(params.keyword);

  const debounced = useDebouncedCallback((value) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          keyword: value,
        };
      },
      replace: true,
    });
    setSearch(value);
  }, 500);

  const handleCategory = (v: string) => {
    setSelectedCategory(v);
    navigate({
      search: (prev) => {
        return {
          ...prev,
          page: 1,
          category: v,
        };
      },
      replace: true,
    });
  };

  return (
    <Container my={10} maxW="7xl">
      <h1
        className={css({
          textStyle: "h6",
          color: "disabled",
          fontWeight: "bold",
          mb: "16px",
        })}
      >
        Produk Saya
      </h1>
      <Flex flexDir="column" gap="40px">
        <Flex
          justifyContent="space-between"
          gap="36px"
          alignItems={{
            base: "start",
            md: "center",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
        >
          <Stack
            flexDir={{
              base: "column",
              md: "row",
            }}
            gap="16px"
            width="full"
          >
            <Menu.Root>
              <Menu.Trigger asChild>
                <HStack cursor="pointer">
                  <p
                    className={css({
                      textStyle: "sm",
                      color: "secondary",
                    })}
                  >
                    {category.data?.list?.find((v) => v.value === selectedCategory)?.label ?? "Semua Kategori"}
                  </p>
                  <Icon color="disabled">
                    <IconChevronDown />
                  </Icon>
                </HStack>
              </Menu.Trigger>
              <Menu.Positioner>
                <Menu.Content maxH="275px" rounded="16px" overflowY="scroll" h="full">
                  <InfiniteScroller
                    fetchNextPage={category.fetchNextPage}
                    hasNextPage={category.hasNextPage}
                    isLoading={category.isFetching}
                    loadingMessage={
                      <Flex flexDir="column" gap="16px" my="4" px="16px" width="full">
                        {A.makeWithIndex(5, (i) => (
                          <Skeleton key={i}>
                            <p>loading...</p>
                          </Skeleton>
                        ))}
                      </Flex>
                    }
                  >
                    {transformCategory?.map((v) => (
                      <Menu.Item
                        id={v.value}
                        textStyle="sm"
                        color="secondary.darken8"
                        data-selected={v.value === selectedCategory}
                        onClick={() => handleCategory(v.value)}
                        className={css({
                          "&[data-selected=true]": {
                            color: "primary",
                          },
                        })}
                      >
                        {v.label}
                      </Menu.Item>
                    ))}
                  </InfiniteScroller>
                </Menu.Content>
              </Menu.Positioner>
            </Menu.Root>
            <Box position="relative" role="group" tabIndex={1} flex={1}>
              <Icon
                pos="absolute"
                height="54px"
                display="flex"
                flexDir="column"
                alignItems="center"
                aria-disabled={!search}
                left={4}
                className={css({
                  color: "primary",
                  "&[aria-disabled=true]": {
                    color: "disabled.text",
                  },
                })}
                _groupFocus={{
                  color: "primary!",
                }}
              >
                <IconSearch />
              </Icon>
              <Input
                borderRadius="12"
                size="md"
                pl="40px"
                minW="340px"
                placeholder="Cari produk"
                defaultValue={search}
                onChange={(e) => debounced(e.target.value)}
              />
            </Box>
          </Stack>
          <Link to="/my-product/mutation">
            <Button>
              <Icon>
                <IconSquareRoundedPlusFilled />
              </Icon>
              Buat Produk
            </Button>
          </Link>
        </Flex>
        {products.data.data.list.length === 0 ? (
          <EmptyState
            title="Produk tidak ditemukan"
            description="Maaf, produk yang kamu cari tidak dapat ditemukan, mohon menggunakan keyword yang lain."
          />
        ) : (
          <Box>
            <Box
              display="grid"
              gap="16px"
              gridTemplateColumns={{
                base: "1",
                sm: "3",
                md: "4",
                lg: "5",
              }}
            >
              {products.data.data.list.map((v, idx) => (
                <CardProduct
                  datum={v}
                  key={idx}
                  borderRadius="8px"
                  gridTemplate="1"
                  withAction
                  onHandleEdit={(e) => {
                    e.preventDefault();
                    navigate({
                      to: "/my-product/mutation",
                      search: {
                        productId: v.id,
                      },
                    });
                  }}
                  onHandleDelete={(e) => {
                    e.preventDefault();
                    onToggle();
                    setDatum(v);
                  }}
                />
              ))}
            </Box>
            <Center mt="40px">
              <Pagination
                count={products.data.data.paging.count}
                pageSize={products.data.data.paging.limit}
                siblingCount={0}
                page={products.data.data.paging.page}
                onPageChange={(v) => {
                  navigate({
                    search: (prev) => {
                      return {
                        ...prev,
                        page: v.page,
                      };
                    },
                  });
                }}
              />
            </Center>
          </Box>
        )}
      </Flex>
      <Delete
        open={open}
        onOpenChange={(v) => onToggle(v.open)}
        isLoading={mutationDelete.isPending}
        name={datum.name}
        onHandleDelete={() => {
          mutationDelete.mutate(datum.id);
        }}
      />
    </Container>
  );
};
