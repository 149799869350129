import React, { useState } from "react";
import { FieldErrors, type FieldValues, type UseControllerProps } from "react-hook-form";

import { css } from "styled-system/css";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { Input as StyledInput, type StyledInputProps, Icon, type FormLabelProps } from "~/components/ui";
import { Wrapper } from "./Wrapper";

type InputProps<T extends FieldValues> = {
  error: FieldErrors<T>;
  label?: string;
  inputProps?: StyledInputProps;
  labelProps?: FormLabelProps;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
} & UseControllerProps<T>;

export const ControlledInput = <T extends FieldValues>({
  error,
  control,
  label,
  name,
  inputProps,
  labelProps,
  leftIcon,
  rightIcon,
  ...rest
}: InputProps<T>) => {
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <Wrapper control={control} error={error} labelProps={labelProps} label={label} name={name} {...rest}>
      {(name, onChange, value, disabled, ref) => (
        <>
          {React.isValidElement(leftIcon) && leftIcon}

          <StyledInput
            ref={ref}
            value={value}
            id={name}
            onChange={onChange}
            disabled={disabled}
            data-invalid={error[name] && true}
            {...inputProps}
            type={inputProps?.type !== "password" ? inputProps?.type : viewPassword ? "text" : "password"}
          />
          {inputProps?.type === "password" ? (
            <Icon
              pos="absolute"
              right="16px"
              size="lg"
              data-invalid={error[name] && true}
              onClick={() => setViewPassword(!viewPassword)}
              className={css({
                "&[data-invalid=true]": {
                  color: "error",
                },
              })}
              cursor="pointer"
            >
              {viewPassword ? <IconEye /> : <IconEyeOff />}
            </Icon>
          ) : (
            React.isValidElement(rightIcon) && rightIcon
          )}
        </>
      )}
    </Wrapper>
  );
};
