import { Box, Container, Flex, HStack } from "styled-system/jsx";
import { CardDetail } from "~/features/event";
import { BreadCrumb, Card, Icon } from "~/components";
import { getRouteApi } from "@tanstack/react-router";
import { IconLink } from "@tabler/icons-react";
import { css } from "styled-system/css";
import { getId, isYoutubeUrl } from "~/utils";

export const Detail = () => {
  const getRoute = getRouteApi("/_layout/meeting/$meetingId");
  const result = getRoute.useLoaderData();

  const breadcrumbs = [
    {
      path: "Meeting Room",
      to: `/meeting`,
      disabled: false,
    },
    {
      path: "Detail",
      to: "#",
      disabled: true,
    },
  ];
  return (
    <Box bgColor="secondary.lighten1">
      <Container maxW="4xl" pt={10} pb={20}>
        <Box mb={10}>
          <BreadCrumb items={breadcrumbs} />
        </Box>

        <Flex flexDir="column" gap={6}>
          <CardDetail
            headerImage="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1710295843389_UI User Website (6).svg"
            datum={result.data}
          />
          <Card.Root borderColor="secondary.lighten2" borderWidth="1" rounded="16px">
            <Card.Header p="24px" pb={0}>
              <Card.Title textStyle="lg" color="disabled" fontWeight="semibold">
                Link Meeting Room
              </Card.Title>
            </Card.Header>
            <Card.Body p="24px" gap={4} asChild>
              <HStack gap="6px">
                <Icon color="secondary" size="md">
                  <IconLink />
                </Icon>
                <a
                  href={result.data.link}
                  target="_blank"
                  className={css({
                    textStyle: "sm",
                    fontWeight: "medium",
                    color: "primary",
                  })}
                >
                  {result.data.link}
                </a>
              </HStack>
            </Card.Body>
          </Card.Root>
          {result.data.video ? (
            <Card.Root borderColor="secondary.lighten2" borderWidth="1" rounded="16px">
              <Card.Title>
                <Card.Header textStyle="lg" color="disabled" fontWeight="semibold" fontFamily="outfit!">
                  Video
                </Card.Header>
              </Card.Title>
              <Card.Body p="24px" asChild>
                {isYoutubeUrl(result.data.video) ? (
                  <iframe
                    width="100%"
                    height="512"
                    allowFullScreen
                    src={`https://www.youtube.com/embed/${getId(result.data.video)}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    className={css({
                      rounded: "16px",
                      border: "0px",
                      minH: "512px",
                    })}
                  ></iframe>
                ) : (
                  <video
                    width="100%"
                    height="512"
                    controls
                    className={css({
                      rounded: "16px",
                      minH: "512px",
                    })}
                  >
                    <source src={result.data.video!} type="video/ogg" />
                    <source src={result.data.video!} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </Card.Body>
            </Card.Root>
          ) : null}
        </Flex>
      </Container>
    </Box>
  );
};
