/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute } from "@tanstack/react-router";
import { Detail, getOneEvent } from "~/features/event";

import { ErrorElement } from "~/components/ErrorElement";
import { SEO } from "~/components";

export const Route = createFileRoute("/_layout/event/$eventId")({
  loader: async ({ context: { queryClient }, params: { eventId } }) =>
    queryClient.ensureQueryData(getOneEvent(eventId)),
  component: () => {
    const data = Route.useLoaderData();
    return (
      <>
        <SEO
          description={data.data.description}
          title={data.data.title}
          url={`${window.location.hostname}/event/${data.data.id}`}
        />
        <Detail />
      </>
    );
  },
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  wrapInSuspense: true,
});
