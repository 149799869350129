import { zodResolver } from "@hookform/resolvers/zod";
import { D } from "@mobily/ts-belt";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { HttpError } from "~/@types";
import { IMAGE, VIDEO_MIME_TYPES, VideoMimeTypes } from "~/constant";
import { useToast } from "~/context/Toast";
import { TypeFile } from "~/features/comment";
import { useUploadMedia } from "~/hooks";
import { defaultServices } from "~/services";
import { getExtension, isYoutubeUrl } from "~/utils";

const forumSchema = z
  .object({
    description: z.string().min(1, {
      message: "Deskripsi tidak boleh kosong",
    }),
    image: z.string().optional(),
    video: z.string().optional(),
    category: z.string().min(1, {
      message: "Kategori tidak boleh kosong",
    }),
  })
  .transform((v) => D.filter(v, Boolean));

type ForumSchema = z.input<typeof forumSchema>;

const DEFAULT_VALUES = {
  category: "",
  description: "",
  image: "",
  video: "",
};

const getRoute = getRouteApi("/_layout/forum/");

export const useCreateForum = () => {
  const search = getRoute.useSearch();
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<TypeFile>("image");
  const [embedYoutube, setEmbedYoutube] = useState("");
  const [valueEmbedYoutube, setValueEmbetYoutube] = useState("");
  const [error, setErorr] = useState(false);
  const [errorFile, setErorrFile] = useState("");
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ForumSchema>({
    resolver: zodResolver(forumSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const imagesExtension = IMAGE.map((v) => v.extension);

  const handleEmbedLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueEmbetYoutube(e.target.value);
    if (isYoutubeUrl(e.target.value)) {
      setErorr(false);
    } else {
      setErorr(true);
    }
  };

  const {
    handleChange,
    inputFileRef,
    mutation: mutationMedia,
    progress,
  } = useUploadMedia({
    onSuccess: (data) => {
      if (inputFileRef.current) {
        inputFileRef.current.value = "";
      }

      if (imagesExtension.includes(getExtension(data.data.url))) {
        setValue("image", data.data.url);
      } else {
        setValue("video", data.data.url);
      }
    },
  });

  const resetImage = () => {
    if (mutationMedia.data?.data.url) {
      if (imagesExtension.includes(getExtension(mutationMedia.data.data.url))) {
        setValue("image", "");
      } else {
        setValue("video", "");
      }
    }
    mutationMedia.reset();
  };

  const mutation = useMutation<unknown, HttpError, ForumSchema>({
    mutationFn: async (v) => {
      const removeFroalaWaterMark = v.description.replace(
        '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
        "",
      );
      const res = await defaultServices.create<Record<string, unknown>, ForumSchema>({
        resource: "/forum",
        variables: {
          ...v,
          description: removeFroalaWaterMark,
        },
      });

      return res;
    },
    onSuccess: () => {
      reset();
      setEmbedYoutube("");
      mutationMedia.reset();
      setIsOpen(false);
      queryClient.invalidateQueries({
        queryKey: [`forum_${search.category}`],
      });

      toast.success({
        title: "Postingan berhasil diunggah",
      });
    },
  });

  const toggleFileUpload =
    (type: TypeFile) =>
    <T>(e: React.MouseEvent<T>) => {
      e.preventDefault();
      setType(type);
      setTimeout(() => {
        if (inputFileRef.current) {
          inputFileRef.current.click();
        }
      }, 50);
    };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, (v) => {
      const fileSizeKb = v.size / 1024;
      const fileSizeMb = fileSizeKb / 1024;
      if (VIDEO_MIME_TYPES.includes(v.type as unknown as VideoMimeTypes) && fileSizeMb > 100) {
        setErorrFile("*Maksimal ukuran video 100 Mb");
        return false;
      } else {
        setErorrFile("");
        return true;
      }
    });
  };

  return {
    control,
    mutationMedia,
    resetImage,
    handleChange,
    handleFileChange,
    inputFileRef,
    onSubmit: handleSubmit((v) => mutation.mutateAsync(v)),
    toggleFileUpload,
    progress,
    type,
    isOpen,
    setValue,
    setIsOpen,
    mutation,
    errors,
    embedYoutube,
    valueEmbedYoutube,
    errorFile,
    error,
    handleEmbedLink,
    setErorr,
    setType,
    setEmbedYoutube,
    setValueEmbetYoutube,
  };
};

type OptsParams = Omit<UseMutationOptions<unknown, HttpError, unknown>, "mutationFn">;

export const useDeleteForum = (opts?: OptsParams) => {
  const [open, setOpen] = useState(false);

  const mutationDelete = useMutation<unknown, HttpError, string>({
    ...opts,
    mutationFn: async (id) => {
      const res = await defaultServices.delete({
        resource: "/forum",
        id,
      });

      return res;
    },
  });

  return {
    mutationDelete,
    setOpen,
    open,
  };
};

export const useLikeForum = ({ id, isLiked }: { id: string; isLiked: boolean }, opts?: OptsParams) => {
  const mutationLike = useMutation<unknown, HttpError>({
    ...opts,
    mutationFn: async () => {
      const res = await defaultServices.update({
        resource: `/forum/${id}/${isLiked ? "dislike" : "like"}`,
        variables: {},
      });

      return res;
    },
  });

  return mutationLike;
};
