import React from "react";
import { Card, Icon, Image } from "~/components";
import { Box, Flex, HStack } from "styled-system/jsx";
import { IconCalendarEvent, IconClockHour3 } from "@tabler/icons-react";
import { css } from "styled-system/css";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { Event } from "~/features/event/@types";
import { Status } from "../badge";

interface CardEventProps extends Card.RootProps {
  datum: Event;
  to: string;
  params: Record<string, any>;
  image: string;
}

export const CardEvent: React.FC<CardEventProps> = ({ datum, to, params, image, ...rest }) => {
  return (
    <Link to={to} params={params}>
      <Card.Root
        {...rest}
        borderColor="secondary.lighten2"
        borderWidth="1"
        rounded="16px"
        minH="300px"
        h="full"
        boxShadow="unset"
      >
        <Card.Header p={0}>
          <Card.Description asChild>
            <Box position="relative">
              <Image
                src={image}
                imageStyle={{
                  width: "full",
                }}
              />
              <Status status={datum.status} position="absolute" left="8px" bottom="8px" />
            </Box>
          </Card.Description>
        </Card.Header>
        <Card.Body asChild p="16px">
          <Flex flexDir="column" gap="8px">
            <h3
              className={css({
                textStyle: "lg",
                color: "secondary.darken8",
                fontWeight: "medium",
                lineClamp: 2,
              })}
            >
              {datum.title}
            </h3>
            <p
              className={css({
                textStyle: "sm",
                color: "secondary",
                lineClamp: 2,
              })}
            >
              {datum.description}
            </p>
          </Flex>
        </Card.Body>
        <Card.Footer bgColor="secondary.lighten1" p="16px" asChild>
          <Flex gap="16px">
            <HStack gap="4px">
              <Icon size="sm">
                <IconCalendarEvent />
              </Icon>
              <p
                className={css({
                  textStyle: "xs",
                  color: "secondary",
                  fontWeight: "medium",
                })}
              >
                {dayjs(datum.refDate).format("DD MMMM YYYY")}
              </p>
            </HStack>
            <HStack gap="4px">
              <Icon size="sm">
                <IconClockHour3 />
              </Icon>
              <p
                className={css({
                  textStyle: "xs",
                  color: "secondary",
                  fontWeight: "medium",
                })}
              >
                {dayjs(datum.refDate).format("HH.mm")} WIB
              </p>
            </HStack>
          </Flex>
        </Card.Footer>
      </Card.Root>
    </Link>
  );
};
