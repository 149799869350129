import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { ParamsFetchListForum, fetchDetailForum, fetchListForum } from "../services";

export const getListForumInfiniteQuery = (q: ParamsFetchListForum) =>
  infiniteQueryOptions({
    queryKey: [`forum_${q.category}_${q.createdBy}_${q.userCategory}`],
    queryFn: async ({ pageParam }) => {
      const res = await fetchListForum({
        ...q,
        page: pageParam,
      });

      return res;
    },
    select: (data) => {
      return data.pages.flatMap((v) => v.data.list);
    },
    initialPageParam: q.page,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
    staleTime: 50_000,
  });

export const getDetialForum = (forumId: string) =>
  queryOptions({
    queryKey: [`detail-forum-${forumId}`],
    queryFn: () => fetchDetailForum(forumId),
    staleTime: Infinity,
    enabled: !!forumId,
  });
