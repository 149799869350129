import { Link } from "@tanstack/react-router";
import type React from "react";
import { Fragment } from "react";
import { css } from "styled-system/css";
import { HStack, type HstackProps } from "styled-system/jsx";
import type { IBreadCrumb } from "~/@types";

interface BreadCrumbProps extends HstackProps {
  items: IBreadCrumb[];
}

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ items, ...rest }) => {
  return (
    <HStack gap="8px" {...rest} flexWrap="wrap">
      {items.map((v, idx) => (
        <Fragment key={v.path}>
          <Link
            to={v.to}
            data-disabled={v.disabled}
            className={css({
              textStyle: "small",
              color: "primary",
              "&[data-disabled=true]": {
                color: "disabled",
                pointerEvents: "none",
              },
            })}
          >
            {v.path}
          </Link>
          {idx !== items.length - 1 && (
            <p
              className={css({
                textStyle: "sm",
                color: "secondary",
              })}
            >
              /
            </p>
          )}
        </Fragment>
      ))}
    </HStack>
  );
};
