import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { BaseCommentSchema } from "~/features/comment";
import { comment, commentSchema } from "../services";
import { useState } from "react";
import { useToast } from "~/context/Toast";

export const useGetComment = (id: string, enabled?: boolean) => {
  const result = useInfiniteQuery({
    queryKey: [`comment_forum_${id}`],
    queryFn: async ({ pageParam }) => {
      const q = {
        page: pageParam,
        limit: 2,
      };

      const res = await comment.getList(q, id);
      return res;
    },

    select: (data) => {
      return {
        list: data.pages.flatMap((v) => v.data.list),
        paging: data.pages[0].data.paging,
      };
    },
    staleTime: 5_000,
    enabled: enabled,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });

  return { result };
};

export const useMutationComment = (id: string) => {
  const queryClient = useQueryClient();
  const [commentId, setCommentId] = useState("");
  const { toast } = useToast();

  const mutation = useMutation<Record<string, any>, AxiosError, BaseCommentSchema>({
    mutationFn: (v) =>
      commentId
        ? comment.update(
            {
              ...v,
              forum: id,
            },
            commentId,
          )
        : comment.create(
            commentSchema.parse({
              ...v,
              forum: id,
            }),
          ),
    onSuccess: () => {
      if (commentId) {
        toast.success({
          title: "Berhasil update komentar",
        });
      }
      queryClient.invalidateQueries({
        queryKey: [`comment_forum_${id}`],
      });
    },
  });

  const mutationDelete = useMutation<Record<string, any>, AxiosError, string>({
    mutationFn: (v) => comment.delete(v),
    onSuccess: () => {
      toast.success({
        title: "Berhasil hapus komentar",
      });
      queryClient.invalidateQueries({
        queryKey: [`comment_forum_${id}`],
      });
    },
  });

  return {
    mutation,
    setCommentId,
    mutationDelete,
  };
};
