import { createFileRoute, redirect } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";

import { List, getListMeeting } from "~/features/meeting";

import { z } from "zod";

const meetingSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(9),
  keyword: z.string().catch(""),
});

type MeetingSearch = z.infer<typeof meetingSearchSchema>;

export const Route = createFileRoute("/_layout/meeting/")({
  beforeLoad: ({ context: { queryClient } }) => {
    const isLogin = queryClient.getQueryData(["isLogin"]);

    if (!isLogin) {
      throw redirect({
        to: "/",
      });
    }
  },
  validateSearch: (search: Record<string, unknown>): MeetingSearch => meetingSearchSchema.parse(search),
  loaderDeps: ({ search: { keyword, limit, page } }) => ({ keyword, limit, page }),
  loader: ({ context: { queryClient }, deps: { page } }) =>
    queryClient.ensureQueryData(
      getListMeeting({
        page,
        limit: 9,
      }),
    ),
  gcTime: 0,
  shouldReload: false,
  component: () => (
    <>
      <Helmet>
        <title>Meeting - Ecobiz</title>
      </Helmet>
      <List />
    </>
  ),
});
