import React from "react";
import { Circle, Flex, HStack, Stack } from "styled-system/jsx";
import { Card } from "~/components";
import { Skeleton } from "~/components/ui/Skeleton";

export const SkeletonExplore = () => {
  return (
    <Card.Root>
      <Card.Header>
        <Skeleton h="135px" />
      </Card.Header>
      <Card.Body>
        <Skeleton h="21px" />
        <Flex gap="4px" w="full" mt={2}>
          <Skeleton w="77px" h="12px" />
          <Skeleton w="77px" h="12px" />
        </Flex>
      </Card.Body>
    </Card.Root>
  );
};
