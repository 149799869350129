import React from "react";
import { Grid } from "styled-system/jsx";
import { CardProduct, Product as IProduct } from "~/features/product";
import { Wrapper } from "../shared/Wrapper";
import { EmptyState } from "./EmptyState";

interface ProductProps {
  datum: IProduct[];
}

export const Product: React.FC<ProductProps> = ({ datum }) => {
  return (
    <Wrapper title="Produk" subTitle="Beragam produk terbaik pilihan ecobiz" to="/product">
      {datum.length === 0 ? (
        <EmptyState />
      ) : (
        <Grid
          gridTemplateColumns={{
            sm: 2,
            md: 4,
            lg: 6,
          }}
          mt={6}
        >
          {datum.map((v) => (
            <CardProduct datum={v} key={v._id} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
};
