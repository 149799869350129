import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Footer, Navbar } from "~/components";
import { ErrorElement } from "~/components/ErrorElement";

export const Route = createFileRoute("/_layout")({
  component: () => (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  ),
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
});
