import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { useQueryClient } from "@tanstack/react-query";

export const useFirebase = () => {
  const queryClient = useQueryClient();

  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  };

  const firebaseInitialize = () => {
    const app = initializeApp(firebaseConfig);
    getMessaging(app);
  };

  const firebaseGetToken = () => {
    const messaging = getMessaging();

    getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          queryClient.fetchQuery({
            queryKey: ["fcmToken"],
            queryFn: () => Promise.resolve(currentToken),
          });
        } else {
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, () => {
      queryClient.invalidateQueries({
        queryKey: ["notification"],
      });
      queryClient.invalidateQueries({
        queryKey: ["notification-count", { read: false }],
      });
    });
  };

  return {
    firebaseInitialize,
    firebaseGetToken,
  };
};
