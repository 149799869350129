import { BaseQuery } from "~/@types";
import { fetchModule } from "../service";
import { keepPreviousData, queryOptions } from "@tanstack/react-query";

export const moduleGetList = (q: BaseQuery) =>
  queryOptions({
    queryKey: ["module", { ...q }],
    queryFn: () => fetchModule(q),
    staleTime: 50_000,
    placeholderData: keepPreviousData,
  });
