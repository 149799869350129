import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { css } from "styled-system/css";
import { Box, Container, VStack } from "styled-system/jsx";

import { MENU_FOOTER } from "~/constant";
import { Image } from "..";

export const Footer = () => {
  return (
    <Box pt="20" pb="6" borderTopColor="secondary.lighten2" borderTopWidth="1px" borderTopStyle="solid">
      <Container maxW="7xl">
        <Box
          gridTemplateColumns={{
            base: "1",
            sm: "2",
            lg: "4",
          }}
          gap="16px"
          display="grid"
        >
          <VStack
            gridColumn={{
              base: "1",
              lg: "1 / 3",
            }}
            alignItems="start"
            gap={{
              base: "20px",
              lg: "40px",
            }}
          >
            <Box>
              <Image
                src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709537934285_UI (4).svg"
                alt="logo"
                width={140.92}
                height="100%"
                loading="lazy"
              />
              <p
                className={css({
                  textStyle: "lg",
                  mt: "8px",
                  fontWeight: "bold",
                  fontFamily: "outfit",
                  color: "secondary",
                })}
              >
                Ecobiz KUKM Jawa Barat
              </p>
            </Box>
            <VStack alignItems="start" justifyContent="space-around" gap="10">
              <Box>
                <p
                  className={css({
                    textStyle: "md",
                    fontFamily: "outfit",
                    fontWeight: "semibold",
                    color: "secondary.darken8",
                  })}
                >
                  Gedung SBM ITB
                </p>
                <p
                  className={css({
                    textStyle: "md",
                    color: "secondary",
                    maxW: "32ch",
                  })}
                >
                  Jl. Ganesa No.10, Lb. Siliwangi, Kecamatan Coblong, Kota Bandung, Jawa Barat 40132
                </p>
              </Box>
            </VStack>
          </VStack>
          <Box gridColumn="1">
            <h4
              className={css({
                textStyle: "md",
                fontWeight: "semibold",
                color: "secondary.darken8",
              })}
            >
              Situs
            </h4>
            <VStack alignItems="start" mt="16px" gap="12px">
              {MENU_FOOTER.sites.map((v, idx) => (
                <Link
                  key={idx}
                  to={v.to}
                  className={css({
                    textStyle: "md",
                    color: "secondary",
                  })}
                >
                  {v.label}
                </Link>
              ))}
            </VStack>
          </Box>
          <Box gridColumn="1">
            <h4
              className={css({
                textStyle: "md",
                fontWeight: "semibold",
                color: "secondary.darken8",
              })}
            >
              Mitra Utama
            </h4>
            <VStack alignItems="start" mt="16px" gap="12px">
              {MENU_FOOTER.mitra.map((v, idx) => (
                <p
                  key={idx}
                  className={css({
                    textStyle: "md",
                    color: "secondary",
                  })}
                >
                  {v.label}
                </p>
              ))}
            </VStack>
          </Box>
        </Box>
        <p
          className={css({
            textStyle: "sm",
            fontWeight: "medium",
            color: "disabled",
            mt: 2,
          })}
        >
          &copy;{dayjs().format("YYYY")} Ecobizitb All Rights Reserved
        </p>
      </Container>
    </Box>
  );
};
