import { createFileRoute } from "@tanstack/react-router";
import { getSearch } from "~/features/explore";
import { Explore } from "~/features/explore/pages";

type LoginSearchSchema = {
  keyword?: string;
};

export const Route = createFileRoute("/explore/")({
  validateSearch: (search: Record<string, unknown>): LoginSearchSchema => {
    return {
      keyword: (search.keyword as string) ?? "",
    };
  },
  loaderDeps: ({ search: { keyword } }) => ({ keyword }),
  loader: ({ context: { queryClient }, deps: { keyword } }) => {
    return queryClient.ensureQueryData(
      getSearch({
        keyword,
      }),
    );
  },
  component: () => <Explore />,
});
