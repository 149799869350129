import React, { useState } from "react";
import { NavbarExplore } from "../components/nav";
import { Container, Flex, Grid } from "styled-system/jsx";
import { Event, Forum, Module, Product, Tips, Community, News } from "../components";
import { useQuery } from "@tanstack/react-query";
import { getSearch } from "../query-options";
import { getRouteApi } from "@tanstack/react-router";
import { SkeletonExplore } from "../components/content/Skeleton";
import { A } from "@mobily/ts-belt";
import { Badge } from "~/components";
import { css } from "styled-system/css";
import { match } from "ts-pattern";
import { EmptyState } from "../components/content";

const getRoute = getRouteApi("/explore/");

const filters = [
  {
    key: "",
    label: "Semua Pencarian",
  },
  {
    key: "forum",
    label: "Forum",
  },
  {
    key: "event",
    label: "Event",
  },
  {
    key: "produk",
    label: "Produk",
  },
  {
    key: "tips",
    label: "Kiat - Kiat",
  },
  {
    key: "news",
    label: "Berita",
  },
  {
    key: "community",
    label: "Komunitas",
  },
  {
    key: "lesson",
    label: "Module",
  },
] as const;

type FilterKey = (typeof filters)[number]["key"];

export const Explore = () => {
  const params = getRoute.useSearch();
  const [filter, setFitler] = useState<FilterKey>("");

  const result = useQuery(
    getSearch({
      keyword: params.keyword,
    }),
  );

  const PartialRender = match(filter)
    .with("community", () => <Community datum={result?.data!.data.community} />)
    .with("event", () => <Event datum={result?.data!.data.event} />)
    .with("forum", () => <Forum datum={result?.data!.data.forum} />)
    .with("news", () => <News datum={result?.data!.data.news} />)
    .with("produk", () => <Product datum={result?.data!.data.product} />)
    .with("tips", () => <Tips datum={result?.data!.data.tips} />)
    .with("lesson", () => <Module datum={result?.data!.data.lesson} />)
    .otherwise(() => (
      <>
        <Forum datum={result?.data!.data.forum} />
        <Event datum={result?.data!.data.event} />
        <Product datum={result?.data!.data.product} />
        <Module datum={result?.data!.data.lesson} />
        <Tips datum={result?.data!.data.tips} />
        <News datum={result?.data!.data.news} />
        <Community datum={result?.data!.data.community} />
      </>
    ));

  return (
    <>
      <NavbarExplore />
      <Container maxW="7xl" py="8">
        <Flex flexWrap="wrap" gap={4} mb={8}>
          {filters.map((v) => (
            <Badge
              height="40px"
              px="24px"
              py="8px"
              cursor="pointer"
              colorPalette="secondary"
              bgColor="colorPalette.lighten1"
              minW="fit-content"
              borderColor="colorPalette.lighten2"
              borderRadius="8px"
              textStyle="md"
              color="colorPalette"
              onClick={() => setFitler(v.key)}
              data-state={filter === v.key ? "checked" : "unchecked"}
              key={v.key}
              className={css({
                "&[data-state=checked]": {
                  colorPalette: "primary",
                  borderColor: "colorPalette",
                  backgroundColor: "green.lighten1",
                },
              })}
            >
              {v.label}
            </Badge>
          ))}
        </Flex>

        {result.isFetching ? (
          <Grid
            gridTemplateColumns={{
              sm: 2,
              md: 3,
            }}
            gap="6"
          >
            {A.makeWithIndex(12, (i) => (
              <SkeletonExplore key={i} />
            ))}
          </Grid>
        ) : (
          <Flex flexDirection="column" gap={10} w="full">
            {PartialRender}
          </Flex>
        )}
      </Container>
    </>
  );
};
