import { IconMail } from "@tabler/icons-react";
import React from "react";
import { css } from "styled-system/css";
import { Center, Flex } from "styled-system/jsx";
import { Button, Icon } from "~/components";
import * as Dialog from "~/components/ui/Dialog";

interface Confirmation extends Dialog.RootProps {
  email?: string;
  type: "expired" | "default";
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  errorMessage?: string;
}

export const Confirmation: React.FC<Confirmation> = ({ type, email, buttonProps, errorMessage, ...rest }) => {
  return (
    <Dialog.Root {...rest}>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <Dialog.Content maxW="552px" p="24px" rounded="24px" w="full" minW="0">
          <Flex flexDir="column" gap="16px">
            <Center>
              <Icon
                data-state={type}
                className={css({
                  "&[data-state=default]": {
                    color: "primary",
                  },
                  "&[data-state=expired]": {
                    color: "red.darken5",
                  },
                })}
                size="2xl"
              >
                <IconMail />
              </Icon>
            </Center>
            <Center flexDir="column">
              <h3
                className={css({
                  textStyle: "h6",
                  mb: "8px",
                  color: "secondary.darken8",
                  fontWeight: "bold",
                })}
              >
                {type === "default" ? " Tautan Verifikasi Terkirim" : errorMessage}
              </h3>
              {type === "default" ? (
                <p
                  className={css({
                    textStyle: "md",
                    color: " secondary",
                    textAlign: "center",
                  })}
                >
                  Tautan verifikasi untuk atur ulang kata sandi telah dikirim ke email{" "}
                  <span
                    className={css({
                      fontWeight: "medium",
                    })}
                  >
                    {email}
                  </span>
                  . Mohon cek kotak masuk/spam pada email Anda.
                </p>
              ) : (
                <p
                  className={css({
                    textStyle: "md",
                    color: " secondary",
                    textAlign: "center",
                  })}
                >
                  Silakan lakukan proses atur ulang kata sandi kembali.
                </p>
              )}
            </Center>
            <Dialog.CloseTrigger asChild>
              <Button
                mt="8px"
                data-state={type}
                className={css({
                  "&[data-state=default]": {
                    colorPalette: "primary",
                  },
                  "&[data-state=expired]": {
                    bgColor: "red.darken5",
                  },
                })}
                {...buttonProps}
              >
                {type === "default" ? "Mengerti" : "Tutup"}
              </Button>
            </Dialog.CloseTrigger>
          </Flex>
        </Dialog.Content>
      </Dialog.Positioner>
    </Dialog.Root>
  );
};
