import { useRef, useState } from "react";
import type { TypeFile } from "../@types";
import { useUploadMedia } from "~/hooks";
import { isYoutubeUrl } from "~/utils";

export const useEditor = () => {
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState("");
  const [type, setType] = useState<TypeFile>("image");
  const [embedYoutube, setEmbedYoutube] = useState("");
  const [valueEmbedYoutube, setValueEmbetYoutube] = useState("");
  const [error, setErorr] = useState(false);
  const [file, setFile] = useState<string | null | undefined>("");
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { mutation: mutationMedia, progress } = useUploadMedia({
    onSuccess: (v) => {
      setFile(v.data.url);
      if (inputFileRef.current) {
        inputFileRef.current.value = "";
      }
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleEmbedLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueEmbetYoutube(e.target.value);
    if (isYoutubeUrl(e.target.value)) {
      setErorr(false);
    } else {
      setErorr(true);
    }
  };

  const toggleFileUpload =
    (type: TypeFile) =>
    <T>(e: React.MouseEvent<T>) => {
      e.preventDefault();
      setType(type);
      setTimeout(() => {
        if (inputFileRef.current) {
          inputFileRef.current.click();
        }
      }, 50);
    };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      mutationMedia.mutate(e.target.files[0]);
    }
  };

  const resetEditor = () => {
    setValue("");
    mutationMedia.reset();
    setEmbedYoutube("");
    setFile("");
  };

  return {
    editorRef,
    inputFileRef,
    handleChange,
    value,
    type,
    toggleFileUpload,
    handleFile,
    progress,
    mutationMedia,
    setValue,
    file,
    setFile,
    setValueEmbetYoutube,
    valueEmbedYoutube,
    setEmbedYoutube,
    embedYoutube,
    handleEmbedLink,
    resetEditor,
    setType,
    error,
    setErorr,
  };
};
