import React from "react";
import { IconDownload, IconFileFilled } from "@tabler/icons-react";
import { css } from "styled-system/css";
import { Flex, HStack, VStack } from "styled-system/jsx";
import { Button, Icon } from "~/components";
import { Module } from "../../../@types";

type CardModuleProps = {
  datum: Module;
};

export const CardModule: React.FC<CardModuleProps> = ({ datum }) => {
  return (
    <>
      <Flex
        justifyContent="space-between"
        flexDir={{
          base: "column",
          md: "row",
        }}
        py="16px"
        borderBottomColor="secondary.lighten2"
        borderBottomWidth={1}
      >
        <HStack gap="16px" alignItems="center">
          <Icon size="2xl" color="disabled">
            <IconFileFilled />
          </Icon>
          <VStack alignItems="start" gap="0">
            <h3
              className={css({
                textStyle: "lg",
                color: "secondary.darken8",
                fontWeight: "semibold",
              })}
            >
              {datum.title}
            </h3>
          </VStack>
        </HStack>
        <Button disabled={!datum.file} asChild>
          <a href={datum.file} target="_blank">
            <Icon>
              <IconDownload />
            </Icon>
            Download Modul
          </a>
        </Button>
      </Flex>
    </>
  );
};
