import { css } from "styled-system/css";
import { Box, Container, Flex } from "styled-system/jsx";

import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { Link } from "@tanstack/react-router";
import { Image } from "..";

export const Navbar = () => {
  return (
    <nav
      className={css({
        position: "sticky",
        top: 0,
        zIndex: "10",
        width: "100%",
        backgroundColor: "white",
        borderBottomColor: "secondary.lighten2",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        py: "6",
      })}
    >
      <Container maxWidth="7xl">
        <Flex justifyContent="space-between" alignItems="center">
          <Link to="/">
            <Image
              src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709537934285_UI (4).svg"
              imageStyle={{
                width: "140.92px",
                height: "auto",
                objectFit: "contain",
              }}
              width="140.92px"
              height="100%"
              alt="logo"
            />
          </Link>
          <Box
            display={{
              base: "block",
              lg: "none",
            }}
          >
            <Mobile />
          </Box>

          <Desktop />
        </Flex>
      </Container>
    </nav>
  );
};
