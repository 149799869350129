import { createFileRoute } from "@tanstack/react-router";
import { getListEvent, List } from "~/features/event";

import { z } from "zod";
import { Helmet } from "react-helmet-async";
import { ErrorElement } from "~/components/ErrorElement";

const meetingSearchSchema = z.object({
  page: z.number().catch(1),
  limit: z.number().catch(9),
  keyword: z.string().catch(""),
  status: z.enum(["done", "pending", ""]).catch(""),
});

type MeetingSearch = z.infer<typeof meetingSearchSchema>;

export const Route = createFileRoute("/_layout/event/")({
  validateSearch: (search: Record<string, unknown>): MeetingSearch => meetingSearchSchema.parse(search),
  loaderDeps: ({ search: { keyword, limit, page, status } }) => ({ keyword, limit, page, status }),
  loader: ({ context: { queryClient }, deps: { page, status, keyword } }) =>
    queryClient.ensureQueryData(
      getListEvent({
        status,
        page,
        keyword,
      }),
    ),
  gcTime: 0,
  shouldReload: false,
  errorComponent: () => <ErrorElement statusCode="500" />,
  notFoundComponent: () => <ErrorElement statusCode="404" />,
  component: () => (
    <>
      <Helmet>
        <title>Event - Ecobiz</title>
      </Helmet>
      <List />
    </>
  ),
});
