import { queryOptions, infiniteQueryOptions } from "@tanstack/react-query";
import {
  type FetchListNotificationParams,
  type FetchCountNotificationParams,
  fetchCountNotification,
  fetchListNotification,
} from "../service";

export const getListNotificationInfiniteQuery = (q: FetchListNotificationParams) =>
  infiniteQueryOptions({
    queryKey: ["notification"],
    staleTime: Infinity,
    queryFn: async ({ pageParam }) => {
      const res = await fetchListNotification({
        ...q,
        page: pageParam,
      });

      return res;
    },
    initialPageParam: q.page,
    enabled: false,
    select: (data) => {
      return data.pages.map((v) => v.data.list).flat();
    },
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });

export const getCountNotification = (q: FetchCountNotificationParams) => {
  return queryOptions({
    queryKey: ["notification-count", { ...q }],
    queryFn: () => fetchCountNotification(q),
    staleTime: Infinity,
  });
};
