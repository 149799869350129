import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { comment, commentSchema } from "../service";
import { getRouteApi } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { BaseCommentSchema } from "~/features/comment";
import { useState } from "react";
import { useToast } from "~/context/Toast";
import { clearHtmlTags } from "~/utils";

export const useGetComment = () => {
  const getRoute = getRouteApi("/_layout/product/$productId");
  const { productId } = getRoute.useParams();
  const result = useInfiniteQuery({
    queryKey: [`comment_product`],
    queryFn: async ({ pageParam }) => {
      const q = {
        page: pageParam,
      };

      const res = await comment.getList(q, productId);
      return res;
    },

    select: (data) => {
      const normalizeData = data.pages.flatMap((page) =>
        page.data.list.map((v) => {
          const sanitizeHtml = clearHtmlTags(v.content);

          return {
            ...v,
            content: sanitizeHtml,
          };
        }),
      );
      return normalizeData;
    },

    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.data.paging.next ? lastPage.data.paging.page + 1 : undefined;
    },
  });

  return { result };
};
export const useMutationComment = () => {
  const queryClient = useQueryClient();
  const getRoute = getRouteApi("/_layout/product/$productId");
  const productId = getRoute.useParams().productId;
  const [commentId, setCommentId] = useState("");
  const { toast } = useToast();

  const mutation = useMutation<Record<string, any>, AxiosError, BaseCommentSchema>({
    mutationFn: (v) => {
      const body = commentSchema.parse({
        ...v,
        product: productId,
      });

      return commentId
        ? comment.update(
            {
              ...v,
              product: "",
            },
            commentId,
          )
        : comment.create(body);
    },
    onSuccess: () => {
      if (commentId) {
        toast.success({
          title: "Berhasil update komentar",
        });
      }
      queryClient.invalidateQueries({
        queryKey: ["comment_product"],
      });
    },
  });

  const mutationDelete = useMutation<Record<string, any>, AxiosError, string>({
    mutationFn: (v) => comment.delete(v),
    onSuccess: () => {
      toast.success({
        title: "Berhasil hapus komentar",
      });
      queryClient.invalidateQueries({
        queryKey: ["comment_product"],
      });
    },
  });

  return {
    mutation,
    setCommentId,
    mutationDelete,
  };
};
