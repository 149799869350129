import { getRouteApi } from "@tanstack/react-router";
import React from "react";
import { css } from "styled-system/css";
import { Flex } from "styled-system/jsx";
import { Button, Image } from "~/components";

const getRoute = getRouteApi("/explore/");

export const EmptyState = () => {
  const params = getRoute.useSearch();

  const onHandleClick = () => {
    window.open(`https://www.google.com/search?q=${params.keyword}`, "_blank");
  };

  return (
    <Flex flexDir="column" alignItems="center" gap={4}>
      <Image src="https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1720369545413_UI User Website popup.svg" />
      <p
        className={css({
          textStyle: "md",
          color: "secondary",
          textAlign: "center",
        })}
      >
        Hasil pencarian yang anda cari tidak ditemukan. Silahkan coba pencarian lain atau kunjungi google
      </p>
      <Button colorPalette="primary" onClick={onHandleClick}>
        Buka Google
      </Button>
    </Flex>
  );
};
