import { queryOptions } from "@tanstack/react-query";
import { BaseQuery } from "~/@types";

import { http } from "~/libs/axios";
import { Result } from "../@types";

export const getSearch = (q: Pick<BaseQuery, "keyword">) =>
  queryOptions({
    queryKey: ["explore", { ...q }],
    queryFn: async () => {
      const res = await http<{
        data: Result;
      }>("GET", {
        url: `search?keyword=${q.keyword}`,
      });

      return res;
    },
  });
