import { IconLogin, IconMenuDeep, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { css } from "styled-system/css";
import { Box, Flex, HStack } from "styled-system/jsx";
import { Link } from "@tanstack/react-router";

import * as Accordion from "~/components/ui/Accordion";
import type { RenderTreeViewProps } from "~/constant";

import { Button } from "../Button";
import * as Drawer from "../Drawer";
import { Icon } from "../Icon";
import { useRouterState } from "@tanstack/react-router";
import { getMenu } from "~/features/menu/query-options";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Notification, Profile } from "./menu";

const RenderTreeView: React.FC<RenderTreeViewProps & Accordion.RootProps> = ({ menu, setOpen, ...rest }) => {
  const router = useRouterState();

  return (
    <Accordion.Root collapsible divideY="0px" display="flex" flexDir="column" gap="24px" border={0} {...rest}>
      {menu.map((v, id) => {
        const isParent = v.items!.length > 0;
        return (
          <Accordion.Item key={id} value={v.label}>
            {!isParent ? (
              <Link
                to={v.to}
                onClick={() => {
                  setOpen?.(false);
                }}
                className={css({
                  textStyle: "sm",
                  colorPalette: "secondary",
                  color: "colorPalette.darken8",
                  "&[data-status=active]": {
                    color: "primary",
                    fontWeight: "medium",
                  },
                })}
              >
                {v.label}
              </Link>
            ) : (
              <Accordion.ItemTrigger py="0" asChild justifyContent="start">
                <Button
                  data-active={router.location.pathname.includes(v.to!)}
                  fontFamily="dmSans!"
                  fontWeight="normal"
                  px="0"
                  textStyle="sm"
                  minH="0px!"
                  height="fit-content!"
                  visual="ghost"
                  colorPalette="secondary"
                  color="colorPalette.darken8"
                  className={css({
                    "&[data-active=true]": {
                      color: "primary",
                      fontWeight: "medium!",
                    },
                  })}
                >
                  {v.label}
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.00002 9.83335C7.87202 9.83335 7.744 9.7847 7.64666 9.6867L4.98 7.02003C4.78466 6.8247 4.78466 6.50801 4.98 6.31268C5.17533 6.11734 5.49202 6.11734 5.68735 6.31268L8.00067 8.62599L10.314 6.31268C10.5093 6.11734 10.826 6.11734 11.0213 6.31268C11.2167 6.50801 11.2167 6.8247 11.0213 7.02003L8.35467 9.6867C8.256 9.7847 8.12802 9.83335 8.00002 9.83335Z"
                        fill="#98A2B3"
                      />
                    </svg>
                  </span>
                </Button>
              </Accordion.ItemTrigger>
            )}
            <Accordion.ItemContent>
              {isParent && (
                <Box pl={2}>
                  <RenderTreeView setOpen={setOpen} menu={v.items!} pt="16px" />
                </Box>
              )}
            </Accordion.ItemContent>
          </Accordion.Item>
        );
      })}
    </Accordion.Root>
  );
};

export const Mobile = () => {
  const queryClient = useQueryClient();
  const data = useSuspenseQuery(getMenu);
  const [open, setOpen] = useState(false);
  const isLogin = queryClient.getQueryData(["isLogin"]);

  return (
    <HStack gap={4}>
      {isLogin ? (
        <>
          <Notification />
          <Profile withInformation={false} />
        </>
      ) : null}

      <Drawer.Root open={open} onOpenChange={(v) => setOpen(v.open)}>
        <Icon size="lg" color="secondary" onClick={() => setOpen(true)}>
          <IconMenuDeep />
        </Icon>
        <Drawer.Backdrop />
        <Drawer.Positioner>
          <Drawer.Content borderTopWidth={0}>
            <Drawer.Header>
              <Flex justifyContent="space-between" alignItems="center">
                <Drawer.Title textStyle="h6">Menu</Drawer.Title>

                <Drawer.CloseTrigger asChild top="3" right="4">
                  <Icon size="lg" cursor="pointer" color="secondary.darken8">
                    <IconX />
                  </Icon>
                </Drawer.CloseTrigger>
              </Flex>
            </Drawer.Header>
            <Drawer.Body>
              <RenderTreeView setOpen={setOpen} menu={data.data.mobile} />
            </Drawer.Body>
            {!isLogin && (
              <Drawer.Footer gap="4" justifyContent="start">
                <Link to="/login">
                  <Button
                    visual="ghost"
                    variant="primary"
                    colorPalette="secondary"
                    color="colorPalette"
                    px={0}
                    justifyContent="start"
                  >
                    Masuk
                    <Icon color="secondary">
                      <IconLogin />
                    </Icon>
                  </Button>
                </Link>
                <Link to="/register">
                  <Button variant="primary" size="md">
                    Daftar
                  </Button>
                </Link>
              </Drawer.Footer>
            )}
          </Drawer.Content>
        </Drawer.Positioner>
      </Drawer.Root>
    </HStack>
  );
};
