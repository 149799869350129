import { ark } from "@ark-ui/react/factory";
import { B } from "@mobily/ts-belt";
import type { ComponentProps } from "react";
import { styled } from "styled-system/jsx";
import { button } from "styled-system/recipes";
import { Progress } from "./Progress";
import React from "react";

const StyledButton = styled(ark.button, button);

export interface ButtonProps extends ComponentProps<typeof StyledButton> {
  loading?: boolean;
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({ loading = false, children, ...rest }) => {
  return (
    <StyledButton data-loading={loading} {...rest}>
      {B.ifElse(
        loading,
        () => (
          <Progress value={null} type="circular" color="primary" />
        ),
        () => children as React.ReactElement,
      )}
    </StyledButton>
  );
};
