import { D } from "@mobily/ts-belt";
import { z } from "zod";
import { BaseQuery } from "~/@types";
import { baseCommentSchema } from "~/features/comment";
import { defaultServices } from "~/services";
import { Forum } from "../@types";
import { type Comment } from "~/features/comment";

export interface ParamsFetchListForum extends BaseQuery {
  category?: string;
  createdBy?: string;
  userCategory?: string;
  myForum?: boolean;
}

export const fetchListForum = async (params: ParamsFetchListForum) => {
  const res = await defaultServices.getList<Forum>({
    resource: "/forum",
    query: {
      ...params,
    },
  });

  return res;
};

export const fetchDetailForum = async (forumId: string) => {
  const res = await defaultServices.getOne<Forum>({
    resource: "/forum",
    id: forumId,
  });

  return res;
};

export const commentSchema = baseCommentSchema
  .merge(
    z.object({
      forum: z.string(),
    }),
  )
  .transform((v) => D.filter(v, Boolean));

export type CommentSchema = z.input<typeof commentSchema>;

export const comment = {
  getList: async (q: BaseQuery, forumId: string) => {
    const res = await defaultServices.getList<Comment>({
      resource: `/forum/${forumId}/comment`,
      query: { ...q },
    });

    return res;
  },

  create: async (body: Partial<CommentSchema>) => {
    const res = await defaultServices.create({
      resource: "/forum/comment",
      variables: body,
    });

    return res;
  },
  update: async (body: CommentSchema, commentId: string) => {
    const res = await defaultServices.update({
      resource: `/forum/comment/${commentId}`,
      variables: body,
    });

    return res;
  },
  delete: async (commentId: string) => {
    const res = await defaultServices.delete({
      resource: "/forum/comment",
      id: commentId,
    });

    return res;
  },
};
