import { createFileRoute, redirect } from "@tanstack/react-router";
import { Login } from "~/features/auth/components";
import { Helmet } from "react-helmet-async";

type LoginSearchSchema = {
  destinationPath?: string;
};

export const Route = createFileRoute("/login")({
  validateSearch: (search: Record<string, unknown>): LoginSearchSchema => {
    return {
      destinationPath: search.destinationPath as string,
    };
  },
  beforeLoad: ({ context: { queryClient } }) => {
    const isLogin = queryClient.getQueryData(["isLogin"]);
    if (isLogin) {
      throw redirect({
        to: "/",
      });
    }
  },
  component: () => (
    <>
      <Helmet>
        <title>Login - Ecobiz</title>
      </Helmet>
      <Login />
    </>
  ),
});
