import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Link, getRouteApi, useRouterState } from "@tanstack/react-router";
import dayjs from "dayjs";
import { css } from "styled-system/css";
import { Box, Center, Flex, Grid, GridItem, HStack, VStack } from "styled-system/jsx";
import { BreadCrumb, Button, Icon, Image } from "~/components";
import { useDisClosure } from "~/hooks";
import { articleGetList, articleGetOne } from "../../query-options";
import { CardNews } from "../data-display";

import { IconArrowsMaximize } from "@tabler/icons-react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { useState } from "react";
import { getId, isYoutubeUrl } from "~/utils";
import { container } from "styled-system/patterns";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export const Detail = () => {
  const { open, onOpen, onClose } = useDisClosure();
  const [galleryIndex, setGalleryIndex] = useState(-1);
  const getRoute = getRouteApi("/_layout/article/$articleId");

  const router = useRouterState();
  const articleId = getRoute.useParams().articleId;


  const detail = useSuspenseQuery(articleGetOne(articleId));

  const getOthersArticle = useQuery(
    articleGetList({
      type: router.location.hash === "Berita" ? "default" : router.location.hash === "Komunitas" ? "community" : "tips",
      limit: 3,
      except: articleId,
    }),
  );

  const breadcrumbs = [
    {
      path: router.location.hash,
      to: `${router.location.hash === "Berita" ? "/article/news" : router.location.hash === "Komunitas" ? "/article/community" : "/article/kiat-kiat"}`,
      disabled: false,
    },
    {
      path: detail.data.data.title,
      to: "#",
      disabled: true,
    },
  ];

  return (
    <>
      <article
        className={container({
          maxW: "7xl",
          mt: 10,
          mb: 20,
        })}
      >
        <BreadCrumb items={breadcrumbs} />
        <Flex gap="16px" mt="48px" flexDir="column">
          <h1
            className={css({
              textStyle: "h3",
              color: "secondary.darken8",
              fontWeight: "bold",
            })}
          >
            {detail.data.data.title}
          </h1>
          <p
            className={css({
              textStyle: "md",
              color: "secondary",
            })}
          >
            {dayjs(detail.data.data.createdAt).format("DD MMMM YYYY")}
          </p>
        </Flex>
        <Flex gap="10" flexDir="column" mt="40px">
          <Flex flexDir="column" gap="4">
            <Box position="relative" w="full" cursor="pointer" onClick={onOpen}>
              <Image
                src={
                  detail.data.data.image ??
                  "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg"
                }
                loading="eager"
                imageStyle={{
                  width: "full",
                  maxH: "520px",
                  height: "full",
                  objectFit: "cover",
                  rounded: "16px",
                }}
              />
              <Box
                w="52px"
                h="52px"
                rounded="full"
                bgColor="white"
                boxShadow="shadow"
                pos="absolute"
                bottom={19}
                right={19}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="secondary" size="md">
                  <IconArrowsMaximize />
                </Icon>
              </Box>
            </Box>
            {detail.data.data.attachments().length > 0 && (
              <VStack alignItems="start" gap="4">
                <h3
                  className={css({
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "secondary.darken8",
                    fontFamily: "outfit!",
                  })}
                >
                  Galeri
                </h3>
                <HStack flexWrap="wrap">
                  {detail.data.data.attachments().map((v, idx) => (
                    <Image
                      width={100}
                      height={100}
                      src={v.type === "video" || v.type === "custom-slide" ? v.poster : v.src}
                      onClick={() => setGalleryIndex(idx)}
                      key={idx}
                      pictureClassName={css({
                        height: "100px",
                      })}
                      imageStyle={{
                        rounded: "8px",
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </HStack>
              </VStack>
            )}
          </Flex>

          {detail.data.data.video ? (
            isYoutubeUrl(detail.data.data.video) ? (
              <iframe
                width="100%"
                height="512"
                allowFullScreen
                src={`https://www.youtube.com/embed/${getId(detail.data.data.video)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className={css({
                  rounded: "16px",
                  border: "0px",
                })}
              ></iframe>
            ) : (
              <video
                width="100%"
                height="512"
                controls
                className={css({
                  rounded: "16px",
                })}
              >
                <source src={detail.data.data.video!} type="video/ogg" />
                <source src={detail.data.data.video!} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          ) : null}

          {detail.data.data.content && (
            <p
              className={css({
                textStyle: "lg",
                fontWeight: "normal",
                color: "secondary.darken8",
                whiteSpace: "pre-line",
              })}
            >
              {detail.data.data.content}
            </p>
          )}
        </Flex>

        <aside
          className={css({
            my: "20",
          })}
        >
          <Flex gap="40px" flexDir="column">
            <h1
              className={css({
                textStyle: "h2",
                color: "secondary.darken8",
                fontWeight: "bold",
                textAlign: "center",
              })}
            >
              {router.location.hash} Lainnya
            </h1>

            <Grid
              gridTemplateColumns={{
                base: "1",
                sm: "2",
                lg: "3",
              }}
              gap="24px"
            >
              {getOthersArticle.data?.data.list.map((v) => (
                <GridItem gridColumn="1" key={v.id}>
                  <CardNews datum={v} hash={router.location.hash} />
                </GridItem>
              ))}
            </Grid>
            <Center>
              <Link
                to={
                  router.location.hash === "Berita"
                    ? "/article/news"
                    : router.location.hash === "Komunitas"
                      ? "/article/community"
                      : "/article/kiat-kiat"
                }
                from={router.location.pathname}
                search={{
                  page: 1,
                  keyword: "",
                  limit: 9,
                }}
              >
                <Button minW="207px">Lihat semua {router.location.hash}</Button>
              </Link>
            </Center>
          </Flex>
        </aside>
      </article>

      <Lightbox
        open={open}
        close={onClose}
        slides={[
          {
            src:
              detail.data.data.image ??
              "https://s3.ap-southeast-3.amazonaws.com/cdn.ecobiz.id/1709696995730_Forum UI.svg",
            alt: "detail image article",
          },
        ]}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        portal={{
          root: document.body,
        }}
        plugins={[Zoom]}
      />

      <Lightbox
        plugins={[Thumbnails, Video]}
        index={galleryIndex}
        slides={detail.data.data.attachments()}
        open={galleryIndex >= 0}
        thumbnails={{
          position: "bottom",
          gap: 16,
          borderRadius: 4,
          border: 1,
          padding: 4,
          width: 120,
          height: 80,
          imageFit: "cover",
          vignette: true,
        }}
        render={{
          slide: ({ slide }) =>
            slide.type === "custom-slide" ? (
              <iframe
                width="100%"
                height="100%"
                allowFullScreen
                src={`https://www.youtube.com/embed/${getId(slide.src)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className={css({
                  rounded: "16px",
                  border: "0px",
                  height: "100%",
                })}
              ></iframe>
            ) : null,

          thumbnail: ({ slide }) => {
            return slide.type === "custom-slide" ? (
              <Image imageClassName="yarl__fullsize yarl__thumbnails_contain_image" src={slide.poster} alt="poster" />
            ) : null;
          },
        }}
        close={() => setGalleryIndex(-1)}
      />
    </>
  );
};
