/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import type { MaybePromise } from "~/@types";

type UseInfiniteScroll = <T>(f?: () => MaybePromise<T>) => React.MutableRefObject<HTMLDivElement>;

export const useInfiniteScroll: UseInfiniteScroll = (f) => {
  const observerTarget = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          if (typeof f === "function") {
            f();
          }
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, []);

  return observerTarget;
};
