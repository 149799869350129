import { IconLogout } from "@tabler/icons-react";
import { Link, getRouteApi } from "@tanstack/react-router";
import { css } from "styled-system/css";
import { Container, Grid, GridItem, HStack, VStack } from "styled-system/jsx";
import { Card, Icon, Progress } from "~/components";
import { auth } from "~/services";
import { Suspense, lazy } from "react";

interface Options {
  id: "settings" | "update-password" | "";
  label: string;
  type: "url" | "action";
}

const options: Options[] = [
  { id: "settings", label: "Biodata", type: "url" },
  { id: "update-password", label: "Ubah Kata Sandi", type: "url" },
  { id: "", label: "Keluar Akun", type: "action" },
];

const Bio = lazy(() => import("./Bio"));
const UpdatePassword = lazy(() => import("./UpdatePassword"));

export const Profile = () => {
  const getRoute = getRouteApi("/_layout/profile/");
  const search = getRoute.useSearch();

  const handleLogout = async () => {
    await auth.logout();
    window.location.reload();
  };

  return (
    <Container maxW="7xl" my={10}>
      <Grid
        gridTemplateColumns={{
          base: 1,
          md: "264px auto",
        }}
        gap="24px"
      >
        <GridItem>
          <Card.Root rounded="8px" borderColor="secondary.lighten2" borderWidth={1} boxShadow="unset">
            <Card.Body asChild py="8px" px={0}>
              <VStack alignItems="start" gap={0}>
                {options.map((v) =>
                  v.type === "url" ? (
                    <Link
                      to="/profile"
                      search={{
                        type: v.id,
                      }}
                    >
                      <p
                        data-active={v.id === search.type}
                        className={css({
                          px: "4",
                          fontWeight: "medium",
                          textStyle: "md",
                          color: "secondary.darken8",
                          cursor: "pointer",
                          py: "3",
                          "&[data-active=true]": {
                            color: "primary",
                          },
                        })}
                      >
                        {v.label}
                      </p>
                    </Link>
                  ) : (
                    <HStack gap="4px" cursor="pointer" onClick={handleLogout}>
                      <p
                        data-type={v.type}
                        className={css({
                          pl: "4",
                          fontWeight: "medium",
                          textStyle: "md",
                          py: "3",
                          "&[data-type=action]": {
                            color: "red.darken5",
                          },
                        })}
                      >
                        {v.label}
                      </p>
                      <Icon color="red.darken5">
                        <IconLogout />
                      </Icon>
                    </HStack>
                  ),
                )}
              </VStack>
            </Card.Body>
          </Card.Root>
        </GridItem>
        <GridItem>
          <Suspense fallback={<Progress value={null} type="circular" />}>
            <Card.Root width="full" rounded="8px" borderColor="secondary.lighten2" borderWidth={1} boxShadow="unset">
              <Card.Header p="4" pb="6">
                <Card.Title textStyle="h6" color="disabled" fontWeight="bold">
                  {search.type === "settings" ? "Biodata" : "Ubah Kata Sandi"}
                </Card.Title>
              </Card.Header>
              <Card.Body p={0} asChild>
                {search.type === "settings" ? <Bio /> : <UpdatePassword />}
              </Card.Body>
            </Card.Root>
          </Suspense>
        </GridItem>
      </Grid>
    </Container>
  );
};
